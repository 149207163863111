<body>
  <form name="frm">
    <nav class="navbar fixed-top shadow p-3 mb-5 bg-white rounded border-top" style="height: 120px; 
                background-color: hsl(0, 0%, 100%); padding-top: 0px; 
                padding-bottom: 0px; border-bottom: 0.3ch;border-left: 0ch; 
                border-right: 0ch; border-top: 0ch; border-style: solid; border-color: rgb(113, 144, 193);">
      <div class="container-fluid col-md-12 ">
        <a class="navbar-brand text-black" style="font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;">
          <h4>Plataforma de Gestão de Treinamento</h4>
        </a><br>
        <div>
          <form class="d-flex">
            <input [(ngModel)]="usuario.codeInstituicao" style="width: 100px;" name="instituicaoId" type="text"
              class="form-control me-2" placeholder="ID">
            <input [(ngModel)]="usuario.usuario" style="width: 150px;" name="usuario" autocomplete="off" maxlength="30"
              type="text" class="form-control me-2" placeholder="Login">
            <input [(ngModel)]="usuario.senha" style="width: 150px;" name="senha" type="password"
              class="form-control me-2" placeholder="Password">

            <button type="submit" (click)="enviar()" class="btn btn-secondary btn-sm">
              <span *ngIf="loading" class="spinner-border spinner-border-sm"></span>
              <span *ngIf="loading"> Conectando...</span>
              <span *ngIf="!loading"><i class="fas fa-sign-in-alt"></i> Entrar</span></button>

          </form>
        </div>
      </div>
    </nav>
  </form>
  <main>

    <section class="pricing section-padding" id="section_2" style="padding-top: 150px;">
      <div>
        <div class="row col-12" style="max-height: 150px;">
          <!-- <div class="tab-content" id="nav-tabContent"> -->
          <!-- <div> -->
          <!-- <div class=""> -->
          <div class="col-lg-4">
            <img src="../../assets/images/logo transaparente.png" class="img-fluid" alt="">
          </div>

          <div class="col-lg-8">
            <!-- <h2 class="mb-3">Habilita Farma</h2> -->

            <p>Olá, Sejam bem-vindos! É um prazer apresentar a plataforma de Gestão de Treinamentos.</p>
            <p>O <b>Habilita Farma</b> é uma aplicação desenvolvida pela farmacêutica magistral Carolina Franco,
              com experiência de mais de 25 anos nas rotinas magistrais, capacitando e treinando pessoas.
              Uma ferramenta que propõe facilitar a gestão dos treinamentos iniciais e continuados na farmácia
              magistral através do agendamento facilitado, bem como sugestões de datas, temas e conteúdos.
            <p>Em resumo, nós do Habilita Farma somos mais do que uma solução de negócios,
              somos um parceiro de confiança para farmácias que buscam soluções tecnológicas de ponta.
              Estamos comprometidos em fornecer produtos de qualidade,
              suporte excepcional e resultados tangíveis para nossos clientes.</p>

          </div>
          <!-- </div> -->
          <!-- </div> -->
          <!-- </div> -->
        </div>
      </div>
    </section>    
    <section class="services section-padding" id="section_3" style="padding-top: 50px;">
        <!-- <hr style="color: green;"> -->
      <div class="container">
        <div class="row">

          <h2 class="mb-5 text-center">Oferecemos</h2>

          <div class="col-lg-4 col-12 d-flex p-0 order-lg-0 order-sm-4" style="background-color: rgb(160, 204, 160);">
            <img src="../../assets/images/services/undraw_content_team_3epn.svg" class="img-fluid services-image"
              alt="">
          </div>

          <div class="col-lg-4 col-12 p-0">
            <div class="services-info custom-icon-left paddingText">

              <h4 class="">Organização</h4>

              <p class="mb-4">Traz ao gestor farmacêutico ou ao gestor da qualidade a organização, padronização e
                sistematização da rotina de treinamentos, e consequentemente,
                deixa mais liberdade no tempo do gestor para suas atividades técnicas e da
                gestão na farmácia Mantém seus colaboradores sempre capacitados e
                tudo isso, atendendo às exigências da RDC 67/2007.
              </p>

              <!-- <a class="custom-btn custom-bg-dark btn" href="#">Learn More</a> -->
            </div>
          </div>

          <div class="col-lg-4 col-12 d-flex p-0" style="background-color: rgb(246, 246, 145);">
            <img src="../../assets/images/services/undraw_online_transactions_02ka.svg" class="img-fluid services-image"
              alt="">
          </div>

          <div class="col-lg-4 col-12 p-0 order-lg-0 order-sm-5">

            <div class="services-info custom-icon-right paddingText order-lg-0 order-sm-2">

              <h4 class="">Capacitação</h4>

              <p class="mb-4">Gestor, mantenha seus colaboradores sempre capacitados, otimize seu tempo através da
                padronização da sua gestão de treinamento e atenda a norma vigente!
              </p>


              <!-- <a class="custom-btn custom-bg-dark btn" href="#">Learn More</a> -->
            </div>
          </div>

          <div class="col-lg-4 col-12 d-flex p-0" style="background-color:rgb(134, 157, 183);">
            <img src="../../assets/images/services/undraw_Online_page_re_lhgx.svg" class="img-fluid services-image"
              alt="">
          </div>

          <div class="col-lg-4 col-12 p-0">

            <div class="services-info custom-icon-top paddingText">

              <h4 class="">Parceria</h4>

              <p class="mb-4">Obrigado por nos dar a oportunidade de apresentar nosso sistema hoje. Estamos ansiosos
                para trabalhar com vocês e ajudá-los a alcançar o sucesso em sua gestão.</p>

              <!-- <a class="custom-btn custom-bg-dark btn" href="#">Learn More</a> -->
            </div>
          </div>

        </div>
      </div>
    </section>

    <section class="section-padding" id="section_6" style="padding-top: 50px;">
      <div class="container">
        <div class="row">

          <div class="col-lg-8 col-md-10 col-12 mx-auto">
            <h2 class="mb-4 text-center">Entre em contato</h2>

            <form class="" role="form" action="" method="post">
              <div class="row">
                <div class="col-lg-4 col-md-6 col-12 my-2">
                  <label class="mb-2" for="name">Nome completo</label>

                  <input type="text" name="name" id="name" class="form-control" required="">
                </div>

                <div class="col-lg-4 col-md-6 col-12 my-2">
                  <label class="mb-2" for="email">Email</label>

                  <input type="email" name="email" id="email" pattern="[^ @]*@[^ @]*" class="form-control" required="">
                </div>

                <div class="col-lg-4 col-md-6 col-12 my-2">
                  <label class="mb-2" for="subject">Como ficou sabendo de nós?</label>

                  <select class="form-control" name="subject" id="subject">
                    <option selected="">Escolha uma opção</option>
                    <option [value]="0">Internet</option>
                    <option [value]="1">Redes Sociais</option>
                    <option [value]="2">Outras farmácias</option>
                    <option [value]="3">Outros</option>
                  </select>
                </div>

                <div class="col-12 my-2">
                  <label class="mb-2" for="message">Deixe sua mensagem</label>

                  <textarea class="form-control" rows="5" id="message" name="message"></textarea>

                  <button type="submit" class="form-control mt-4 btn btn-secondary" id="submit">Enviar</button>
                </div>
              </div>
            </form>
          </div>

        </div>
      </div>
    </section>

  </main>

  <footer class="site-footer">
    <div class="container">
      <div class="row">
        <div class="col-6 mx-auto my-lg-0 my-4">
          <h5 class="text-white mb-3">Links Úteis</h5>

          <ul class="footer-menu">
            <li class="footer-menu-item"><a href="https://site.cff.org.br/" class="footer-menu-link">CFF - Conselho
                Federal de Farmácia</a></li>

            <li class="footer-menu-item"><a
                href="https://bvsms.saude.gov.br/bvs/saudelegis/anvisa/2007/rdc0067_08_10_2007.html"
                class="footer-menu-link">RDC 67/2007 - Boas Práticas de Manipulação em Farmácias</a></li>

            <li class="footer-menu-item"><a
                href="https://www.gov.br/anvisa/pt-br/assuntos/regulamentacao/legislacao/bibliotecas-tematicas"
                class="footer-menu-link">Bibliotecas Temáticas - Anvisa</a></li>

            <li class="footer-menu-item"><a
                href="https://bvsms.saude.gov.br/bvs/saudelegis/svs/1998/prt0344_12_05_1998_rep.html"
                class="footer-menu-link">Portaria 344/1998 - Substâncias Sujeitas a Controle Especial</a></li>

            <li class="footer-menu-item"><a
                href="https://bvsms.saude.gov.br/bvs/saudelegis/anvisa/2009/rdc0044_17_08_2009.pdf"
                class="footer-menu-link">RDC 44/2009 - Atendimento e Serviços Farmacêuticos</a></li>

            <li class="footer-menu-item"><a
                href="https://www.cff.org.br/sistemas/geral/revista/pdf/76/08-codigodeetica.pdf"
                class="footer-menu-link">Código de Ética Farmacêutica</a></li>

            <li class="footer-menu-item"><a href="https://anfarmag.org.br/" class="footer-menu-link">Anfarmag -
                Associação Nacional de Farmacêuticos Magistrais</a></li>

            <li class="footer-menu-item"><a
                href="https://www.gov.br/agricultura/pt-br/assuntos/insumos-agropecuarios/insumos-pecuarios/produtos-veterinarios/legislacao-1/instrucoes-normativas/instrucao-normativa-mapa-no-11-de-08-06-2005.pdf/view"
                class="footer-menu-link">IN 11/2005 - MAPA - Manipulação Veterinária</a></li>

            <li class="footer-menu-item"><a
                href="https://www.gov.br/pf/pt-br/assuntos/produtos-quimicos/legislacao/port204.pdf"
                class="footer-menu-link">Portaria 204/2022 - Substâncias Controladas pela Polícia Federal</a></li>

            <li class="footer-menu-item"><a
                href="https://bvsms.saude.gov.br/bvs/saudelegis/anvisa/2018/rdc0222_28_03_2018.pdf"
                class="footer-menu-link">RDC 222/2018 - PGRSS - Plano de Gerenciamento de Resíduos em Serviços de
                Saúde</a></li>
          </ul>
        </div>

        <div class="col-6">
          <h5 class="text-white mb-3">Localização</h5>

          <p class="text-white mb-1">São José dos Campos - SP</p>

          <p><a href="mailto:email@company.com" class="footer-link">contato@habilitafarma.com.br</a>
            <li><a href="#" class="social-icon-link bi-instagram">Instagram</a></li>

          </p>
        </div>

        <div class="site-footer-bottom mt-5">
          <div class="row pt-4">
            <div class="col-lg-6 col-12">
              <p class="copyright-text tooplate-link">Direitos autorais © 2023-2024 Habilita Farma. Todos os direitos
                reservados.
                <br><a rel="nofollow" href="https://www.habilitafarma.com.br/" target="_blank">Habilita Farma - Gestão
                  de Treinamentos</a>
              </p>
            </div>

            <div class="col-lg-3 col-12 ms-auto text-white">
              <ul class="social-icon">
                <li><a href="#" class="social-icon-link bi-facebook"></a></li>

                <li><a href="https://twitter.com/search?q=tooplate" class="social-icon-link bi-twitter"></a></li>

                <li><a href="#" class="social-icon-link bi-instagram"></a></li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </div>
  </footer>

</body>





<!-- <body class="hold-transition login-page body-bg">
  <div class="login-box">
    <div class="card card-outline card-primary">
      <div class="card-header text-center">
        <a href="../../index2.html" class="h1">
          <img src="../../assets/logoLogin.jpg" alt="HABILITAFARMA" 
          style="opacity: .8"></a>
      </div>
      <div class="card-body">
        <p class="login-box-msg">Entre com seus dados de acesso.</p>
  
        <form name="frm" (ngSubmit)="enviar()">
          <div class="input-group mb-3">
              <input [(ngModel)]="usuario.codeInstituicao" name="instituicaoId" type="text" class="form-control" placeholder="Instituição ID">
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-desktop"></span>
                </div>
              </div>
            </div>
            <div class="input-group mb-3">
              <input [(ngModel)]="usuario.usuario" name="usuario" autocomplete="off" maxlength="30" type="text" class="form-control" placeholder="Login">
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-user"></span>
                </div>
              </div>
            </div>
            <div class="input-group mb-3">
            <input [(ngModel)]="usuario.senha" name="senha" type="password" class="form-control" placeholder="Password">
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-lock"></span>
              </div>
            </div>
          </div>
          <div class="row">              
            <div class="col-12 text-center mt-2 mb-3">
              <button type="submit" class="btn btn-primary btn-block">
                <span *ngIf="loading" class="spinner-border spinner-border-sm"></span>
                <span *ngIf="loading"> Conectando...</span>
                <span *ngIf="!loading"><i class="fas fa-sign-in-alt"></i> Entrar</span></button>
            </div>            
          </div>
        </form>
      </div>
    </div>
    v. 1.2.0
  </div>
</body> -->