import { Injectable } from '@angular/core';
import { SessionService } from "../session.service";
import { Http } from '../http.service';
import { BaseWebApi } from "./base-webapi.service";

@Injectable({
  providedIn: 'root'
})
export class VwAgendaTelaPrincipalWebapiService extends BaseWebApi {
  constructor(httpService: Http,
    sessionService: SessionService) {
      super(httpService, sessionService);
      this.prefixo = 'vwagendatelaprincipal';
    }
  }
  