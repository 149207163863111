export class TbAgenda{
    id: number;
    tipo: number;
    preDefinido: boolean;
    idInstituicao: number;
    idSetor: number;
    idGrupo: number;
    idContratado: number;
    idTema: number;
    idTreinador: number;
    idAvaliador: number;
    dataPlanejada: Date;
    dataRealizada: Date;
    dataLimite: Date;
    excluido: boolean;

    constructor(init? : Partial<TbAgenda>) {
        if(init != null) Object.assign(this, init);
        else{
            this.id = undefined;
            this.tipo = undefined;
            this.preDefinido = undefined;
            this.idInstituicao = undefined;
            this.idSetor = undefined;
            this.idGrupo = undefined;
            this.idContratado = undefined;
            this.idTema = undefined;
            this.idTreinador = undefined;
            this.idAvaliador = undefined;
            this.dataPlanejada = undefined;
            this.dataRealizada = undefined;
            this.dataLimite = undefined;
            this.excluido = undefined;
        }
    }
}