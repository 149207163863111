import { Injectable } from '@angular/core';
import { BaseWebApi } from './base-webapi.service';
import { Http } from '../http.service';
import { SessionService } from '../session.service';

@Injectable({
  providedIn: 'root'
})
export class TopicoWebapiService extends BaseWebApi{

  constructor(httpService: Http,
    sessionService: SessionService) { 
      super(httpService, sessionService);
      this.prefixo = 'topico';
    }

    TopicosPorTema(idInstituicao: number, idTema: number, auth_token: string, success?, error?) {
      return this.httpClient.get(this.prefixo + '/TopicosPorTema?idInstituicao=' + idInstituicao + '&idTema=' + idTema, auth_token, success, error);
    }
}
