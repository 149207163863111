<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">
                            Acompanhamento
                        </h3>
                    </div>
                    <form>
                        <div class="card-body">
                            <div class="card-body p-0">

                                <div class="col-sm-4">
                                    <input class="form-control mr-sm-2" type="search"
                                        placeholder="Pesquisar por Nome ou Setor." name="textoFiltro" id="textoFiltro"
                                        [(ngModel)]="textoFiltro">

                                </div>

                                <table class="table table-striped projects">
                                    <thead>
                                        <tr>
                                            <th style="width: 1%">
                                                #
                                            </th>
                                            <th style="width: 30%">
                                                Colaborador / Setor / Tipo
                                            </th>
                                            <th>
                                                Progresso
                                            </th>
                                            <th style="width: 9%" class="text-center">
                                                Status
                                            </th>
                                            <th style="width: 5px" class="text-center">
                                                Visualizar
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of vwAcompanhamentoContinuado | filtrosGrid:textoFiltro">
                                            <td>
                                                #
                                            </td>
                                            <td>
                                                <a>
                                                    {{item.nomeContratado}}
                                                </a>
                                                <br />
                                                <small>
                                                    {{item.nomeSetor}}
                                                </small>
                                                <br />
                                                <small>
                                                    <b><i>{{item.tipo}}</i></b>
                                                </small>
                                            </td>
                                            <td class="project_progress">
                                                <div class="progress progress-sm">
                                                    <!-- <div class="progress-bar bg-orange" role="progressbar"
                                                        aria-valuenow="57" aria-valuemin="0" aria-valuemax="100"
                                                        style="width: 57%">
                                                    </div> -->
                                                    <div class="base">
                                                        <div class="pBar"
                                                            [ngStyle]="{'width.%':item.porcentagemConcluida}"></div>
                                                    </div>
                                                </div>
                                                <small>
                                                    {{item.porcentagemConcluida}}% Complete
                                                </small>
                                            </td>
                                            <td class="project-state">
                                                <span [hidden]="!(item.porcentagemConcluida != 100)"
                                                    class="badge badge-warning col-sm-12">Em Andamento</span>
                                                <span [hidden]="!(item.porcentagemConcluida === 100)"
                                                    class="badge badge-success col-sm-12">Concluído</span>
                                            </td>
                                            <td class="project-actions text-right">
                                                <button type="button"
                                                    (click)="listarDetalheAcompanhamentoContinuado(item.id)"
                                                    class="btn btn-outline-default" data-toggle="modal"
                                                    data-target="#detalhesContinuado">

                                                    <i class="nav-icon fas fa-eye"></i></button>
                                            </td>
                                        </tr>
                                        <tr *ngFor="let item of vwAcompanhamentoInicial | filtrosGrid:textoFiltro">
                                            <td>
                                                #
                                            </td>
                                            <td>
                                                <a>
                                                    {{item.nomeContratado}}
                                                </a>
                                                <br />
                                                <small>
                                                    {{item.nomeSetor}}
                                                </small>
                                                <br />
                                                <small>
                                                    <b><i>{{item.tipo}}</i></b>
                                                </small>
                                            </td>
                                            <td class="project_progress">
                                                <div class="progress progress-sm">
                                                    <!-- <div class="progress-bar bg-orange" role="progressbar"
                                                        aria-valuenow="57" aria-valuemin="0" aria-valuemax="100"
                                                        style="width: 57%">
                                                    </div> -->
                                                    <div class="base">
                                                        <div class="pBar"
                                                            [ngStyle]="{'width.%':item.porcentagemConcluida}"></div>
                                                    </div>
                                                </div>
                                                <small>
                                                    {{item.porcentagemConcluida}}% Complete
                                                </small>
                                            </td>
                                            <td class="project-state">
                                                <span [hidden]="!(item.porcentagemConcluida != 100)"
                                                    class="badge badge-warning col-sm-12">Em Andamento</span>
                                                <span [hidden]="!(item.porcentagemConcluida === 100)"
                                                    class="badge badge-success col-sm-12">Concluído</span>
                                            </td>
                                            <td class="project-actions text-right">
                                                <button type="button"
                                                    (click)="listarDetalheAcompanhamentoInicial(item.id)"
                                                    class="btn btn-outline-default" data-toggle="modal"
                                                    data-target="#detalhesInicial">

                                                    <i class="nav-icon fas fa-eye"></i></button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Modal detalhes inicial-->
<div class="modal fade" id="detalhesInicial" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true" data-backdrop="static">

    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Detalhes do Acompanhamento Inicial</h5>
            </div>
            <!-- <div class="modal-body"> -->
            <!-- <div class="row gx-3 gy-2 align-items-center"> -->
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th scope="col">Temas</th>
                        <!-- <th scope="col">contratado</th> -->
                        <th scope="col">Concluído</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <tr *ngFor="let item of vwDetalheAcompanhamentoInicial">
                        <td width="auto" [innerHtml]="item.nomeTema"></td>
                        <!-- <td width="auto" [innerHtml]="item.nomeContratado"></td> -->
                        <td width="auto" [innerHtml]="item.concluido | TrueSimFalseNaopipe"></td>
                    </tr>
                </tbody>
            </table>
            <!-- </div> -->
            <!-- </div> -->
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" style="width: 150px !important;"
                    data-dismiss="modal">Fechar</button>
            </div>
        </div>
    </div>
</div>


<!-- Modal detalhes continuado-->
<div class="modal fade" id="detalhesContinuado" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true" data-backdrop="static">

    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Detalhes do Acompanhamento Continuado</h5>
            </div>
            <!-- <div class="modal-body"> -->
            <!-- <div class="row gx-3 gy-2 align-items-center"> -->
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th scope="col">Temas</th>
                        <!-- <th scope="col">contratado</th> -->
                        <th scope="col">Concluído</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <tr *ngFor="let item of vwDetalheAcompanhamentoContinuado">
                        <td width="auto" [innerHtml]="item.nomeTema"></td>
                        <!-- <td width="auto" [innerHtml]="item.nomeContratado"></td> -->
                        <td width="auto" [innerHtml]="item.concluido | TrueSimFalseNaopipe"></td>
                    </tr>
                </tbody>
            </table>
            <!-- </div> -->
            <!-- </div> -->
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" style="width: 150px !important;"
                    data-dismiss="modal">Fechar</button>
            </div>
        </div>
    </div>
</div>