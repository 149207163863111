export class TbUsuario{
    id: number;
    idInstituicao: number;
    idContratado: number;
    usuario: string;
    senha: string;
    dataCadastro: Date;
    ativo: boolean;
    excluido: boolean;

    constructor(init? : Partial<TbUsuario>) {
        if(init != null) Object.assign(this, init);
        else{
            this.id = undefined;
            this.idInstituicao = undefined;
            this.idContratado = undefined;
            this.usuario = undefined;
            this.senha = undefined;
            this.dataCadastro = undefined;
            this.ativo = undefined;
            this.excluido = undefined;
        }
    }
}
