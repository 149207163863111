import { Injectable } from '@angular/core';
import { BaseWebApi } from './base-webapi.service';
import { Http } from '../http.service';
import { SessionService } from '../session.service';

@Injectable({
  providedIn: 'root'
})
export class VwAcompanhamentoInicialWebapiService extends BaseWebApi {
  constructor(httpService: Http,
    sessionService: SessionService) {
      super(httpService, sessionService);
      this.prefixo = 'vwacompanhamentoinicial';
    }
  }
  