import { Injectable } from '@angular/core';
import { SessionService } from "../session.service";
import { Http } from '../http.service';
import { BaseWebApi } from "./base-webapi.service";

@Injectable({
  providedIn: 'root'
})
export class EnviarEmailWebapiService extends BaseWebApi {
  constructor(httpService: Http,
    sessionService: SessionService) {
      super(httpService, sessionService);
      this.prefixo = 'enviaremailagenda';
    }    
    
    enviaremailagenda(auth_token: string, success?, error?) {
      return this.httpClient.get(this.prefixo + '/enviaremailagenda', auth_token, success, error);
    }   
    
    EnviarEmailCronJob(auth_token: string, success?, error?) {
      return this.httpClient.get(this.prefixo + '/EnviarEmailCronJob', auth_token, success, error);
    }   

    enviaremailagendaMensal(auth_token: string, success?, error?) {
      return this.httpClient.get(this.prefixo + '/enviaremailagenda?tipo=2', auth_token, success, error);
    }

    testecronjob(auth_token: string, success?, error?) {
      return this.httpClient.get(this.prefixo + '/testecronjobhf', auth_token, success, error);
    }
  }
  
