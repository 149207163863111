<body class="hold-transition sidebar-mini layout-navbar-fixed">
  <!-- Site wrapper -->
  <div class="wrapper">
    <!-- Navbar -->
    <nav class="main-header navbar navbar-expand navbar-white navbar-light">
      <!-- Left navbar links -->
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
        </li>
        <li class="nav-item d-none d-sm-inline-block">
          <a routerLink="/principal/home" class="nav-link">Agenda</a>
        </li>
        <!-- <li class="nav-item d-none d-sm-inline-block">
          <a routerLink="/principal/home" class="nav-link">Contato</a>
        </li> -->
        <li class="nav-item d-none d-sm-inline-block">
          <a routerLink="/principal/login" class="nav-link">Sair
            <i class="fas fa-sign-out-alt text-danger"></i></a>
        </li>


      </ul>

      <!-- Right navbar links -->
      <ul class="navbar-nav ml-auto">

        <!-- Messages Dropdown Menu -->
        <!-- <li class="nav-item dropdown">
          <a class="nav-link" data-toggle="dropdown" href="#">
            <i class="far fa-comments"></i>
            <span class="badge badge-danger navbar-badge"></span>
          </a>
          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            <a href="#" class="dropdown-item">
              <i class="fas fa-envelope mr-2"></i> 4 new messages
              <span class="float-right text-muted text-sm">3 mins</span>
            </a>
            <div class="dropdown-divider"></div>
            <a href="#" class="dropdown-item">
              <i class="fas fa-users mr-2"></i> 8 friend requests
              <span class="float-right text-muted text-sm">12 hours</span>
            </a>
            <div class="dropdown-divider"></div>
            <a href="#" class="dropdown-item">
              <i class="fas fa-file mr-2"></i> 3 new reports
              <span class="float-right text-muted text-sm">2 days</span>
            </a>
            <div class="dropdown-divider"></div>
            <a href="#" class="dropdown-item dropdown-footer">See All Notifications</a>
          </div>
        </li> -->
        <!-- Notifications Dropdown Menu -->
        <!-- <li class="nav-item dropdown">
          <a class="nav-link" data-toggle="dropdown" href="#">
            <i class="far fa-bell"></i>
            <span class="badge badge-warning navbar-badge"></span>
          </a>
          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            <span class="dropdown-item dropdown-header">15 Notifications</span>
            <div class="dropdown-divider"></div>
            <a href="#" class="dropdown-item">
              <div class="media">
                <img src="../../assets/Imagens/usuario.jpg" alt="User Avatar" class="img-size-50 mr-3 img-circle">
                <div class="media-body">
                  <h3 class="dropdown-item-title">
                    Treinamento Continuado
                    <span class="float-right text-sm text-danger"><i class="fas fa-star"></i></span>
                  </h3>
                  <p class="text-sm">Grupo Farmacêuticos</p>
                  <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 9:00h</p>
                </div>
              </div>
            </a>
            <div class="dropdown-divider"></div>
            <a href="#" class="dropdown-item">
              <div class="media">
                <img src="../../assets/Imagens/usuario.jpg" alt="User Avatar" class="img-size-50 img-circle mr-3">
                <div class="media-body">
                  <h3 class="dropdown-item-title">
                    Treinamento Inicial
                    <span class="float-right text-sm text-muted"><i class="fas fa-star"></i></span>
                  </h3>
                  <p class="text-sm">José Edson</p>
                  <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 10:00h</p>
                </div>
              </div>
            </a>
            <div class="dropdown-divider"></div>
            <a href="#" class="dropdown-item">
              <div class="media">
                <img src="../../assets/Imagens/usuario.jpg" alt="User Avatar" class="img-size-50 img-circle mr-3">
                <div class="media-body">
                  <h3 class="dropdown-item-title">
                    Treinamento Continuado
                    <span class="float-right text-sm text-warning"><i class="fas fa-star"></i></span>
                  </h3>
                  <p class="text-sm">Pesagem / Cabines</p>
                  <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i>15:00h</p>
                </div>
              </div>
            </a>
            <div class="dropdown-divider"></div>
            <a href="#" class="dropdown-item dropdown-footer">See All Messages</a>
          </div>
        </li> -->
        <!-- <li class="nav-item">
          <a class="nav-link" data-widget="fullscreen" href="#" role="button">
            <i class="fas fa-expand-arrows-alt"></i>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-widget="control-sidebar" data-slide="true" href="#" role="button">
            <i class="fas fa-th-large"></i>
          </a>
        </li> -->
      </ul>
    </nav>
    <!-- /.navbar -->

    <!-- Main Sidebar Container -->
    <aside class="main-sidebar sidebar-dark-primary elevation-4">
      <!-- Brand Logo -->
      <a href="../../index3.html" class="brand-link elevation-4"
        style="padding-top: 10px; padding-bottom: 5px; padding-left: 10px;">
        <span class="brand-text">
          <img src="../../assets/Imagens/logocinzasombra.jpg" alt="User Image">
        </span>
      </a>

      <!-- Sidebar -->
      <div class="sidebar">
        <!-- Sidebar user (optional) -->
        <div class="user-panel mt-3 pb-3 mb-3 d-flex" style="padding-top: 10px;">
          <div class="image">
            <img src="../../assets/Imagens/usuario.jpg" class="img-circle elevation-2" alt="User Image">
          </div>
          <div class="info" style="font-size: small;">
            <a href="#" class="d-block" style="text-decoration:none">{{usuario.usuario}}</a>

          </div>
        </div>
        <div class="accordion" id="accordionExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingHome">
              <button style="background-color: #343a40; color: aliceblue;" class="accordion-button collapsed"
                type="button" routerLink="/principal/home" data-bs-toggle="collapse" aria-expanded="false"
                aria-controls="collapseHome">
                <i class="nav-icon far fa-calendar-alt"></i>
                <div style="padding-left: 10%;" class="brand-text">
                  Agenda
                </div>
              </button>
            </h2>
            <div id="collapseHome" class="accordion-collapse collapse show" aria-labelledby="headingHome"
              data-bs-parent="#accordionExample">
              <!-- <div class="accordion-body" style="background-color: #4b4f54;">
                <a routerLink="/principal/home"  style="padding-left: 10px;">
                  <i class="nav-icon fas fa-home"></i>
                  Agenda
                </a>
              </div> -->
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingCadastro">
              <button style="background-color: #343a40; color: aliceblue;" class="accordion-button collapsed"
                type="button" data-bs-toggle="collapse" data-bs-target="#collapseCadastro" aria-expanded="false"
                aria-controls="collapseCadastro">
                <i class="nav-icon fas fa-edit"></i>
                <div style="padding-left: 10%;" class="brand-text">
                  Cadastro
                </div>
              </button>
            </h2>
            <div id="collapseCadastro" class="accordion-collapse collapse" aria-labelledby="headingCadastro"
              data-bs-parent="#accordionExample">
              <div class="accordion-body" style="background-color: #4b4f54;">
                <a routerLink="/principal/contratado" style="padding-left: 10px; text-decoration:none">
                  <i class="nav-icon fas fa-edit"></i>
                  Colaborador
                </a>
              </div>
              <div class="accordion-body" style="background-color: #4b4f54;">
                <a routerLink="/principal/funcaoCargo" style="padding-left: 10px; text-decoration:none">
                  <i class="nav-icon fas fa-edit"></i>
                  Função/Cargo
                </a>
              </div>
              <div class="accordion-body" style="background-color: #4b4f54;">
                <a routerLink="/principal/setor" style="padding-left: 10px; text-decoration:none">
                  <i class="nav-icon fas fa-edit"></i>
                  Setor
                </a>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingAgendamento">
              <button style="background-color: #343a40; color: aliceblue;" class="accordion-button collapsed"
                type="button" data-bs-toggle="collapse" data-bs-target="#collapseTreinamentos" aria-expanded="false"
                aria-controls="collapseTreinamentos">
                <i class="nav-icon fas fa-users"></i>
                <div style="padding-left: 10%;" class="brand-text">
                  Treinamentos
                </div>
              </button>
            </h2>
            <div id="collapseTreinamentos" class="accordion-collapse collapse" aria-labelledby="headingTreinamentos"
              data-bs-parent="#accordionExample">
              <div class="accordion-body" style="background-color: #4b4f54;">
                <a routerLink="/principal/agendamento" style="padding-left: 10px; text-decoration:none">
                  <i class="far fa-calendar-alt nav-icon"></i>
                  Inicial
                </a>
              </div>
              <div class="accordion-body" style="background-color: #4b4f54;">
                <a routerLink="/principal/agendamentoContinuado" style="padding-left: 10px; text-decoration:none">
                  <i class="far fa-calendar-alt nav-icon"></i>
                  Continuado
                </a>
              </div>
              <div class="accordion-body" style="background-color: #4b4f54;">
                <a routerLink="/principal/acompanhamento" style="padding-left: 10px; text-decoration:none">
                  <i class="nav-icon fas fa-table"></i>
                  Acompanhamento
                </a>
              </div>
              <div class="accordion-body" style="background-color: #4b4f54;">
                <a routerLink="/principal/tema" style="padding-left: 10px; text-decoration:none">
                  <i class="nav-icon fas fa-edit"></i>
                  Tema
                </a>
              </div>
              <!-- <div class="accordion-body" style="background-color: #4b4f54;">
              <a routerLink="/principal/aplicar"  style="padding-left: 10px; text-decoration:none">
                <i class="nav-icon fas fa-table"></i>
                Aplicar
              </a>
            </div> -->
              <!-- <div class="accordion-body" style="background-color: #4b4f54;">
              <a routerLink="/principal/avaliacao"  style="padding-left: 10px; text-decoration:none">
                <i class="nav-icon fas fa-table"></i>
                Avaliação
              </a>
            </div> -->              
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingAjuda">
              <button style="background-color: #343a40; color: aliceblue;" class="accordion-button collapsed"
                type="button" data-bs-toggle="collapse" data-bs-target="#collapseAjuda" aria-expanded="false"
                aria-controls="collapseAjuda">
                <i class="nav-icon fas fa-book"></i>
                <div style="padding-left: 10%;" class="brand-text">
                  Ajuda
                </div>
              </button>
            </h2>
            <div id="collapseAjuda" class="accordion-collapse collapse" aria-labelledby="headingAjuda"
              data-bs-parent="#accordionExample">
              <div class="accordion-body" style="background-color: #4b4f54;">
                <a routerLink="/principal/manual" style="padding-left: 10px; text-decoration:none">
                  <i class="nav-icon fas fa-book"></i>
                  Manual
                </a>
              </div>
              <!-- <div class="accordion-body" style="background-color: #4b4f54;">
                <a routerLink="/principal/sobre" style="padding-left: 10px; text-decoration:none">
                  <i class="nav-icon fas fa-book"></i>
                  Sobre
                </a>
              </div> -->
              <div class="accordion-body" style="background-color: #4b4f54;">
                <a routerLink="/principal/contato" style="padding-left: 10px; text-decoration:none">
                  <i class="nav-icon fas fa-book"></i>
                  Contato
                </a>
              </div>
              <div class="accordion-body" style="background-color: #4b4f54;">
                <a routerLink="/principal/admin/usuario" style="padding-left: 10px; text-decoration:none">
                  <i class="nav-icon fas fa-book"></i>
                  Alterar Login/Senha
                </a>
              </div>
            </div>
            <!-- <div class="accordion-body" style="background-color: #4b4f54;">
              <a routerLink="/principal/login"  style="padding-left: 10px; text-decoration:none">
                <i class="fas fa-sign-out-alt text-danger"></i>                
                <div style="padding-left: 10%;" class="brand-text">Sair</div>
                
              </a>
            </div> -->
          </div>
        </div>
        <!-- /.sidebar-menu -->
      </div>
      <!-- /.sidebar -->
    </aside>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <section class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6" style="padding-top: 1%;">
              <h1>Gestão de Treinamentos</h1>
            </div>
            <!-- <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item"><a href="#">Agenda</a></li>
                <li class="breadcrumb-item active">Contratado</li>
              </ol>
            </div> -->
          </div>
        </div><!-- /.container-fluid -->
      </section>
      <!-- Main content -->
      <router-outlet></router-outlet>
      <!-- /.content -->
    </div>
    <!-- /.content-wrapper -->

    <footer class="main-footer">
      <div class="float-right d-none d-sm-block">
        <b>Version</b> 1.2 / {{codeInstituicao}}
      </div>
      <strong>Direitos autorais &copy; 2023-2024 <a href="https://habilitafarma.com.br">Habilita Farma</a>.</strong>
      Todos os direitos reservados.
    </footer>

    <!-- Control Sidebar -->
    <aside class="control-sidebar control-sidebar-dark">
      <!-- Control sidebar content goes here -->
    </aside>
    <!-- /.control-sidebar -->
  </div>
  <!-- ./wrapper -->
</body>