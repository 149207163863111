export class tbAcompanhamentoContinuado{
    id: number;
    idInstituicao: number;
    idContratado: number;
    idAgenda: number;
    concluido: boolean;
    excluido: boolean;

    constructor(init? : Partial<tbAcompanhamentoContinuado>) {
        if(init != null) Object.assign(this, init);
        else{
            this.id = undefined;
            this.idInstituicao = undefined;
            this.idContratado = undefined;
            this.idAgenda = undefined;
            this.concluido = undefined;
            this.excluido = undefined;
        }
    }
}