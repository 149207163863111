import { Injectable } from '@angular/core';
import { SessionService } from './../session.service';
import { Http } from '../http.service';

export abstract class BaseWebApi {
    protected prefixo: String = '';

    constructor(protected httpClient: Http,
                protected sessionService: SessionService) { }

    listar(auth_token: string, sucessoCallback, erroCallback) {
        return this.httpClient.get(this.prefixo, auth_token, sucessoCallback, erroCallback);
    };

    selecionarPorId(id: number, auth_token: string, sucessoCallback, erroCallback) {
        return this.httpClient.get(this.prefixo + '/' + id, auth_token, sucessoCallback, erroCallback);
    };

    selecionarPorIdInstituicao(id: number, auth_token: string, sucessoCallback, erroCallback) {
        return this.httpClient.get(this.prefixo + '/porInstituicao/' + id, auth_token, sucessoCallback, erroCallback);
    };

    incluir(objeto, auth_token: string, sucess?, error?) {
        return this.httpClient.post(this.prefixo, objeto, auth_token, sucess, error);
    };

    uploadImagem(objeto, sucess?, error?){
        return this.httpClient.uploadImagem(this.prefixo + '/UploadFiles', objeto, sucess, error);
    }

    alterar(objeto, auth_token: string, sucess?, error?) {
        return this.httpClient.put(this.prefixo,  objeto, auth_token,sucess, error);
    };

    excluir(id: number,  auth_token: string,  sucessoCallback, erroCallback) {
        return this.httpClient.del(this.prefixo + '/' + id, auth_token, sucessoCallback, erroCallback);
    };
    
    GetCurrentUser(){        
        return this.sessionService.getUsuario();            
    }
}
