import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { DatePipe } from '@angular/common';

import { ModalModule } from 'ngx-bootstrap/modal'
import { NgxPaginationModule } from 'ngx-pagination';
import { CKEditorModule } from 'ckeditor4-angular';
import { ImageCropperModule } from 'ngx-image-cropper';

import { AppRoutingModule } from '../app-routing.module';
import { PrincipalRoutingModule } from './principal-routing.module';

import { AlertaModalComponent } from '../shared/components/alerta-modal/alerta-modal.component'

import { UsuarioComponent } from './usuario/usuario.component';
import { TrueSimFalseNaopipe } from '../shared/pipes/TrueSimFalseNao.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ContratadoComponent } from './contratado/contratado.component';
import { EscolaridadePipePipe } from '../shared/pipes/escolaridade-pipe.pipe';
import { FiltrosGridPipe } from '../shared/pipes/filtros-grid.pipe';
import { UsuarioWebApi } from '../shared/services/webapi/usuario-webapi.service';
import { ContratadoWebapiService } from '../shared/services/webapi/contratado-webapi.service';
import { HomeComponent } from './home/home.component';
import { SetorComponent } from './setor/setor.component';
import { ContratadoSetorComponent } from './contratado-setor/contratado-setor.component';
import { SeparardatahoraPipe } from '../shared/pipes/separardatahora.pipe';
import { AgendamentoComponent } from './agendamento/agendamento.component';
import { AcompanhamentoComponent } from './acompanhamento/acompanhamento.component';
import { AvaliacaoComponent } from './avaliacao/avaliacao.component';
import { RegistroComponent } from './registro/registro.component';
import { AgendamentoContinuadoComponent } from './agendamento-continuado/agendamento-continuado.component';
import { ConvertDataPraMesPipe } from '../shared/pipes/convert-data-pra-mes.pipe';
import { ContatoComponent } from './contato/contato.component';
import { SobreComponent } from './sobre/sobre.component';
import { ManualComponent } from './manual/manual.component';
import { AplicarComponent } from './aplicar/aplicar.component';
import { FuncaoCargoComponent } from './funcao-cargo/funcao-cargo.component';
import { TemaComponent } from './tema/tema.component';
import { FarmaciaComponent } from './admin/farmacia/farmacia.component';
import { InstituicaoComponent } from './instituicao/instituicao.component';
import { EnviaremailagendaComponent } from './enviaremailagenda/enviaremailagenda.component';
import { EnviaremailagendamensalComponent } from './enviaremailagendamensal/enviaremailagendamensal.component';
import { TestecronjoghfComponent } from './testecronjoghf/testecronjoghf.component';

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ModalModule.forRoot(),
    BrowserAnimationsModule,
    NgxPaginationModule,
    CKEditorModule,
    AppRoutingModule,
    PrincipalRoutingModule,
    ImageCropperModule
  ],
  declarations: [
    UsuarioComponent,
    ContratadoComponent,
    TrueSimFalseNaopipe,
    FiltrosGridPipe,
    EscolaridadePipePipe,
    SeparardatahoraPipe,
    ConvertDataPraMesPipe,
    AlertaModalComponent,
    HomeComponent,
    SetorComponent,
    ContratadoSetorComponent,
    AgendamentoComponent,
    AcompanhamentoComponent,
    AvaliacaoComponent,
    RegistroComponent,
    AgendamentoContinuadoComponent,
    ContatoComponent,
    SobreComponent,
    ManualComponent,
    AplicarComponent,
    FuncaoCargoComponent,
    TemaComponent,
    FarmaciaComponent,
    InstituicaoComponent,
    EnviaremailagendaComponent,
    EnviaremailagendamensalComponent,
    TestecronjoghfComponent
  ],
  exports:[
    AlertaModalComponent
  ],
  providers:[
    UsuarioWebApi,
    ContratadoWebapiService,
    DatePipe
  ]
})
export class PrincipalModule { }
