export class TbGrupo{
    id: number;
    idInstituicao: number;
    nome: string;
    descricao: string;
    excluido: boolean;

    constructor(init? : Partial<TbGrupo>) {
        if(init != null) Object.assign(this, init);
        else{
            this.id = undefined;
            this.idInstituicao = undefined;
            this.nome = undefined;
            this.descricao = undefined;
            this.excluido = undefined;
        }
    }
}