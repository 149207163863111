export class TbTema{
    id: number;
    idInstituicao: number;
    nome: string;
    descricao: string;
    tipo: number;
    topicos: string;
    predefinido: boolean;
    obrigatorio: boolean;
    periocidade: number;
    idMetodoAvaliacao: number;
    prazoAvaliacao: number;
    excluido: boolean;

    constructor(init? : Partial<TbTema>) {
        if(init != null) Object.assign(this, init);
        else{
            this.id = undefined;
            this.idInstituicao = undefined;
            this.nome = undefined;
            this.descricao = undefined;
            this.tipo = undefined;
            this.predefinido = undefined;
            this.obrigatorio = undefined;
            this.periocidade = undefined;
            this.topicos = undefined;
            this.idMetodoAvaliacao = undefined;
            this.prazoAvaliacao = undefined;
            this.excluido = undefined;
        }
    }
}