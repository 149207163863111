import { Component, Inject, OnInit, ViewChild, ViewContainerRef, forwardRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppComponent } from 'src/app/app.component';
import { TbContratado } from 'src/app/entities/tbContratado';
import { TbInstituicao } from 'src/app/entities/tbInstituicao';
import { TbUsuario } from 'src/app/entities/tbUsuario';
import { Utils } from 'src/app/shared/classes/utils';
import { ContratadoWebapiService } from 'src/app/shared/services/webapi/contratado-webapi.service';
import { InstituicaoWebapiService } from 'src/app/shared/services/webapi/instituicao-webapi.service';
import { UsuarioWebApi } from 'src/app/shared/services/webapi/usuario-webapi.service';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.css']
})
export class UsuarioComponent implements OnInit {

  @ViewChild('ngForm') frm: NgForm;
  public get frmCtrls(): any { return this.frm ? this.frm.controls : {}; };
  public tbContratados: TbContratado[] = new Array<TbContratado>();
  public tbContratado: TbContratado = new TbContratado();
  public tbUsuarios: TbUsuario[] = new Array<TbUsuario>();
  public tbUsuario: TbUsuario = new TbUsuario();
  public tbInstituicao: TbInstituicao = new TbInstituicao();
  public loading: boolean = false;
  public habilitarExcluir: boolean = false;

  textoFiltro = '';
  codeInstituicao = '';
  HFtkn = localStorage.getItem("HFtkn");
  senha2: string;
  usuarioAtual = "";

  constructor(@Inject(forwardRef(() => AppComponent))
  public parent: AppComponent,
    public toast: ToastrService,
    public vcr: ViewContainerRef,
    private UsuarioWebapiService: UsuarioWebApi,
    private contratadoWebService: ContratadoWebapiService,
    private instituicaoWebApiService: InstituicaoWebapiService,
    private utils: Utils,
    private router: Router) {
    let HFtkn = localStorage.getItem("HFtkn");
    this.codeInstituicao = localStorage.getItem("chaveId");
    this.BuscarInstituicaoPorCode(this.codeInstituicao);
  }


  BuscarInstituicaoPorCode(code) {
    this.instituicaoWebApiService.pesquisarInstituicaoPorCode(this.codeInstituicao, this.HFtkn, (data: TbInstituicao) => {
      this.tbInstituicao.id = data[0];
      this.listarUsuario();
      this.listarContratados();
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  listarUsuario() {
    this.UsuarioWebapiService.selecionarPorIdInstituicao(this.tbInstituicao.id, this.HFtkn, (dataUsuario: TbUsuario[]) => {
      this.tbUsuarios = undefined;
      this.tbUsuarios = dataUsuario.filter(u=>u.usuario != 'admin');
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  listarContratados() {
    this.contratadoWebService.selecionarPorIdInstituicao(this.tbInstituicao.id, this.HFtkn, (dataContratado: TbContratado[]) => {
      this.tbContratados = dataContratado;
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  BuscarPorId(item) {
    this.UsuarioWebapiService.selecionarPorId(item.id, this.HFtkn, (data: TbUsuario) => {
      this.tbUsuario = data;
      this.usuarioAtual = this.tbUsuario.usuario;
      // this.tbContratado.id = this.tbUsuario.idContratado;
      this.loading = true;
      this.habilitarExcluir = true;
      window.scroll(0, 0);
    },
      (error) => {
        this.toast.error(error);
      });
  }

  BuscarContratadoPorId() {
    this.contratadoWebService.selecionarPorId(this.tbContratado.id, this.HFtkn, (data: TbContratado) => {
      this.tbContratado = data;
      this.tbUsuario.idContratado = this.tbContratado.id;
      this.tbUsuario.ativo = true;
      this.tbUsuario.usuario = this.tbContratado.email;
      this.tbUsuario.dataCadastro = new Date(Date.now());
      this.tbUsuario.idInstituicao = this.tbInstituicao.id;

      if (this.ValidarCampos()) {
        this.loading = true;
        this.habilitarExcluir = false;
        this.tbUsuario.excluido = false;

        if (this.tbUsuario.id == undefined) {
          this.UsuarioWebapiService.incluir(this.tbUsuario, this.HFtkn, (retorno) => {
            this.toast.success("Registro salvo com sucesso!");
            this.LimparCampo();
            this.listarUsuario();
            this.loading = false;
          },
            (error) => {
              this.loading = false;
              this.toast.error(error);
            });
        }
        else {
          this.UsuarioWebapiService.alterar(this.tbUsuario, this.HFtkn, (retorno) => {
            this.toast.success("Registro salvo com sucesso!")
            this.LimparCampo();
            this.listarUsuario();
            this.loading = false;
          },
            (error) => {
              this.loading = false;
              this.toast.error(error);
            });
        }
      }
    },
      (error) => {
        this.toast.error(error);
      });
  }

  LimparCampo() {
    this.tbUsuario.senha = undefined;
    this.tbUsuario.usuario = undefined;
    this.loading = false;
    this.habilitarExcluir = false;
  }

  Novo() {
    this.loading = true;
  }

  Salvar() {
    if (this.tbUsuario.senha != this.senha2)
      this.toast.error("O campo Senha e Repita a senha não conferem.");
    else {
      if(this.usuarioAtual != this.tbUsuario.usuario){
        this.UsuarioWebapiService.pesquisarPorUsuario(this.tbUsuario.usuario, this.tbInstituicao.id, this.HFtkn, (data: TbUsuario) => {
          if (data.id == undefined) {
            this.UsuarioWebapiService.alterar(this.tbUsuario, this.HFtkn, (retorno) => {
              this.toast.success("Registro salvo com sucesso!")
              this.LimparCampo();
              this.listarUsuario();
              this.loading = false;
            },
              (error) => {
                this.loading = false;
                this.toast.error(error);
              });
          }
          else{
            this.toast.error("Login digitado já existe.");
          }
        },
          (error) => {
            this.loading = false;
            this.toast.error(error);
          });

      }
      else{
        this.UsuarioWebapiService.alterar(this.tbUsuario, this.HFtkn, (retorno) => {
          this.toast.success("Registro salvo com sucesso!")
          this.LimparCampo();
          this.listarUsuario();
          this.loading = false;
        },
          (error) => {
            this.loading = false;
            this.toast.error(error);
          });
      }
      
    }


  }

  ExcluirRegistro() {
    this.tbUsuario.excluido = true;
    this.UsuarioWebapiService.alterar(this.tbUsuario, this.HFtkn, (retorno) => {
      this.LimparCampo();
      this.listarUsuario();
      this.loading = false;
      this.habilitarExcluir = false;
      this.toast.success("Registro excluído com sucesso!")
    },
      (error) => {
        this.loading = false;
        this.toast.error(error);
      });
  }

  ValidarCampos() {
    if ((this.tbContratado.id == undefined || "") || this.tbUsuario.senha == undefined || "") {
      this.toast.error("Campo Usuário e senha são Obrigatórios.");
      return false;
    }
    else {
      return true;
    }
  }

  ngOnInit(): void {
  }
}
