import { Component, OnInit, Inject, forwardRef, ViewContainerRef, ViewChild } from '@angular/core';
import { AppComponent } from '../app.component';
import { ToastrService } from 'ngx-toastr';
import { UsuarioWebApi } from '../shared/services/webapi/usuario-webapi.service';
import { Utils } from '../shared/classes/utils';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    @ViewChild('frm') frm: NgForm;
    public get frmCtrls(): any { return this.frm ? this.frm.controls : {}; };

    public usuarioLogado;

    public HFtkn;

    public usuario: any = {
        usuario: '',
        id: '',
        codeInstituicao: ''
    };

    public loading: boolean = false;

    constructor(@Inject(forwardRef(() => AppComponent)) public parent: AppComponent,
        public toast: ToastrService,
        public vcr: ViewContainerRef,
        private utils: Utils,
        private usuarioWebApi: UsuarioWebApi,
        private router: Router) {
    }

    enviar() {
        if (this.usuario.usuario && this.usuario.senha) {

            let date: Date = new Date();
            let month = (date.getMonth() + 1).toString().padStart(2, "0");
            let day = date.getDate().toString().padStart(2, "0");

            this.loading = true;
            try {
                this.usuarioWebApi.gettkn(this.usuario.usuario, this.usuario.senha, this.usuario.codeInstituicao, (data) => {
                    if(data == null){
                        this.toast.error('Dados Incorretos.');
                                this.loading = false;
                                return;
                    }
                    
                    this.HFtkn = data;
                    localStorage.setItem("HFtkn", this.HFtkn.token);

                    let HFtkn = localStorage.getItem("HFtkn");
                    this.usuarioWebApi.autenticar(this.usuario.usuario, this.usuario.senha, this.usuario.codeInstituicao, HFtkn,
                        (data) => {
                            if (data.length > 0) {
                                if (data[0].ativo == false) {
                                    this.toast.error("Usuário sem acesso. Entre em contato com o Suporte")
                                    this.loading = false;
                                }
                                else {
                                    this.usuarioLogado = data[0];
                                    this.getTkn();
                                    // this.toast.success(`Usuário ${this.usuarioLogado.usuario} Conectado!`);
                                    this.router.navigate(['/principal/home']);
                                    this.loading = false;
                                    localStorage.removeItem("chaveId");
                                    localStorage.setItem("chaveId", this.usuario.codeInstituicao);
                                    localStorage.removeItem("usuariohf");
                                    localStorage.setItem("usuariohf", this.usuarioLogado.usuario);
                                }
                            }
                            else {
                                this.toast.error('Usuário ou senha incorreto.');
                                this.loading = false;
                            }
                        },
                        (error, status) => {
                            this.loading = false;
                            this.toast.error("Falha no Sistema. Entre em contato com o Suporte");
                        });
                },
                    (error) => {
                        this.toast.error("Falha no Sistema. Entre em contato com o Suporte.");
                    });



            } catch (error) {
                this.loading = false;
                this.toast.error("Falha no Sistema. Entre em contato com o Suporte");
            }

        }
        else
            this.toast.error('Informe o usuário e senha.');
    }

    ngOnInit(): void {
        this.usuario.codeInstituicao = localStorage.getItem("chaveId");
    }


    getTkn() {
        this.usuarioWebApi.gettkn(this.usuario.usuario, this.usuario.senha, this.usuario.codeInstituicao, (data) => {
            this.HFtkn = data;
            localStorage.setItem("HFtkn", this.HFtkn.token);
        },
            (error) => {
                this.toast.error(error);
            });
    }

}
