import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'separardatahora'
})
export class SeparardatahoraPipe implements PipeTransform {
    transform(_datahora: string, dataOuhora: number): string
    {
        let retorno = "";

            if (_datahora < '2001-01-01T02:00:00')
            return "";

            if(dataOuhora == 1){
                retorno = _datahora.toString().substring(8,10) + '-' + _datahora.toString().substring(5, 7) + '-' + _datahora.toString().substring(0,4);
            }
            else if (dataOuhora == 2){
                retorno = _datahora.toString().substring(11,16)
            }
            else if (dataOuhora == 3){
                    if(_datahora.toString().substring(5, 7) == "01")
                        retorno = "Jan"                    
                        if(_datahora.toString().substring(5, 7) == "02")
                            retorno = "Fev"                    
                            if(_datahora.toString().substring(5, 7) == "03")
                                retorno = "Março"                    
                                if(_datahora.toString().substring(5, 7) == "04")
                                    retorno = "Abril"                    
                                    if(_datahora.toString().substring(5, 7) == "05")
                                        retorno = "Maio"                    
                                        if(_datahora.toString().substring(5, 7) == "06")
                                            retorno = "Junho"                    
                                            if(_datahora.toString().substring(5, 7) == "07")
                                                retorno = "Julho"                    
                                                if(_datahora.toString().substring(5, 7) == "08")
                                                    retorno = "Agosto"                    
                                                    if(_datahora.toString().substring(5, 7) == "09")
                                                        retorno = "Setembro"                    
                                                        if(_datahora.toString().substring(5, 7) == "10")
                                                            retorno = "Outubro"                    
                                                            if(_datahora.toString().substring(5, 7) == "11")
                                                                retorno = "Nov"                    
                                                                if(_datahora.toString().substring(5, 7) == "12")
                                                                    retorno = "Dez"
            }
            else if (dataOuhora == 4){
                if(_datahora.toString().substring(5, 7) == "01")
                retorno = _datahora.toString().substring(8,10) + " " + "Jan." + " " + _datahora.toString().substring(0,4)                    
                if(_datahora.toString().substring(5, 7) == "02")
                    retorno = _datahora.toString().substring(8,10) + " " + "Fev." + " " + _datahora.toString().substring(0,4)
                    if(_datahora.toString().substring(5, 7) == "03")
                        retorno =  _datahora.toString().substring(8,10) + " " + "Mar." + " " + _datahora.toString().substring(0,4)
                        if(_datahora.toString().substring(5, 7) == "04")
                            retorno =  _datahora.toString().substring(8,10) + " " + "Abr." + " " + _datahora.toString().substring(0,4)
                            if(_datahora.toString().substring(5, 7) == "05")
                                retorno =  _datahora.toString().substring(8,10) + " " + "Mai." + " " + _datahora.toString().substring(0,4)
                                if(_datahora.toString().substring(5, 7) == "06")
                                    retorno =  _datahora.toString().substring(8,10) + " " + "Jun." + " " + _datahora.toString().substring(0,4)
                                    if(_datahora.toString().substring(5, 7) == "07")
                                        retorno =  _datahora.toString().substring(8,10) + " " + "Jul." + " " + _datahora.toString().substring(0,4)
                                        if(_datahora.toString().substring(5, 7) == "08")
                                            retorno =  _datahora.toString().substring(8,10) + " " + "Ago." + " " + _datahora.toString().substring(0,4)
                                            if(_datahora.toString().substring(5, 7) == "09")
                                                retorno =  _datahora.toString().substring(8,10) + " " + "Set." + " " + _datahora.toString().substring(0,4)
                                                if(_datahora.toString().substring(5, 7) == "10")
                                                    retorno =  _datahora.toString().substring(8,10) + " " + "Out." + " " + _datahora.toString().substring(0,4)
                                                    if(_datahora.toString().substring(5, 7) == "11")
                                                        retorno =  _datahora.toString().substring(8,10) + " " + "Nov." + " " + _datahora.toString().substring(0,4)
                                                        if(_datahora.toString().substring(5, 7) == "12")
                                                            retorno =  _datahora.toString().substring(8,10) + " " + "Dez." + " " + _datahora.toString().substring(0,4)
            };        
        return retorno;
    }
}
