import { Injectable } from '@angular/core';
import { SessionService } from './session.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class Http {
    //private serverApi: String = 'http://localhost:5000/';
    private serverApi: String = 'https://habilitafarmaapi.habilitafarma.com.br/';

    constructor(private http: HttpClient,
                private sessionService: SessionService) { }

    private generateHeaders(){
        var headers = {
            'Content-Type': 'application/json'
        }

        return new HttpHeaders(headers);
    }

    private defaultSubscribe(promise, success?, error?){
        promise.subscribe(
            (data) => { 
                if(success) success(data);
            },
            (data) => {
                if(error) error(data);
            });
    }

    autenticar(url: String, auth_token, usuario, senha, codeInstituicao, success?, error?) {  
        const httpHeaders: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth_token}`
          })             
            let promise = this.http.get(this.serverApi +'api/'+ url + 'login=' + usuario + '&senha=' + senha + '&codeInstituicao=' + codeInstituicao, { headers: httpHeaders });
            this.defaultSubscribe(promise, success, error);
    }

    gettkn(url: String, usuario, senha, codeInstituicao, success?, error?) {               
            let promise = this.http.get(this.serverApi.toString() + url + 'usuario=' + usuario + '&senha=' + senha + '&codeInstituicao=' + codeInstituicao, { headers: this.generateHeaders() });
            this.defaultSubscribe(promise, success, error);
    }

    get(url: String, auth_token: string, success?, error?, opts: Object = null) {
        const httpHeaders: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth_token}`
          })
        let promise = this.http.get(this.serverApi +'api/'+ url, { headers: httpHeaders });
        this.defaultSubscribe(promise, success, error);
    }

    post(url: String, obj: Object, auth_token: string, success?, error?, opts: Object = null){
        const httpHeaders: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth_token}`
          })
        
        let promise = this.http.post(this.serverApi +'api/'+ url, obj, { headers: httpHeaders });
        this.defaultSubscribe(promise, success, error);
    }

    put(url: String, obj: Object, auth_token: string, success?, error?, opts: Object = null){
        const httpHeaders: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth_token}`
          })
        
        let promise = this.http.put(this.serverApi +'api/'+ url, obj, { headers: httpHeaders });
        this.defaultSubscribe(promise, success, error);
    }

    uploadImagem(url: String, obj: Object, success?, error?, opts: Object = null){
        
        let promise = this.http.post(this.serverApi +'api/'+ url, obj);
        this.defaultSubscribe(promise, success, error);
    }

    del(url: String, auth_token: string, success?, error?, opts: Object = null){
        const httpHeaders: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth_token}`
          })
        let promise = this.http.delete(this.serverApi +'api/'+ url, { headers: httpHeaders });
        this.defaultSubscribe(promise, success, error);
    }
}
