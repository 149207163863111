import { Component, Inject, OnInit, ViewChild, ViewContainerRef, forwardRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { PrincipalComponent } from '../principal.component';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { InstituicaoWebapiService } from 'src/app/shared/services/webapi/instituicao-webapi.service';
import { TbInstituicao } from 'src/app/entities/tbInstituicao';
import { vwAgendaTelaPrincipal } from 'src/app/entities/vwAgendaTelaPrincipal';
import { VwAgendaTelaPrincipalWebapiService } from 'src/app/shared/services/webapi/vw-agenda-tela-principal-webapi.service';
import { ContratadoWebapiService } from 'src/app/shared/services/webapi/contratado-webapi.service';
import { TbContratado } from 'src/app/entities/tbContratado';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  @ViewChild('ngForm') frm: NgForm;
  public get frmCtrls(): any { return this.frm ? this.frm.controls : {}; };

  public vwAgendaTelaPrincipal: vwAgendaTelaPrincipal[] = new Array<vwAgendaTelaPrincipal>();
  public tbContratado: TbContratado = new TbContratado();

  public loading: boolean = false;
  public idInstituicao;

  dataFiltro: Date;
  dataFiltroString;

  anoAtual = new Date().getFullYear();
  mesAtual: number;

  mesSelecionado: number;

  paginacaoMes = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

  constructor(@Inject(forwardRef(() => PrincipalComponent))
  public parent: PrincipalComponent,
    public vwagendaWebapiService: VwAgendaTelaPrincipalWebapiService,
    private instituicaoWebApiService: InstituicaoWebapiService,
    public datepipe: DatePipe,
    public toast: ToastrService,
    public vcr: ViewContainerRef) {
    this.codeInstituicao = localStorage.getItem("chaveId");
    this.BuscarInstituicaoPorCode(this.codeInstituicao);
    this.dataFiltro = new Date();
    this.dataFiltroString = this.dataFiltro.toISOString().substring(0, 4) + '-' + this.dataFiltro.toISOString().substring(5, 7);
    this.mesAtual = new Date().getMonth() + 1;

  }

  textoFiltro = '';
  codeInstituicao = '';
  HFtkn = localStorage.getItem("HFtkn");

  BuscarInstituicaoPorCode(code) {
    this.instituicaoWebApiService.pesquisarInstituicaoPorCode(this.codeInstituicao, this.HFtkn, (data: TbInstituicao) => {
      this.idInstituicao = data[0];
      this.listarAgendaPrincipal();
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  listarAgendaPrincipal() {
    this.vwagendaWebapiService.selecionarPorIdInstituicao(this.idInstituicao, this.HFtkn, (data: vwAgendaTelaPrincipal[]) => {
      this.vwAgendaTelaPrincipal = data;
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  public aumentarAno() {
    this.anoAtual = this.anoAtual + 1;
    console.log(this.anoAtual);
  }
  
  public diminuirAno() {
    this.anoAtual = this.anoAtual - 1;
    console.log(this.anoAtual);
  }

  public selecionarMes(mes) {
    this.dataFiltroString = this.anoAtual + "-" + mes;
    this.mesSelecionado = mes;
  }

  public enviarDadosRegistro(agenda) {
    let ArrayAgenda = JSON.stringify(agenda);
    localStorage.setItem("agenda", ArrayAgenda);
    localStorage.setItem("responsavel", this.tbContratado.nome);
  }

  ngOnInit(): void {
  }
}
