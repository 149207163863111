import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'TrueSimFalseNaopipe'
})
export class TrueSimFalseNaopipe implements PipeTransform {
    transform(linkExterno: boolean): string
    {
        let retorno = "";
        
            if(linkExterno == true){
                retorno = "Sim";
            }
            else{
                retorno = "Não"
            };
        
        return retorno;
    }
}
