import { Injectable } from '@angular/core';
import { SessionService } from "../session.service";
import { Http } from '../http.service';
import { BaseWebApi } from "./base-webapi.service";

@Injectable({
  providedIn: 'root'
})
export class ContratadoWebapiService extends BaseWebApi {
  constructor(httpService: Http,
    sessionService: SessionService) {
    super(httpService, sessionService);
    this.prefixo = 'contratado';
  }

  listarContratadoPorSetor(idSetor: number, HFtkn: string, success?, error?) {
    return this.httpClient.get(this.prefixo + '/listarContratadoPorSetor?idSetor=' + idSetor, HFtkn, success, error);
  }

  listarContratadoTreinador(idInstituicao: number, HFtkn: string, success?, error?) {
    return this.httpClient.get(this.prefixo + '/listarContratadoTreinador?idInstituicao=' + idInstituicao, HFtkn, success, error);
  }

  listarContratadoAvaliador(idInstituicao: number, HFtkn: string, success?, error?) {
    return this.httpClient.get(this.prefixo + '/listarContratadoAvaliador?idInstituicao=' + idInstituicao, HFtkn, success, error);
  }

  selecionarResponsavel(idInstituicao: number, HFtkn: string, success?, error?) {
    return this.httpClient.get(this.prefixo + '/SelecionarResponsavel?idInstituicao=' + idInstituicao, HFtkn, success, error);
  }

  SelecionarContratadoPorNome(idInstituicao: number, nome: string, HFtkn: string, success?, error?) {
    return this.httpClient.get(this.prefixo + '/SelecionarContratadoPorNome?idInstituicao=' + idInstituicao + '&nome=' + nome, HFtkn, success, error);
  }

  ListarContratadosPorNomeSetor(idInstituicao: number, grupo: string, tipoTema: string, HFtkn: string, success?, error?) {
    return this.httpClient.get(this.prefixo + '/ListarContratadosPorNomeSetor?idInstituicao=' + idInstituicao + '&grupo=' + grupo + '&tipoTema=' + tipoTema, HFtkn, success, error);
  }

  ListarContratadosPresentesRegistroPorIdAgenda(idInstituicao: number, idAgenda: number, HFtkn: string, success?, error?) {
    return this.httpClient.get(this.prefixo + '/ListarContratadosPresentesRegistroPorIdAgenda?idInstituicao=' + idInstituicao + '&idAgenda=' + idAgenda, HFtkn, success, error);
  }
}
