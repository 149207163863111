import { Component, Inject, OnInit, ViewChild, ViewContainerRef, forwardRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppComponent } from 'src/app/app.component';
import { TbInstituicao } from 'src/app/entities/tbInstituicao';
import { TbFuncaoCargo } from 'src/app/entities/tbFuncaoCargo';
import { Utils } from 'src/app/shared/classes/utils';
import { InstituicaoWebapiService } from 'src/app/shared/services/webapi/instituicao-webapi.service';
import { FuncaoCargoWebapiService } from 'src/app/shared/services/webapi/funcao-cargo-webapi.service';

@Component({
  selector: 'app-funcao-cargo',
  templateUrl: './funcao-cargo.component.html',
  styleUrls: ['./funcao-cargo.component.css']
})

export class FuncaoCargoComponent implements OnInit {

  @ViewChild('ngForm') frm: NgForm;
  public get frmCtrls(): any { return this.frm ? this.frm.controls : {}; };
  public tbFuncoesCargos: TbFuncaoCargo[] = new Array<TbFuncaoCargo>();
  public tbFuncaoCargo: TbFuncaoCargo = new TbFuncaoCargo();
  public tbInstituicao: TbInstituicao = new TbInstituicao();
  public loading: boolean = false;
  public habilitarExcluir: boolean = false;

  textoFiltro = '';
  codeInstituicao = '';  
  HFtkn = localStorage.getItem("HFtkn");

  constructor(@Inject(forwardRef(() => AppComponent))
  public parent: AppComponent,
    public toast: ToastrService,
    public vcr: ViewContainerRef,
    private funcaoCargoWebapiService: FuncaoCargoWebapiService,
    private instituicaoWebApiService: InstituicaoWebapiService,
    private utils: Utils,
    private router: Router) {
    let HFtkn = localStorage.getItem("HFtkn");    
    this.codeInstituicao = localStorage.getItem("chaveId");
    this.BuscarInstituicaoPorCode(this.codeInstituicao);
  }

  listarSetores() {
    this.funcaoCargoWebapiService.selecionarPorIdInstituicao(this.tbFuncaoCargo.idInstituicao, this.HFtkn, (dataSetor: TbFuncaoCargo[]) => {
      this.tbFuncoesCargos = dataSetor;
    },
      (error) => {
        if(error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  BuscarPorId(item) {
    this.funcaoCargoWebapiService.selecionarPorId(item.id, this.HFtkn, (data: TbFuncaoCargo) => {
      this.tbFuncaoCargo = data;
      this.loading = true;
      this.habilitarExcluir = true;
      window.scroll(0, 0);
    },
      (error) => {
        this.toast.error(error);
      });
  }

  BuscarInstituicaoPorCode(code) {
    this.instituicaoWebApiService.pesquisarInstituicaoPorCode(this.codeInstituicao, this.HFtkn, (data: TbInstituicao) => {
      this.tbFuncaoCargo.idInstituicao = data[0];
      this.listarSetores()
    },
      (error) => {
        if(error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  LimparCampo() {
    this.tbFuncaoCargo.nome = undefined;
    this.tbFuncaoCargo.descricao = undefined;
    this.tbFuncaoCargo.excluido = undefined;
    this.tbFuncaoCargo.id = undefined;
    this.loading = false;
    this.habilitarExcluir = false;
  }

  Novo() {
    this.loading = true;
  }

  Salvar() {
    if (this.ValidarCampos()) {
      this.loading = true;
      this.tbFuncaoCargo.descricao = this.tbFuncaoCargo.nome;
      this.tbFuncaoCargo.excluido = false;


      if (this.tbFuncaoCargo.id == undefined) {
        this.funcaoCargoWebapiService.incluir(this.tbFuncaoCargo, this.HFtkn, (retorno) => {
          this.LimparCampo();
          this.listarSetores();
          this.loading = false;
          this.toast.success("Registro salvo com sucesso!");
        },
          (error) => {
            this.loading = false;
            this.toast.error(error);
          });
      }
      else {
        this.funcaoCargoWebapiService.alterar(this.tbFuncaoCargo, this.HFtkn, (retorno) => {
          this.LimparCampo();
          this.listarSetores();
          this.loading = false;
          this.toast.success("Registro salvo com sucesso!")
        },
          (error) => {
            this.loading = false;
            this.toast.error(error);
          });
      }
    }
  }

  ExcluirRegistro() {
    this.tbFuncaoCargo.excluido = true;
    this.funcaoCargoWebapiService.alterar(this.tbFuncaoCargo, this.HFtkn, (retorno) => {
      this.LimparCampo();
      this.listarSetores();
      this.loading = false;
      this.habilitarExcluir = false;
      this.toast.success("Registro excluído com sucesso!")
    },
      (error) => {
        this.loading = false;
        this.toast.error(error);
      });
  }

  ValidarCampos() {
    if (this.tbFuncaoCargo.nome == undefined || "")
        {
          this.toast.error("Campo Nome é Obrigatório.");
          return false;
        }
    else {      
      return true;
    }
  }

  ngOnInit(): void {
  }
}

