import { Component, Inject, OnInit, ViewChild, forwardRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.css']
})
export class ContatoComponent implements OnInit {
  
  @ViewChild('frm') frm: NgForm;
  public get frmCtrls(): any { return this.frm ? this.frm.controls : {}; };

  public nome;
  public email;
  public assunto;
  public mensagem;

  constructor(@Inject(forwardRef(() => AppComponent)) public parent: AppComponent) {
    }
    
  ngOnInit(): void {
  }

}
