<form #f="ngForm">
  <section class="content">
    <div class="container-fluid">
      <!-- paginaçao por mes -->
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Agenda</h3>
            </div>
            <div class="card-body" style="padding-bottom: 0px;">
              <ul class="pagination pagination-month justify-content-center">
                <li class="page-item">
                  <a class="page-link" (click)="diminuirAno()">«</a>
                </li>
                <li class="page-item" [ngClass]="[mesSelecionado == 1 ? 'active' : '']" (click)="selecionarMes('01')">
                  <a class="page-link">
                    <p class="page-month">Jan</p>
                    <p class="page-year">{{anoAtual}}</p>
                  </a>
                </li>
                <li class="page-item" [ngClass]="[mesSelecionado == 2 ? 'active' : '']" (click)="selecionarMes('02')">
                  <a class="page-link">
                    <p class="page-month">Fev</p>
                    <p class="page-year">{{anoAtual}}</p>
                  </a>
                </li>
                <li class="page-item" [ngClass]="[mesSelecionado == 3 ? 'active' : '']" (click)="selecionarMes('03')">
                  <a class="page-link">
                    <p class="page-month">Mar</p>
                    <p class="page-year">{{anoAtual}}</p>
                  </a>
                </li>
                <li class="page-item" [ngClass]="[mesSelecionado == 4 ? 'active' : '']" (click)="selecionarMes('04')">
                  <a class="page-link">
                    <p class="page-month">Abr</p>
                    <p class="page-year">{{anoAtual}}</p>
                  </a>
                </li>
                <li class="page-item" [ngClass]="[mesSelecionado == 5 ? 'active' : '']" (click)="selecionarMes('05')">
                  <a class="page-link">
                    <p class="page-month">Mai</p>
                    <p class="page-year">{{anoAtual}}</p>
                  </a>
                </li>
                <li class="page-item" [ngClass]="[mesSelecionado == 6 ? 'active' : '']" (click)="selecionarMes('06')">
                  <a class="page-link">
                    <p class="page-month">Jun</p>
                    <p class="page-year">{{anoAtual}}</p>
                  </a>
                </li>
                <li class="page-item" [ngClass]="[mesSelecionado == 7 ? 'active' : '']" (click)="selecionarMes('07')">
                  <a class="page-link">
                    <p class="page-month">Jul</p>
                    <p class="page-year">{{anoAtual}}</p>
                  </a>
                </li>
                <li class="page-item" [ngClass]="[mesSelecionado == 8 ? 'active' : '']" (click)="selecionarMes('08')">
                  <a class="page-link">
                    <p class="page-month">Ago</p>
                    <p class="page-year">{{anoAtual}}</p>
                  </a>
                </li>
                <li class="page-item" [ngClass]="[mesSelecionado == 9 ? 'active' : '']" (click)="selecionarMes('09')">
                  <a class="page-link">
                    <p class="page-month">Set</p>
                    <p class="page-year">{{anoAtual}}</p>
                  </a>
                </li>
                <li class="page-item" [ngClass]="[mesSelecionado == 10 ? 'active' : '']" (click)="selecionarMes('10')">
                  <a class="page-link">
                    <p class="page-month">Out</p>
                    <p class="page-year">{{anoAtual}}</p>
                  </a>
                </li>
                <li class="page-item" [ngClass]="[mesSelecionado == 11 ? 'active' : '']" (click)="selecionarMes('11')">
                  <a class="page-link">
                    <p class="page-month">Nov</p>
                    <p class="page-year">{{anoAtual}}</p>
                  </a>
                </li>
                <li class="page-item" [ngClass]="[mesSelecionado == 12 ? 'active' : '']" (click)="selecionarMes('12')">
                  <a class="page-link">
                    <p class="page-month">Dez</p>
                    <p class="page-year">{{anoAtual}}</p>
                  </a>
                </li>
                <li class="page-item"><a class="page-link" (click)="aumentarAno()">»</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- fim paginacao por mes -->

      <!-- Timelime example  -->
      <div class="row">
        <div class="col-md-12">
          <!-- The time line -->
          <div class="timeline" *ngFor="let item of vwAgendaTelaPrincipal  | filtrosGrid:dataFiltroString">
            <!-- timeline time label -->
            <div class="time-label">
              <span class="bg-black col-md-2">{{item.dataPlanejada | separardatahora: 4}}</span>
            </div>
            <!-- /.timeline-label -->
            <!-- timeline item -->
            <div>
              <i class="fas fa-clock bg-gray"></i>
              <div class="timeline-item">
                <span class="time"><i class="fas fa-clock"></i> {{item.dataPlanejada | separardatahora: 2}}</span>
                <h3 class="timeline-header"><i class="fas fa-book mr-1" style="color:#fd7e14;"></i>{{item.nomeTema}}</h3>

                <div class="timeline-body row">
                  <div style="width: 180px;">Tipo do Treinamento:</div>
                  <div class="col-md-4"> <b>{{item.tipo}}</b></div>
                </div>
                <div class="timeline-body row">
                  <div style="width: 10%;">Participante:</div>
                  <div class="col-md-4"><i>{{item.nomeContratado}}</i></div>
                  <div style="width: 10%;">Setor:</div>
                  <div class="col-md-5"> <i>{{item.nomeSetor}}</i></div>
                  <div style="width: 10%;">Treinador:</div>
                  <div class="col-md-4"> <i>{{item.nomeTreinador}}</i> </div>
                  <div style="width: 10%;">Avaliador:</div>
                  <div class="col-md-3"> <i>{{item.nomeAvaliador}}</i> </div>
                </div>
                <div class="timeline-footer row col-md-12">
                  <!-- <div class="col-md-2">
                    <i><a href="#" style="text-decoration:none">Visualizar</a></i>
                  </div> -->
                  <!-- if(this.tbAgenda.dataRealizada.toString() == '2000-01-01T02:00:00')
                  this.tbAgenda.dataRealizada = undefined; -->

                  <div class="col-md-5" *ngIf="item.dataRealizada < '2001-01-01T02:00:00' && item.nomeTreinador != undefined">
                    <i class="far fa-hand-point-right"></i> <i><a routerLink="/principal/aplicar" [queryParams]="{idTema:item.idTema}" (click)="enviarDadosRegistro(item)" style="text-decoration:none">Realizar Treinamento</a></i>
                  </div>
                  <div class="col-md-5" *ngIf="item.dataRealizada > '2001-01-01T02:00:00'">
                    <i class="far fa-thumbs-up"></i><b class="text-green">Realizado</b>
                  </div>
                </div>
              </div>
            </div>
            <!-- END timeline item -->
            <div>
              <i class="fas fa-clock bg-gray"></i>
            </div>
          </div>
        </div>
        <!-- /.col -->
      </div>
    </div>
    <!-- /.timeline -->

  </section>
</form>