import { Injectable } from "@angular/core";
import { SessionService } from "../session.service";
import { Http } from '../http.service';
import { BaseWebApi } from "./base-webapi.service";
@Injectable({
  providedIn: 'root'
})
export class InstituicaoWebapiService extends BaseWebApi{

  constructor(httpService: Http, 
    sessionService: SessionService) {
      super(httpService, sessionService);
      this.prefixo = 'instituicao';
   }

  pesquisarInstituicaoPorCode(code: string, HFtkn: string, success?, error?) {
    return this.httpClient.get(this.prefixo + '/instituicaoporcode?code=' + code, HFtkn, success, error);
  }



}


