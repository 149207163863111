import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UsuarioWebApi } from '../shared/services/webapi/usuario-webapi.service';
import { TbUsuario } from '../entities/tbUsuario';
import { Router } from '@angular/router';
import { SessionService } from '../shared/services/session.service';
import { Utils } from '../shared/classes/utils';

@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.css']
})
export class PrincipalComponent implements OnInit {

  public usuario: TbUsuario = new TbUsuario();
  codeInstituicao = '';
  
  constructor(private sessionService: SessionService,
              private router: Router, 
              private toastr: ToastrService, 
              private usuarioWebApi: UsuarioWebApi,
              private utils: Utils) 
  { 
    this.usuario.usuario = localStorage.getItem("usuariohf");
    this.toastr.info('Bem vindo ao Habilita Farma!', ' Caro Usuário ' + this.usuario.usuario, {progressBar: true});
    this.codeInstituicao = localStorage.getItem("chaveId");
  }

  home() {
    this.router.navigateByUrl('/principal/home');
  }
  contratado() {
    this.router.navigateByUrl('/principal/contratado');
  }
  setor() {
    this.router.navigateByUrl('/principal/setor');
  }
  agendaInicial(){
    this.router.navigateByUrl('/principal/agendainicial');
  }
  agendaContinuada(){
    this.router.navigateByUrl('/principal/agendacontinuado');
  }
  acompanhamento(){
    this.router.navigateByUrl('/principal/acompanhamento');
  }
  avaliacao(){
    this.router.navigateByUrl('/principal/avaliacao');
  }
  registro(){
    this.router.navigateByUrl('/principal/registro');
  }
  manual(){
    this.router.navigateByUrl('/principal/manual');
  }
  sobre(){
    this.router.navigateByUrl('/principal/sobre');
  }
  contato(){
    this.router.navigateByUrl('/principal/contato');
  }

  sair(){
    // localStorage.removeItem("HFtkn");
    this.sessionService.logout();
    this.router.navigateByUrl('/login');
  }

  ngOnInit(): void {
    // this.usuario.usuario = this.usuarioWebApi.GetCurrentUser().usuario[0].nome;
    // this.toastr.info('Bem vindo ao Sistema!', 'Caro Usuário', {progressBar: true});
  }

}
