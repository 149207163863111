import { splitNsName } from '@angular/compiler';
import { Component, Inject, OnInit, ViewChild, ViewContainerRef, forwardRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppComponent } from 'src/app/app.component';
import { TbAgenda } from 'src/app/entities/tbAgenda';
import { TbContratado } from 'src/app/entities/tbContratado';
import { TbGrupo } from 'src/app/entities/tbGrupo';
import { TbInstituicao } from 'src/app/entities/tbInstituicao';
import { TbSetor } from 'src/app/entities/tbSetor';
import { TbTema } from 'src/app/entities/tbTema';
import { TbTopico } from 'src/app/entities/tbTopico';
import { Utils } from 'src/app/shared/classes/utils';
import { AgendaWebapiService } from 'src/app/shared/services/webapi/agenda-webapi.service';
import { ContratadoWebapiService } from 'src/app/shared/services/webapi/contratado-webapi.service';
import { GrupoWebapiService } from 'src/app/shared/services/webapi/grupo-webapi.service';
import { InstituicaoWebapiService } from 'src/app/shared/services/webapi/instituicao-webapi.service';
import { SetorWebapiService } from 'src/app/shared/services/webapi/setor-webapi.service';
import { TemaWebapiService } from 'src/app/shared/services/webapi/tema-webapi.service';
import { TopicoWebapiService } from 'src/app/shared/services/webapi/topico-webapi.service';
import { VwGridAgendaContinuadoWebapiService } from 'src/app/shared/services/webapi/vw-grid-agenda-continuado-webapi.service';
import { VwGridAgendaWebapiService } from 'src/app/shared/services/webapi/vw-grid-agenda-webapi.service';

@Component({
  selector: 'app-agendamento-continuado',
  templateUrl: './agendamento-continuado.component.html',
  styleUrls: ['./agendamento-continuado.component.css']
})
export class AgendamentoContinuadoComponent implements OnInit {

  @ViewChild('ngForm') frm: NgForm;
  public get frmCtrls(): any { return this.frm ? this.frm.controls : {}; };
  public tbGrupos: TbGrupo[] = new Array<TbGrupo>();
  public tbGrupo: TbGrupo = new TbGrupo();
  public tbAgendas: TbAgenda[] = new Array<TbAgenda>();
  public tbAgenda: TbAgenda = new TbAgenda();
  public tbAgendaRealizada: TbAgenda = new TbAgenda();
  public tbContratados: TbContratado[] = new Array<TbContratado>();
  public tbContratadosTreinadores: TbContratado[] = new Array<TbContratado>();
  public tbContratadosAvaliadores: TbContratado[] = new Array<TbContratado>();
  public tbTemas: TbTema[] = new Array<TbTema>();
  public tbTema: TbTema = new TbTema();
  public tbTopicos: TbTopico[] = new Array<TbTopico>();
  public tbInstituicao: TbInstituicao = new TbInstituicao();
  public loading: boolean = false;
  public habilitarExcluir: boolean = false;

  TopicoTexto;
  textoFiltro = '';
  codeInstituicao = '';
  HFtkn = localStorage.getItem("HFtkn");

  dataFiltro: Date;
  dataFiltroString;
  ano = new Date(Date.now()).getFullYear();

  TreinamentoPredefinido: boolean = false;
  mes = "0";
  anoSelecionado = "0";
  public agendaGrupoSelecionado = "";


  constructor(@Inject(forwardRef(() => AppComponent))
  public parent: AppComponent,
    public toast: ToastrService,
    public vcr: ViewContainerRef,
    private agendaWebapiService: AgendaWebapiService,
    private vwGridAgendaContinuadoWebapiService: VwGridAgendaContinuadoWebapiService,
    private grupoWebapiService: GrupoWebapiService,
    private contratadoWebapiService: ContratadoWebapiService,
    private temaWebapiService: TemaWebapiService,
    private instituicaoWebApiService: InstituicaoWebapiService,
    private topicoWebapiService: TopicoWebapiService,
    private utils: Utils,
    private router: Router) {
    let HFtkn = localStorage.getItem("HFtkn");
    this.codeInstituicao = localStorage.getItem("chaveId");
    this.BuscarInstituicaoPorCode(this.codeInstituicao);
  }

  BuscarInstituicaoPorCode(code) {
    this.instituicaoWebApiService.pesquisarInstituicaoPorCode(this.codeInstituicao, this.HFtkn, (data: TbInstituicao) => {
      this.tbAgenda.idInstituicao = data[0];
      this.listarGrupos();
      this.listarContratadoTreinador();
      this.listarContratadoAvaliador();
      this.listarAgenda();
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  listarAgenda() {
    this.vwGridAgendaContinuadoWebapiService.ListarAgendaContinuadoPorAno(this.tbAgenda.idInstituicao, this.ano, this.HFtkn, (dataAgenda: TbAgenda[]) => {
      this.tbAgendas = dataAgenda;
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  listarGrupos() {
    this.grupoWebapiService.selecionarPorIdInstituicao(1, this.HFtkn, (dataGrupo: TbGrupo[]) => {
      this.tbGrupos = dataGrupo;
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  listarContratadoTreinador() {
    this.contratadoWebapiService.listarContratadoTreinador(this.tbAgenda.idInstituicao, this.HFtkn, (dataContratado: TbContratado[]) => {
      this.tbContratadosTreinadores = dataContratado;
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  listarContratadoAvaliador() {
    this.contratadoWebapiService.listarContratadoAvaliador(this.tbAgenda.idInstituicao, this.HFtkn, (dataContratado: TbContratado[]) => {
      this.tbContratadosAvaliadores = dataContratado;
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  listarTemas(idGrupo) {
    this.temaWebapiService.TemaPorGrupo(this.tbAgenda.idInstituicao, idGrupo, this.HFtkn, (dataTema: TbTema[]) => {
      this.tbTemas = dataTema;
      this.TopicoTexto = this.tbTemas.find(t => t.id == this.tbAgenda.idTema).topicos;
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  mostrarTopicos() {
    this.topicoWebapiService.TopicosPorTema(1, this.tbAgenda.idTema, this.HFtkn, (dataTopico: TbTopico[]) => {
      this.tbTopicos = dataTopico;
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }


  mostrarTopico() {
    this.TopicoTexto = this.tbTemas.find(t => t.id == this.tbAgenda.idTema).topicos;
  }

  BuscarPorId(item) {
    this.agendaWebapiService.selecionarPorId(item.id, this.HFtkn, (data: TbAgenda) => {
      this.agendaGrupoSelecionado = item.nomeGrupo;
      this.tbGrupos = undefined;
      this.tbContratados = undefined;
      this.tbTemas = undefined;

      this.tbAgenda = data;
      if (this.tbAgenda.dataRealizada.toString() == '2000-01-01T02:00:00')
        this.tbAgenda.dataRealizada = undefined;

      this.TreinamentoPredefinido = this.tbAgenda.preDefinido;
      this.mes = this.tbAgenda.dataPlanejada.toString().substring(5, 7);
      this.anoSelecionado = this.tbAgenda.dataPlanejada.toString().substring(0, 4); //0000/00/00

      this.listarGrupos();
      this.tbGrupo.id = this.tbAgenda.idGrupo;

      this.listarTemas(this.tbAgenda.idGrupo);
      this.tbTema.id = this.tbAgenda.idTema;

      this.mostrarTopicos();

      this.loading = true;
      this.habilitarExcluir = true;
      window.scroll(0, 0);
    },
      (error) => {
        this.toast.error(error);
      });
  }

  LimparCampo() {
    this.tbAgenda.id = undefined;
    this.tbAgenda.tipo = undefined;
    this.tbAgenda.idGrupo = undefined;
    this.tbAgenda.idContratado = undefined;
    this.tbAgenda.idTema = undefined;
    this.tbAgenda.idTreinador = undefined;
    this.tbAgenda.idAvaliador = undefined;
    this.tbAgenda.dataPlanejada = undefined;
    this.tbAgenda.dataRealizada = undefined;
    this.tbAgenda.dataLimite = undefined;
    this.loading = false;
    this.habilitarExcluir = false;
    this.TopicoTexto = undefined;
    this.tbTemas = undefined;
  }

  Novo() {
    this.loading = true;
  }

  Salvar() {
    this.tbAgenda.idContratado = 0;
    this.tbAgenda.idSetor = 0;
    this.tbAgenda.preDefinido = true;
    this.tbAgenda.tipo = 2;

    if(this.tbAgenda.id == undefined)
      this.tbAgenda.preDefinido = false;

    if (this.ValidarCampos()) {
      this.loading = true;
      this.habilitarExcluir = false;
      this.tbAgenda.excluido = false;
      if (this.tbAgenda.id == undefined) {
        this.tbAgenda.dataRealizada = new Date('2000-01-01T00:00:00');
        this.agendaWebapiService.incluir(this.tbAgenda, this.HFtkn, (retorno) => {
          this.LimparCampo();
          this.listarAgenda();
          this.loading = false;
          this.toast.success("Registro salvo com sucesso!", "Agendamento", { progressBar: true });
        },
          (error) => {
            this.loading = false;
            this.toast.error(error);
          });
      }
      else {
        if (this.tbAgenda.dataRealizada == undefined)
          this.tbAgenda.dataRealizada = new Date('2000-01-01T00:00:00');

        this.agendaWebapiService.alterar(this.tbAgenda, this.HFtkn, (retorno) => {
          this.LimparCampo();
          this.listarAgenda();
          this.loading = false;
          this.toast.success("Registro salvo com sucesso!", "Agendamento", { progressBar: true });
        },
          (error) => {
            this.loading = false;
            this.toast.error(error);
          });
      }
    }
  }

  ExcluirRegistro() {
    this.tbAgenda.excluido = true;
    this.agendaWebapiService.alterar(this.tbAgenda, this.HFtkn, (retorno) => {
      this.LimparCampo();
      this.listarAgenda();
      this.loading = false;
      this.habilitarExcluir = false;
      this.toast.success("Registro excluído com sucesso!", "Agendamento", { progressBar: true });
    },
      (error) => {
        this.loading = false;
        this.toast.error(error);
      });
  }

  ValidarCampos() {

    // var retorno: boolean = true;
    // if ((this.mes != this.tbAgenda.dataPlanejada.toString().substring(5, 7) || this.anoSelecionado != this.tbAgenda.dataPlanejada.toString().substring(0, 4) ) && this.tbAgenda.preDefinido == true)
    //   retorno = false;
    
    // if(retorno == false) {
    //   this.toast.error("Esse Agendamento é Pré-Definido e não pode ser alterado para o mês selecionado.");
    //   return false;
    // }


    if (this.tbAgenda.idContratado == undefined ||
      this.tbAgenda.idInstituicao == undefined ||
      this.tbAgenda.idSetor == undefined ||
      this.tbAgenda.idTema == undefined ||
      this.tbAgenda.idAvaliador == undefined ||
      this.tbAgenda.idTreinador == undefined) {
      this.toast.error("Preencha todos os campos.");
      return false;
    }
    else {
      return true;
    }


    // if (this.agendaGrupoSelecionado == "Farmacêutico") {
    //   if (this.mes != this.tbAgenda.dataPlanejada.toString().substring(5, 7))
    //     retorno = false;
    //   if (this.mes != this.tbAgenda.dataPlanejada.toString().substring(5, 7))
    //     retorno = false;
    //   if (this.mes != this.tbAgenda.dataPlanejada.toString().substring(5, 7))
    //     retorno = false;
    //   if (this.mes != this.tbAgenda.dataPlanejada.toString().substring(5, 7))
    //     retorno = false;
    //   if (this.mes != this.tbAgenda.dataPlanejada.toString().substring(5, 7))
    //     retorno = false;
    //   if (this.mes != this.tbAgenda.dataPlanejada.toString().substring(5, 7))
    //     retorno = false;
    //   if (this.mes != this.tbAgenda.dataPlanejada.toString().substring(5, 7))
    //     retorno = false;
    //   if (this.mes != this.tbAgenda.dataPlanejada.toString().substring(5, 7))
    //     retorno = false;
    //   if (this.mes != this.tbAgenda.dataPlanejada.toString().substring(5, 7))
    //     retorno = false;
    //   if (this.mes != this.tbAgenda.dataPlanejada.toString().substring(5, 7))
    //     retorno = false;
    // }
    // else if (this.agendaGrupoSelecionado == "Laboratório") {
    //   if (this.mes != this.tbAgenda.dataPlanejada.toString().substring(5, 7))
    //     retorno = false;
    //   if (this.mes != this.tbAgenda.dataPlanejada.toString().substring(5, 7))
    //     retorno = false;
    //   if (this.mes != this.tbAgenda.dataPlanejada.toString().substring(5, 7))
    //     retorno = false;
    //   if (this.mes != this.tbAgenda.dataPlanejada.toString().substring(5, 7))
    //     retorno = false;
    //   if (this.mes != this.tbAgenda.dataPlanejada.toString().substring(5, 7))
    //     retorno = false;
    //   if (this.mes != this.tbAgenda.dataPlanejada.toString().substring(5, 7))
    //     retorno = false;

    // }
    // else if (this.agendaGrupoSelecionado == "Atendimento") {
    //   if (this.mes != this.tbAgenda.dataPlanejada.toString().substring(5, 7))
    //     retorno = false;
    //   if (this.mes != this.tbAgenda.dataPlanejada.toString().substring(5, 7))
    //     retorno = false;
    //   if (this.mes != this.tbAgenda.dataPlanejada.toString().substring(5, 7))
    //     retorno = false;
    //   if (this.mes != this.tbAgenda.dataPlanejada.toString().substring(5, 7))
    //     retorno = false;
    //   if (this.mes != this.tbAgenda.dataPlanejada.toString().substring(5, 7))
    //     retorno = false;
    //   if (this.mes != this.tbAgenda.dataPlanejada.toString().substring(5, 7))
    //     retorno = false;
    // }

    

    // if (this.TreinamentoPredefinido == true && this.mes != this.tbAgenda.dataPlanejada.toString().substring(5, 7)) {

    //   if (this.mes == "01" && this.tbAgenda.dataPlanejada.toString().substring(5, 7) == "02") {
    //     return true;
    //   }
    //   if (this.mes == "02" && this.tbAgenda.dataPlanejada.toString().substring(5, 7) == "01") {
    //     return true;
    //   }
    //   if (this.mes == "03" && this.tbAgenda.dataPlanejada.toString().substring(5, 7) == "04" && this.agendaGrupoSelecionado != "Farmacêutico") {
    //     return true;
    //   }
    //   if (this.mes == "04" && this.tbAgenda.dataPlanejada.toString().substring(5, 7) == "03" && this.agendaGrupoSelecionado != "Farmacêutico") {
    //     return true;
    //   }
    //   if (this.mes == "05" && this.tbAgenda.dataPlanejada.toString().substring(5, 7) == "06" && this.agendaGrupoSelecionado != "Farmacêutico") {
    //     return true;
    //   }
    //   if (this.mes == "06" && this.tbAgenda.dataPlanejada.toString().substring(5, 7) == "05" && this.agendaGrupoSelecionado != "Farmacêutico") {
    //     return true;
    //   }
    //   if (this.mes == "07" && this.tbAgenda.dataPlanejada.toString().substring(5, 7) == "08" && this.agendaGrupoSelecionado != "Farmacêutico") {
    //     return true;
    //   }
    //   if (this.mes == "08" && this.tbAgenda.dataPlanejada.toString().substring(5, 7) == "07" && this.agendaGrupoSelecionado != "Farmacêutico") {
    //     return true;
    //   }
    //   if (this.mes == "09" && this.tbAgenda.dataPlanejada.toString().substring(5, 7) == "10" && this.agendaGrupoSelecionado != "Farmacêutico") {
    //     return true;
    //   }
    //   if (this.mes == "10" && this.tbAgenda.dataPlanejada.toString().substring(5, 7) == "09" && this.agendaGrupoSelecionado != "Farmacêuticos") {
    //     return true;
    //   }
    //   if (this.mes == "11" && this.tbAgenda.dataPlanejada.toString().substring(5, 7) == "12") {
    //     return true;
    //   }
    //   if (this.mes == "12" && this.tbAgenda.dataPlanejada.toString().substring(5, 7) == "11") {
    //     return true;
    //   }

    //   this.toast.error("Esse Agendamento é Pré-Definido e não pode ser alterado para o mês selecionado.");
    //   return false;
    // }
  }

  ngOnInit(): void {
  }
}
