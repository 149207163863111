import { Component, Inject, OnInit, ViewChild, ViewContainerRef, forwardRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppComponent } from 'src/app/app.component';
import { TbGrupo } from 'src/app/entities/tbGrupo';
import { TbInstituicao } from 'src/app/entities/tbInstituicao';
import { TbSetor } from 'src/app/entities/tbSetor';
import { TbTema } from 'src/app/entities/tbTema';
import { TbTemaSetor } from 'src/app/entities/tbTemaSetor';
import { Utils } from 'src/app/shared/classes/utils';
import { GrupoWebapiService } from 'src/app/shared/services/webapi/grupo-webapi.service';
import { InstituicaoWebapiService } from 'src/app/shared/services/webapi/instituicao-webapi.service';
import { SetorWebapiService } from 'src/app/shared/services/webapi/setor-webapi.service';
import { TemaSetorWebapiService } from 'src/app/shared/services/webapi/tema-setor-webapi.service';
import { TemaWebapiService } from 'src/app/shared/services/webapi/tema-webapi.service';

@Component({
  selector: 'app-setor',
  templateUrl: './setor.component.html',
  styleUrls: ['./setor.component.css']
})
export class SetorComponent implements OnInit {

  @ViewChild('ngForm') frm: NgForm;
  public get frmCtrls(): any { return this.frm ? this.frm.controls : {}; };
  public tbSetores: TbSetor[] = new Array<TbSetor>();
  public tbGrupos: TbGrupo[] = new Array<TbGrupo>();
  public tbTemas: TbTema[] = new Array<TbTema>();
  public tbTemaSetor: TbTemaSetor = new TbTemaSetor();
  public tbSetor: TbSetor = new TbSetor();
  public tbInstituicao: TbInstituicao = new TbInstituicao();
  public loading: boolean = false;
  public habilitarExcluir: boolean = false;

  textoFiltro = '';
  codeInstituicao = '';
  HFtkn = localStorage.getItem("HFtkn");

  constructor(@Inject(forwardRef(() => AppComponent))
  public parent: AppComponent,
    public toast: ToastrService,
    public vcr: ViewContainerRef,
    private setorWebapiService: SetorWebapiService,
    private temaSetorApiService: TemaSetorWebapiService,
    private grupoWebapiService: GrupoWebapiService,
    private instituicaoWebApiService: InstituicaoWebapiService,
    private temaWebapiService: TemaWebapiService,
    private utils: Utils,
    private router: Router) {
    let HFtkn = localStorage.getItem("HFtkn");
    this.codeInstituicao = localStorage.getItem("chaveId");
    this.BuscarInstituicaoPorCode(this.codeInstituicao);
  }

  BuscarInstituicaoPorCode(code) {
    this.instituicaoWebApiService.pesquisarInstituicaoPorCode(this.codeInstituicao, this.HFtkn, (data: TbInstituicao) => {
      this.tbInstituicao.id = data[0];
      this.listarSetores();
      this.listarGrupos();
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  listarSetores() {
    this.setorWebapiService.selecionarPorIdInstituicao(this.tbInstituicao.id, this.HFtkn, (dataSetor: TbSetor[]) => {
      this.tbSetores = dataSetor;
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  listarGrupos() {
    this.grupoWebapiService.selecionarPorIdInstituicao(1, this.HFtkn, (data: TbGrupo[]) => {
      this.tbGrupos = data;
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  cadastrarTodosTemasQuandoCriarSetor(idSetor) {

    var idSetorParaModelo;
    if(this.tbSetor.idGrupo == 1)
      idSetorParaModelo = 29; // id de setor farmaceutico
    else if(this.tbSetor.idGrupo == 2)
      idSetorParaModelo = 30; // id de setor laboratorio
    else if(this.tbSetor.idGrupo == 3)
      idSetorParaModelo = 32; // id de setor atendimento
    else if(this.tbSetor.idGrupo == 10)
      idSetorParaModelo = 45; // id de setor limpeza

    this.temaWebapiService.TemaPorSetor(1, idSetorParaModelo, this.HFtkn, (dataTema: TbTema[]) => {
      this.tbTemas = dataTema;
      this.tbTemaSetor.excluido = false;
      this.tbTemaSetor.idInstituicao = this.tbInstituicao.id;
      this.tbTemaSetor.idSetor = idSetor;

      this.tbTemas.forEach(element => {
        this.tbTemaSetor.idTema = element.id;
        if (element.tipo == 1 && element.periocidade == 0) {
          this.temaSetorApiService.incluir(this.tbTemaSetor, this.HFtkn, (retorno) => {
          },
            (error) => {
              this.loading = false;
              this.toast.error(error);
            });
        }
      });
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  BuscarPorId(item) {
    this.setorWebapiService.selecionarPorId(item.id, this.HFtkn, (data: TbSetor) => {
      this.tbSetor = data;
      this.loading = true;
      this.habilitarExcluir = true;
      window.scroll(0, 0);
    },
      (error) => {
        this.toast.error(error);
      });
  }


  LimparCampo() {
    this.tbSetor.nome = undefined;
    this.tbSetor.descricao = undefined;
    this.tbSetor.excluido = undefined;
    this.tbSetor.id = undefined;
    this.loading = false;
    this.habilitarExcluir = false;
  }

  Novo() {
    this.loading = true;
  }

  Salvar() {
    if (this.ValidarCampos()) {
      this.loading = true;
      this.habilitarExcluir = false;
      this.tbSetor.excluido = false;
      this.tbSetor.idInstituicao = this.tbInstituicao.id;

      if (this.tbSetor.descricao == undefined || "") {
        this.tbSetor.descricao = this.tbSetor.nome;
      }

      if (this.tbSetor.id == undefined) {
        this.setorWebapiService.incluir(this.tbSetor, this.HFtkn, (retorno) => {
          this.LimparCampo();
          this.cadastrarTodosTemasQuandoCriarSetor(retorno);
          this.listarSetores();
          this.loading = false;
          this.toast.success("Registro salvo com sucesso!");
        },
          (error) => {
            this.loading = false;
            this.toast.error(error);
          });
      }
      else {
        this.setorWebapiService.alterar(this.tbSetor, this.HFtkn, (retorno) => {
          this.LimparCampo();
          this.listarSetores();
          this.loading = false;
          this.toast.success("Registro salvo com sucesso!")
        },
          (error) => {
            this.loading = false;
            this.toast.error(error);
          });
      }
    }
  }

  ExcluirRegistro() {
    this.tbSetor.excluido = true;
    this.setorWebapiService.alterar(this.tbSetor, this.HFtkn, (retorno) => {
      this.LimparCampo();
      this.listarSetores();
      this.loading = false;
      this.habilitarExcluir = false;
      this.toast.success("Registro excluído com sucesso!")
    },
      (error) => {
        this.loading = false;
        this.toast.error(error);
      });
  }

  ValidarCampos() {
    if (this.tbSetor.nome == undefined || "") {
      this.toast.error("Campo Nome é Obrigatório.");
      return false;
    }
    else {
      return true;
    }
  }

  ngOnInit(): void {
  }
}
