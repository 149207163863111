<body style="background-color: #f8f8f8;">
    <div class="box" style="background-color: #f8f8f8;">
      <img src="../../../assets/farmacias/2207-1/logo.jpg" id="logo">
      <div id="title">
         <a href="../../../assets/Manual Rápido Habilita Farma.pdf" download="Manual Rápido Habilita Farma.pdf">Clique aqui e faça dowload do Guia Rápido</a>
      </div>
      <div id="descricao">
        <br>
        <a href="mailto:contato@habilitafarma.com.br">contato@habilitafarma.com.br</a>
        
      </div>
    </div>
  </body>