import { Injectable } from '@angular/core';
import { BaseWebApi } from './base-webapi.service';
import { Http } from '../http.service';
import { SessionService } from '../session.service';

@Injectable({
  providedIn: 'root'
})
export class AcompanhamentoInicialWebapiService extends BaseWebApi {
  constructor(httpService: Http,
    sessionService: SessionService) {
    super(httpService, sessionService);
    this.prefixo = 'acompanhamentoinicial';
  }

  SelecionarAcompanhamentoInicialPorIdAgenda(idInstituicao: number, idContratado: number, idTema: number, HFtkn: string, success?, error?) {
    return this.httpClient.get(this.prefixo + '/SelecionarAcompanhamentoInicialPorIdAgenda?idInstituicao=' + idInstituicao + '&idContratado=' + idContratado + '&idTema=' + idTema, HFtkn, success, error);
  }
}
