import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtrosGrid'
})
export class FiltrosGridPipe implements PipeTransform {

  transform(list: any, obj: any): any {

    if (!list) return null;
    if (!obj) return list;

    obj = obj.toLowerCase();
    debugger;
    var retorno = list.filter(function (item) {
      return JSON.stringify(item)
        .toLowerCase()
        .includes(obj);
    })
    return retorno;
  }
}
