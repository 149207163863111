<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <!-- Default box -->
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Colaboradores</h3>
                    </div>
                    <form #f="ngForm">
                        <div class="row" style="padding: 10px; text-align: right;">
                            <div class="col-sm-8" style="text-align: left;">
                                <button type="button" (click)="Novo()" data-toggle="modal" data-target="#novo"
                                    class="btn btn-secondary btn-sm">
                                    <span><i class="fas fa-save"></i> Novo</span>
                                </button>
                            </div>
                            <div class="col-sm-4">
                                <input class="form-control mr-sm-2" type="search"
                                    placeholder="Pesquisar por Nome, Setor ou Função." name="textoFiltro"
                                    id="textoFiltro" [(ngModel)]="textoFiltro">

                            </div>
                        </div>
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <!-- <th scope="col">Código</th> -->
                                    <th scope="col">Nome</th>
                                    <th scope="col">Setor</th>
                                    <th scope="col">Função/Cargo</th>
                                    <th scope="col" class="text-center">Editar</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <tr *ngFor="let item of vwContratadosSetor | filtrosGrid:textoFiltro">
                                    <!-- <td width="auto" [innerHtml]="item.id"></td> -->
                                    <td width="auto" [innerHtml]="item.nomeContratado"></td>
                                    <td width="auto" [innerHtml]="item.nomeSetor"></td>
                                    <td width="auto" [innerHtml]="item.funcaoCargo"></td>
                                    <td class="text-center">
                                        <button type="button" class="btn btn-outline-default btn-sm"
                                            (click)="BuscarPorId(item)" data-toggle="modal" data-target="#novo">
                                            <i class="nav-icon fas fa-edit"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>


<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Confirma a exclusão do registro?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Confirma a exclusão do registro?
            </div>
            <div class="modal-footer">
                <button type="button" (click)="LimparCampo()" class="btn btn-secondary btn-sm"
                    data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-warning btn-sm" (click)="ExcluirRegistro()"
                    data-dismiss="modal">Confirmar</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal novo contratado-->
<div class="modal fade" id="novo" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"
    data-backdrop="static">

    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Novo Cadastro</h5>
            </div>
            <div class="modal-body">
                <div class="row gx-3 gy-2 align-items-center">
                    <div class="form-group col-md-12">
                        <div class="row">
                            <div class="col-md-6"><label for="nome">Nome</label></div>
                        </div>
                        <input autofocus type="text" class="form-control" id="tbContratado.nome"
                            [(ngModel)]="tbContratado.nome" name="tbContratado.nome" placeholder="Nome"
                            [disabled]="!this.loading">
                    </div>
                    <div class="form-group col-md-8">
                        <label for="inputEmail4">Email</label>
                        <input type="email" class="form-control" id="tbContratado.email"
                            [(ngModel)]="tbContratado.email" name="tbContratado.email" placeholder="Email"
                            [disabled]="!this.loading">
                    </div>
                    <div class="form-group col-md-4">
                        <label for="inputTelefone">Telefone</label>
                        <input type="text" class="form-control" id="tbContratado.telefone"
                            [(ngModel)]="tbContratado.telefone" name="tbContratado.telefone" placeholder="Telefone"
                            [disabled]="!this.loading">
                    </div>
                    <div class="form-group col-md-8">
                        <label for="inputSetor">Setor</label>
                        <select id="tbContratadoSetor.idSetor" [(ngModel)]="tbContratadoSetor.idSetor"
                            [disabled]="!this.loading" name="tbContratadoSetor.idSetor" class="form-control">
                            <!-- <option [value]="0" selected>Escolha...</option> -->
                            <option [value]="item.id" *ngFor="let item of tbSetores">{{item.nome}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-4">
                        <label for="inputFuncaoCargo">Função / Cargo</label>
                        <select id="tbContratado.idFuncaoCargo" [(ngModel)]="tbContratado.idFuncaoCargo"
                            [disabled]="!this.loading" name="tbContratado.idFuncaoCargo" class="form-control">
                            <option [value]="item.id" *ngFor="let item of tbFuncoesCargos">{{item.nome}}</option>
                            <!-- <option [value]="0" selected>Escolha...</option> -->
                        </select>
                    </div>
                    <!-- <div class="form-group col-md-3">
                        <label for="inputDataNascimmento">Data Nascimmento</label>
                        <input type="date" class="form-control" name="datanasc" [(ngModel)]="datanasc" id="datanasc"
                            [disabled]="!this.loading">
                    </div> -->
                    <!-- <div class="form-group col-md-3">
                        <label for="inputState">Escolaridade</label>
                        <select id="tbContratado.escolaridade" [(ngModel)]="tbContratado.escolaridade"
                            [disabled]="!this.loading" name="tbContratado.escolaridade" class="form-control">
                            <option [value]="0">Escolha...</option>
                            <option [value]="1">Fundamental 1</option>
                            <option [value]="2">Fundamental 2</option>
                            <option [value]="3">Ensino Médio</option>
                            <option [value]="4">Ensino Técnico</option>
                            <option [value]="5">Ensino Superior</option>
                        </select>
                    </div> -->
                    <div class="form-group col-md-3">
                        <label for="inputTreinador">Treinador</label>
                        <select id="tbContratado.Treinador" [(ngModel)]="tbContratado.treinador"
                            name="tbContratado.treinador" [disabled]="!this.loading" class="form-control">
                            <option [value]="0" selected>Escolha...</option>
                            <option [value]=true>Sim</option>
                            <option [value]=false>Não</option>
                        </select>
                    </div>
                    <div class="form-group col-md-3">
                        <label for="inputAvaliador">Avaliador</label>
                        <select id="tbContratado.Avaliador" [(ngModel)]="tbContratado.avaliador"
                            name="tbContratado.avaliador" [disabled]="!this.loading" class="form-control">
                            <option [value]="0" selected>Escolha...</option>
                            <option [value]=true>Sim</option>
                            <option [value]=false>Não</option>
                        </select>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="inputAvaliador">Usuário do Sistema</label>
                        <div>
                            <label class="switch">
                                <input type="checkbox" [(ngModel)]="tbContratado.usuario" name="tbContratado.usuario"
                                    id="tbContratado.usuario" value="" [disabled]="tbContratado.email == undefined">
                                <span class="slider round"></span>
                            </label>&nbsp;
                        </div>
                        <!-- <label for="todoCheck1"></label> -->
                        <!-- <span class="text">Usuário do Sistema</span> -->
                        <!-- <div>
                            <input [disabled]="tbContratado.email == undefined" type="checkbox" [(ngModel)]="tbContratado.usuario" name="tbContratado.usuario" id="tbContratado.usuario" >
                            <label for="inputState" style="padding-left: 15px;">Usuário do Sistema</label>
                        </div> -->
                    </div>

                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary btn-sm" (click)="LimparCampo()" data-dismiss="modal">
                    Cancelar</button>
                <button type="button" class="btn btn-success btn-sm" (click)="Salvar()" data-dismiss="modal">
                    Confirmar</button>
                <div [hidden]="!habilitarExcluir">
                    <button type="button" data-toggle="modal" data-target="#exampleModal" data-dismiss="modal"
                        class="btn btn-danger btn-sm">Excluir</button>
                </div>
            </div>
        </div>
    </div>
</div>