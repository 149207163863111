<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <!-- Default box -->
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Farmácias</h3>
                    </div>
                    <form #f="ngForm">
                        <div class="row" style="padding: 10px; text-align: right;">
                            <div class="col-sm-8" style="text-align: left;">
                                <button type="button" (click)="Novo()" data-toggle="modal" data-target="#novo"
                                    class="btn btn-secondary btn-sm" style="width: 150px;">
                                    <span><i class="fas fa-save"></i> Novo</span>
                                </button>
                            </div>
                            <div class="col-sm-4">
                                <input class="form-control mr-sm-2" type="search" placeholder="Pesquisar por Nome."
                                    name="textoFiltro" id="textoFiltro" [(ngModel)]="textoFiltro">

                            </div>
                        </div>
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <!-- <th scope="col">Código</th> -->
                                    <!-- <th scope="col">Codigo</th> -->
                                    <th scope="col">Nome Fantasia </th>
                                    <!-- <th scope="col">Razao Social </th>
                                    <th scope="col">CNPJ </th>
                                    <th scope="col">Endereço </th> -->
                                    <th scope="col">Telefone </th>
                                    <!-- <th scope="col">Responsavel Legal </th> -->
                                    <!-- <th scope="col">Responsavel Tecnico </th>
                                    <th scope="col">Responsavel Garantia Qualidade </th> -->
                                    <th scope="col" class="text-center">Editar</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <tr *ngFor="let item of tbInstituicoes | filtrosGrid:textoFiltro">
                                    <td width="auto" [innerHtml]="item.nomeFantasia"> </td>
                                    <!-- <td width="auto" [innerHtml]="item.razaoSocial"> </td>
                                    <td width="auto" [innerHtml]="item.cnpj"> </td>
                                    <td width="auto" [innerHtml]="item.endereco"> </td> -->
                                    <td width="auto" [innerHtml]="item.telefone"> </td>
                                    <!-- <td width="auto" [innerHtml]="item.responsavelLegal"> </td> -->
                                    <!-- <td width="auto" [innerHtml]="item.ryesponsavelTecnico"> </td>
                                    <td width="auto" [innerHtml]="item.responsavelGarantiaQualidade"> </td> -->
                                    <td class="text-center">
                                        <button type="button" class="btn btn-outline-default btn-sm"
                                            (click)="BuscarPorId(item)" data-toggle="modal" data-target="#novo">
                                            <i class="nav-icon fas fa-edit"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Confirma a exclusão do registro?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Confirma a exclusão do registro?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">
                    Cancelar</button>
                <button type="button" class="btn btn-warning btn-sm" (click)="ExcluirRegistro()" data-dismiss="modal">
                    Confirmar</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal novo setor-->
<div class="modal fade" id="novo" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"
    data-backdrop="static">

    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Novo Cadastro</h5>
            </div>
            <div class="modal-body">
                <div class="row gx-3 gy-2 align-items-center">

                    <div class="form-group col-md-6">
                        <label for="codigo"><b>Código</b></label>
                        <input type="text" class="form-control" id="tbInstituicao.codigo" [(ngModel)]="tbInstituicao.codigo"
                            name="tbInstituicao.codigo" placeholder="codigo" [disabled]="!this.loading">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="cnpj"><b>cnpj</b></label>
                        <input type="text" class="form-control" id="tbInstituicao.cnpj" [(ngModel)]="tbInstituicao.cnpj"
                            name="tbInstituicao.cnpj" placeholder="cnpj" [disabled]="!this.loading">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="nomeFantasia"><b>Nome Fantasia</b></label>
                        <input type="text" class="form-control" id="tbInstituicao.nomeFantasia" [(ngModel)]="tbInstituicao.nomeFantasia"
                            name="tbInstituicao.nomeFantasia" placeholder="nome Fantasia" [disabled]="!this.loading">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="nomeFantasia"><b>Razao Social</b></label>
                        <input type="text" class="form-control" id="tbInstituicao.razaoSocial" [(ngModel)]="tbInstituicao.razaoSocial"
                            name="tbInstituicao.razaoSocial" placeholder="razaoSocial" [disabled]="!this.loading">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="endereco"><b>endereco</b></label>
                        <input type="text" class="form-control" id="tbInstituicao.endereco" [(ngModel)]="tbInstituicao.endereco"
                            name="tbInstituicao.endereco" placeholder="endereco" [disabled]="!this.loading">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="telefone"><b>telefone</b></label>
                        <input type="text" class="form-control" id="tbInstituicao.telefone" [(ngModel)]="tbInstituicao.telefone"
                            name="tbInstituicao.telefone" placeholder="telefone" [disabled]="!this.loading">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="idResponsavelLegal"><b>Responsavel Legal</b></label>
                        <input type="text" class="form-control" id="tbInstituicao.idResponsavelLegal" [(ngModel)]="tbInstituicao.idResponsavelLegal"
                            name="tbInstituicao.idResponsavelLegal" placeholder="idResponsavelLegal" [disabled]="!this.loading">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="idResponsavelTecnico"><b>Responsavel Tecnico</b></label>
                        <input type="text" class="form-control" id="tbInstituicao.idResponsavelTecnico" [(ngModel)]="tbInstituicao.idResponsavelTecnico"
                            name="tbInstituicao.idResponsavelTecnico" placeholder="idResponsavelTecnico" [disabled]="!this.loading">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="idResponsavelGarantiaQualidade"><b>Responsavel Garantia Qualidade</b></label>
                        <input type="text" class="form-control" id="tbInstituicao.idResponsavelGarantiaQualidade" [(ngModel)]="tbInstituicao.idResponsavelGarantiaQualidade"
                            name="tbInstituicao.idResponsavelGarantiaQualidade" placeholder="idResponsavelGarantiaQualidade" [disabled]="!this.loading">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="dataCadastro"><b>data Cadastro</b></label>
                        <input type="text" class="form-control" id="tbInstituicao.dataCadastro" [(ngModel)]="tbInstituicao.dataCadastro"
                            name="tbInstituicao.dataCadastro" placeholder="dataCadastro" [disabled]="true">
                    </div>

                    <!-- <div class="form-group col-md-6">
                        <label for="inputSetor">Grupo</label>
                        <select id="tbInstituicao.idGrupo" [(ngModel)]="tbInstituicao.idGrupo" [disabled]="!this.loading"
                            name="tbInstituicao.idGrupo" class="form-control">
                            <option [value]="0" selected>Escolha...</option>
                            <option [value]="item.id" *ngFor="let item of tbGrupos">{{item.nome}}</option>
                        </select>
                    </div> -->
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary btn-sm" (click)="LimparCampo()" data-dismiss="modal">
                    Cancelar</button>
                <button type="button" class="btn btn-success btn-sm" (click)="Salvar()" data-dismiss="modal">
                    Confirmar</button>
                <div [hidden]="!habilitarExcluir">
                    <button type="button" data-toggle="modal" data-target="#exampleModal" data-dismiss="modal"
                        class="btn btn-danger btn-sm">
                        Excluir</button>
                </div>
            </div>
        </div>
    </div>
</div>