import { Injectable } from '@angular/core';
import { SessionService } from "../session.service";
import { Http } from '../http.service';
import { BaseWebApi } from "./base-webapi.service";

@Injectable({
  providedIn: 'root'
})
export class TemaWebapiService extends BaseWebApi {
  constructor(httpService: Http,
    sessionService: SessionService) {
    super(httpService, sessionService);
    this.prefixo = 'tema';
  }

  TemaPorSetor(idInstituicao: number, idSetor: number, auth_token: string, success?, error?) {
    return this.httpClient.get(this.prefixo + '/TemasPorSetor?idInstituicao=' + idInstituicao + '&idSetor=' + idSetor, auth_token, success, error);
  }
  
  TemaPorGrupo(idInstituicao: number, idGrupo: number, auth_token: string, success?, error?) {
    return this.httpClient.get(this.prefixo + '/TemasPorGrupo?idInstituicao=' + idInstituicao + '&idGrupo=' + idGrupo, auth_token, success, error);
  }

}
