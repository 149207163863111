import { Component, Inject, OnInit, ViewChild, ViewContainerRef, forwardRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppComponent } from 'src/app/app.component';
import { EnviarEmailWebapiService } from 'src/app/shared/services/webapi/enviar-email-webapi.service';
import { UsuarioWebApi } from 'src/app/shared/services/webapi/usuario-webapi.service';

@Component({
  selector: 'app-testecronjoghf',
  templateUrl: './testecronjoghf.component.html',
  styleUrls: ['./testecronjoghf.component.css']
})
export class TestecronjoghfComponent implements OnInit {

  @ViewChild('ngForm') frm: NgForm;
  public get frmCtrls(): any { return this.frm ? this.frm.controls : {}; };
  public HFtkn;

  private params: Params;
  codeInstituicao = '';

  constructor(@Inject(forwardRef(() => AppComponent))
  private route: ActivatedRoute,
    public parent: AppComponent,
    public toast: ToastrService,
    public vcr: ViewContainerRef,
    private usuarioWebApi: UsuarioWebApi,
    public enviaremailagenda: EnviarEmailWebapiService) {
    this.enviar();
  }

  enviar() {
    try {
        this.usuarioWebApi.gettkn("admin", "admin2207@", "2207-1", (data) => {
            if (data == null) {
                return;
            }
            this.HFtkn = data;
            localStorage.setItem("HFtkn", this.HFtkn.token);

            let HFtkn = localStorage.getItem("HFtkn");
            this.enviaremailagenda.testecronjob(HFtkn,
                (data) => {
                    
                },
                (error, status) => {
                });
        },
            (error) => {
                this.toast.error("Falha no Sistema. Entre em contato com o Suporte.");
            });

    } catch (error) {            
    }

}



ngOnInit(): void {
}

}
