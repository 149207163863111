<form #f="ngForm">
    <div class="row">
        <div class="text-white titulo col-sm-12">
            <p></p>
        </div>

        <p></p>
        <div class="col-sm-12">
            <h1>Vincular Contratado por Setores</h1>
            <p>Realizar vínculo de contratado por Setores.</p>
        </div>
    </div>

    <div class="form-group">
        <label for="inputState"><b>Setor</b></label>
        <select id="tbContratado.escolaridade" name="tbContratado.escolaridade"
            class="form-control">
            <option value="1">Setor 1</option>
            <option value="2">Setor 2</option>
            <option value="3">Setor 3</option>
            <option value="4">Setor 4</option>
            <option value="5">Setor 5</option>
            <option selected>Escolha...</option>
        </select>
    </div>

    <div class="form-group row">
        <div class="panel-group col-md-6">
            <div class="panel panel-default">
                <div class="panel-body"><b>Contratados vinculados</b></div>
                <div class="list-group">
                    <a class="list-group-item list-group-item-action"><button type="button" class="btn btn-primary btn-sm">Del</button>Almir Sater</a>
                    <a class="list-group-item list-group-item-action"><button type="button" class="btn btn-primary btn-sm">Del</button>Carolina Franco</a>
                </div>
            </div>
        </div>
        <div class="panel-group col-md-6">
            <div class="panel panel-default">
                <div class="panel-body"><b>Contratados</b></div>
                <div class="list-group">
                    <a class="list-group-item list-group-item-action"><button type="button" class="btn btn-primary btn-sm">Add</button>Luiz Veríssimo</a>
                    <a class="list-group-item list-group-item-action"><button type="button" class="btn btn-primary btn-sm">Add</button>José Edson</a>
                    <a class="list-group-item list-group-item-action"><button type="button" class="btn btn-primary btn-sm">Add</button>Frejat</a>
                </div>
            </div>
        </div>
    </div>


</form>

<!-- Remove the container if you want to extend the Footer to full width. -->
<div class="my-5">

    <footer class="text-center text-lg-start" style="background-color: #070707;">

        <!-- Copyright -->
        <div class="text-center text-white p-3" style="background-color: rgba(0, 0, 0, 0.2);">
            © 2023 Copyright:
            <a class="text-white" href="https://habilitafarma.com/">habilitafarma.com</a>
        </div>
        <!-- Copyright -->
    </footer>

</div>
<!-- End of .container -->