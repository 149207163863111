<section class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <!-- Default box -->
        <!-- TO DO List -->
        <div class="card">
          <div style="text-align: right;">
            <a (click)="imprimir()" rel="noopener" target="_blank" class="btn btn-defaultpx;"><i
            class="fas fa-print"></i></a>
          </div>
          
          <!-- /.card-header -->
          <form>
            <div class="card-body">
              <table class="col-md-12">
                <tbody>
                  <tr>
                    <td rowspan="3" style="width: 10%;">
                      <img [src]="pathLogo" alt="HABILITAFARMA" style="width: 300px;">
                    </td>
                    <td colspan="2" style="width: 312px;text-align: center;">
                      <strong>REGISTRO DA QUALIDADE</strong>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="width: 312px;text-align: center;">
                      <strong>REGISTRO DE PRESEN&Ccedil;A EM TREINAMENTO</strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 70%;padding-left: 10px;">
                      Aprovado por: {{responsavel}}
                    </td>
                    <td style="width: 30%;text-align: center;">
                      Data: {{dataDoRegistro | date:'dd/MM/yyyy'}}
                   </td>
                  </tr>
                </tbody>
              </table>
              <p>&nbsp;</p>
              <table class="col-md-12">
                <tbody>
                  <tr>
                    <td style="width: 226px;" class="align-middle" >
                      <strong>Respons&aacute;vel pelo Treinamento</strong>
                    </td>
                    <td style="width: 435px; padding-left: 10px;"class="align-middle">
                      {{DadosRegistro.nomeTreinador}}
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 226px;">
                      <strong>Cargo / Fun&ccedil;&atilde;o</strong>
                    </td>
                    <td style="width: 435px; padding-left: 10px;">
                      {{vwContratadoResponsavelFuncaoCargo.nomeFuncaoCargo}}
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 226px;">
                      <strong>Tema do Treinamento</strong>
                    </td>
                    <td style="width: 435px; padding-left: 10px;">
                      {{DadosRegistro.nomeTema}}
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 226px;">
                      <strong>Tipo do Treinamento</strong>
                    </td>
                    <td style="width: 435px; padding-left: 10px;">
                      {{tipoTreinamento}}
                    </td>
                  </tr>                 
                  <tr>
                    <td style="width: 226px;">
                      <strong>Data da realiza&ccedil;&atilde;o</strong>
                    </td>
                    <td style="width: 435px;padding-left: 10px;">
                      {{DadosRegistro.dataRealizada | date:'dd/MM/yyyy'}}
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 226px;">
                      <strong>M&eacute;todo de Avalia&ccedil;&atilde;o de Efetividade</strong>
                    </td>
                    <td style="width: 435px;padding-left: 10px;">
                      {{metodoAvaliacao}}
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 226px;">
                      <strong>Prazo para Avalia&ccedil;&atilde;o da Efetividade</strong>
                    </td>
                    <td style="width: 435px; padding-left: 10px;">
                      {{tbTema.prazoAvaliacao}} &nbsp; dias.
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>&nbsp;</p>
              <table class="col-md-12">
                <tbody>
                  <tr>
                    <td style="width: 218px;">
                      <strong>Nome dos Participantes</strong>
                    </td>
                    <td style="width: 218px;">
                      <strong>Fun&ccedil;&atilde;o / Cargo</strong>
                    </td>
                    <td style="width: 226px;">
                      <strong>Assinatura</strong>
                    </td>
                  </tr>
                  <tr *ngFor="let item of vwContratadoFuncaoCargo">
                    <td style="width: 218px;">
                      {{item.nomeContratado}}
                    </td>
                    <td style="width: 218px;">
                      {{item.nomeFuncaoCargo}}
                    </td>
                    <td style="width: 226px;">
                      &nbsp;
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>&nbsp;</p>
              <table class="col-md-12">
                <tbody>
                  <tr>
                    <td colspan="2" style="width: 661px;">
                      <p><strong>Registro da Avalia&ccedil;&atilde;o da Efetividade do Treinamento &ndash; Eficaz:&nbsp;
                          SIM (&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; )&nbsp;&nbsp;&nbsp; N&Atilde;O
                          (&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; )</strong></p>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="width: 661px;">
                      <strong>Justificativa:</strong>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="width: 661px;">
                      &nbsp;
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="width: 661px;">
                      <strong>Necess&aacute;rio novo treinamento: SIM (&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          )&nbsp;&nbsp;&nbsp; N&Atilde;O (&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; )</strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 255px;">
                      <strong>Data da Avalia&ccedil;&atilde;o:</strong>
                    </td>
                    <td style="width: 406px;">
                      <strong>Respons&aacute;vel pela avalia&ccedil;&atilde;o:</strong>
                      {{DadosRegistro.nomeAvaliador}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>