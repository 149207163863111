import { splitNsName } from '@angular/compiler';
import { Component, Inject, OnInit, ViewChild, ViewContainerRef, forwardRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppComponent } from 'src/app/app.component';
import { TbAgenda } from 'src/app/entities/tbAgenda';
import { TbContratado } from 'src/app/entities/tbContratado';
import { TbInstituicao } from 'src/app/entities/tbInstituicao';
import { TbSetor } from 'src/app/entities/tbSetor';
import { TbTema } from 'src/app/entities/tbTema';
import { TbTopico } from 'src/app/entities/tbTopico';
import { Utils } from 'src/app/shared/classes/utils';
import { AgendaWebapiService } from 'src/app/shared/services/webapi/agenda-webapi.service';
import { ContratadoWebapiService } from 'src/app/shared/services/webapi/contratado-webapi.service';
import { InstituicaoWebapiService } from 'src/app/shared/services/webapi/instituicao-webapi.service';
import { SetorWebapiService } from 'src/app/shared/services/webapi/setor-webapi.service';
import { TemaWebapiService } from 'src/app/shared/services/webapi/tema-webapi.service';
import { TopicoWebapiService } from 'src/app/shared/services/webapi/topico-webapi.service';
import { VwGridAgendaWebapiService } from 'src/app/shared/services/webapi/vw-grid-agenda-webapi.service';

@Component({
  selector: 'app-agendamento',
  templateUrl: './agendamento.component.html',
  styleUrls: ['./agendamento.component.css']
})
export class AgendamentoComponent implements OnInit {

  @ViewChild('ngForm') frm: NgForm;
  public get frmCtrls(): any { return this.frm ? this.frm.controls : {}; };
  public tbSetores: TbSetor[] = new Array<TbSetor>();
  public tbAgendas: TbAgenda[] = new Array<TbAgenda>();
  public tbAgenda: TbAgenda = new TbAgenda();
  public tbAgendaRealizada: TbAgenda = new TbAgenda();
  public tbContratados: TbContratado[] = new Array<TbContratado>();
  public tbContratado: TbContratado = new TbContratado();
  public tbContratadosTreinadores: TbContratado[] = new Array<TbContratado>();
  public tbContratadosAvaliadores: TbContratado[] = new Array<TbContratado>();
  public tbTemas: TbTema[] = new Array<TbTema>();
  public tbTopicos: TbTopico[] = new Array<TbTopico>();
  public tbSetor: TbSetor = new TbSetor();
  public tbInstituicao: TbInstituicao = new TbInstituicao();
  public loading: boolean = false;
  public habilitarExcluir: boolean = false;

  TopicoTexto;
  textoFiltro = '';
  codeInstituicao = '';
  HFtkn = localStorage.getItem("HFtkn");

  constructor(@Inject(forwardRef(() => AppComponent))
  public parent: AppComponent,
    public toast: ToastrService,
    public vcr: ViewContainerRef,
    private agendaWebapiService: AgendaWebapiService,
    private vwGridAgendaWebapiService: VwGridAgendaWebapiService,
    private setorWebapiService: SetorWebapiService,
    private contratadoWebapiService: ContratadoWebapiService,
    private temaWebapiService: TemaWebapiService,
    private instituicaoWebApiService: InstituicaoWebapiService,
    private topicoWebapiService: TopicoWebapiService,
    private utils: Utils,
    private router: Router) {
    let HFtkn = localStorage.getItem("HFtkn");
    this.codeInstituicao = localStorage.getItem("chaveId");
    this.BuscarInstituicaoPorCode();

  }

  BuscarInstituicaoPorCode() {
    this.instituicaoWebApiService.pesquisarInstituicaoPorCode(this.codeInstituicao, this.HFtkn, (data: TbInstituicao) => {
      this.tbAgenda.idInstituicao = data[0];
      this.listarContratado();
      this.ListarSetores();
      this.listarContratadoTreinador();
      this.listarContratadoAvaliador();
      this.listarAgenda();
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  listarAgenda() {
    this.vwGridAgendaWebapiService.ListarAgenda(this.tbAgenda.idInstituicao, this.HFtkn, (dataAgenda: TbAgenda[]) => {
      this.tbAgendas = dataAgenda;
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  SelecionarSetorPorContratado(idContratado) {
    this.tbTopicos = undefined;    
    this.tbAgenda.idTema = undefined;
    this.setorWebapiService.ListarSetorPorContratado(this.tbAgenda.idInstituicao, idContratado, this.HFtkn, (dataSetor: TbSetor) => {
      this.tbSetor = dataSetor[0];
      this.tbAgenda.idSetor = this.tbSetor.id;
      this.listarTemas(this.tbSetor.id);
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  ListarSetores() {
    this.setorWebapiService.selecionarPorIdInstituicao(this.tbAgenda.idInstituicao, this.HFtkn, (dataSetor: TbSetor[]) => {
      this.tbSetores = dataSetor;
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  listarContratado() {
    this.contratadoWebapiService.selecionarPorIdInstituicao(this.tbAgenda.idInstituicao, this.HFtkn, (dataContratado: TbContratado[]) => {
      this.tbContratados = dataContratado;
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  listarContratadoTreinador() {
    this.contratadoWebapiService.listarContratadoTreinador(this.tbAgenda.idInstituicao, this.HFtkn, (dataContratado: TbContratado[]) => {
      this.tbContratadosTreinadores = dataContratado;
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  listarContratadoAvaliador() {
    this.contratadoWebapiService.listarContratadoAvaliador(this.tbAgenda.idInstituicao, this.HFtkn, (dataContratado: TbContratado[]) => {
      this.tbContratadosAvaliadores = dataContratado;
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  listarTemas(idSetor) {
    this.temaWebapiService.TemaPorSetor(1, idSetor, this.HFtkn, (dataTema: TbTema[]) => {
      this.tbTemas = dataTema;
      this.TopicoTexto = this.tbTemas.find(t => t.id == this.tbAgenda.idTema).topicos;
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  mostrarTopicos() {
    this.agendaWebapiService.SelecionarAgendaPorContratadoTema(this.tbAgenda.idInstituicao, this.tbAgenda.idContratado, this.tbAgenda.idTema, this.HFtkn, (data: TbAgenda) => {
      this.tbAgendaRealizada = data[0];
      if (this.tbAgendaRealizada != undefined) {
        this.toast.warning("Esse tema já foi agendado para esse colaborador.").toastRef.manualClose;
        this.tbAgenda.idTema = 0;
      }
      else{
        this.topicoWebapiService.TopicosPorTema(1, this.tbAgenda.idTema, this.HFtkn, (dataTopico: TbTopico[]) => {
          this.tbTopicos = dataTopico;          
        },
          (error) => {
            if (error.status == 401)
              this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
            else
              this.toast.error(error);
          });
      }
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });

    
  }


  mostrarTopico() {
    this.TopicoTexto = this.tbTemas.find(t => t.id == this.tbAgenda.idTema).topicos;
    this.agendaWebapiService.SelecionarAgendaPorContratadoTema(this.tbAgenda.idInstituicao, this.tbAgenda.idContratado, this.tbAgenda.idTema, this.HFtkn, (data: TbAgenda) => {
      this.tbAgendaRealizada = data[0];
      if (this.tbAgendaRealizada != undefined) {
        this.toast.warning("Esse tema já foi agendado para esse colaborador.").toastRef.manualClose;
        this.tbAgenda.idTema = 0;
      }
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  BuscarPorId(item) {
    this.agendaWebapiService.selecionarPorId(item.id, this.HFtkn, (data: TbAgenda) => {
      // this.tbSetores = undefined;
      // this.tbTemas = undefined;

      this.tbAgenda = data;
      if (this.tbAgenda.dataRealizada == null)
        this.tbAgenda.dataRealizada = undefined;

      this.tbContratado.id = this.tbAgenda.idContratado;

      // this.listarSetores(this.tbAgenda.idContratado);
      this.tbSetor.id = this.tbAgenda.idSetor;

      this.listarTemas(this.tbAgenda.idSetor);

      this.loading = true;
      this.habilitarExcluir = true;
      window.scroll(0, 0);
    },
      (error) => {
        this.toast.error(error);
      });
  }

  LimparCampo() {
    this.tbAgenda.id = undefined;
    this.tbAgenda.tipo = undefined;
    this.tbAgenda.idSetor = undefined;
    this.tbAgenda.idContratado = undefined;
    this.tbAgenda.idTema = undefined;
    this.tbAgenda.idTreinador = undefined;
    this.tbAgenda.idAvaliador = undefined;
    this.tbAgenda.dataPlanejada = undefined;
    this.tbAgenda.dataRealizada = undefined;
    this.tbAgenda.dataLimite = undefined;
    this.loading = false;
    this.habilitarExcluir = false;
    this.TopicoTexto = undefined;
    // this.tbContratados = undefined;
    this.tbTemas = undefined;
    this.tbTopicos = undefined;
  }

  Novo() {
    this.loading = true;
  }

  Salvar() {
    if (this.ValidarCampos()) {
      this.loading = true;
      this.habilitarExcluir = false;
      this.tbAgenda.excluido = false;
      this.tbAgenda.tipo = 1;
      this.tbAgenda.dataRealizada = null;

      this.agendaWebapiService.SelecionarAgendaPorContratadoTema(this.tbAgenda.idInstituicao, this.tbAgenda.idContratado, this.tbAgenda.idTema, this.HFtkn, (data: TbAgenda) => {
        this.tbAgendaRealizada = data[0];
        if (this.tbAgendaRealizada != undefined) {
          this.toast.error("Esse tema já foi agendado para esse contratado.").toastRef.manualClose;
          this.LimparCampo();
        }
        else {
          if (this.tbAgenda.id == undefined) {
            this.agendaWebapiService.incluir(this.tbAgenda, this.HFtkn, (retorno) => {
              this.LimparCampo();
              this.listarAgenda();
              this.loading = false;
              this.toast.success("Registro salvo com sucesso!", "Agendamento", { progressBar: true });
            },
              (error) => {
                this.loading = false;
                this.toast.error(error);
              });
          }
          else {
            this.agendaWebapiService.alterar(this.tbAgenda, this.HFtkn, (retorno) => {
              this.LimparCampo();
              this.listarAgenda();
              this.loading = false;
              this.toast.success("Registro salvo com sucesso!", "Agendamento", { progressBar: true });
            },
              (error) => {
                this.loading = false;
                this.toast.error(error);
              });
          }
        }
      },
        (error) => {
          if (error.status == 401)
            this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
          else
            this.toast.error(error);
        });
    }
  }

  ExcluirRegistro() {
    this.tbAgenda.excluido = true;
    this.agendaWebapiService.alterar(this.tbAgenda, this.HFtkn, (retorno) => {
      this.LimparCampo();
      this.listarAgenda();
      this.loading = false;
      this.habilitarExcluir = false;
      this.toast.success("Registro excluído com sucesso!")
    },
      (error) => {
        this.loading = false;
        this.toast.error(error);
      });
  }

  ValidarCampos() {
    if (this.tbAgenda.idContratado == undefined ||
      this.tbAgenda.idInstituicao == undefined ||
      this.tbAgenda.idSetor == undefined ||
      this.tbAgenda.idTema == undefined ||
      this.tbAgenda.idTema == 0 ||
      this.tbAgenda.idAvaliador == undefined ||
      this.tbAgenda.idTreinador == undefined ||
      this.tbAgenda.dataPlanejada == undefined ||
      this.tbAgenda.dataLimite == undefined) {
      this.toast.error("Preencha todos os campos.");
      return false;
    }
    else {
      return true;
    }
  }

  ngOnInit(): void {
  }
}
