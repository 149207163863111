import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertDataPraMes'
})
export class ConvertDataPraMesPipe implements PipeTransform {
  transform(_datahora: string, data: string): string {
    let retorno = "";

    if (_datahora < '2001-01-01T02:00:00')
      return "";

    if (_datahora.toString().substring(5, 7) == "01")
      retorno = "Jan"
    if (_datahora.toString().substring(5, 7) == "02")
      retorno = "Fev"
    if (_datahora.toString().substring(5, 7) == "03")
      retorno = "Março"
    if (_datahora.toString().substring(5, 7) == "04")
      retorno = "Abril"
    if (_datahora.toString().substring(5, 7) == "05")
      retorno = "Maio"
    if (_datahora.toString().substring(5, 7) == "06")
      retorno = "Junho"
    if (_datahora.toString().substring(5, 7) == "07")
      retorno = "Julho"
    if (_datahora.toString().substring(5, 7) == "08")
      retorno = "Agosto"
    if (_datahora.toString().substring(5, 7) == "09")
      retorno = "Setembro"
    if (_datahora.toString().substring(5, 7) == "10")
      retorno = "Outubro"
    if (_datahora.toString().substring(5, 7) == "11")
      retorno = "Nov"
    if (_datahora.toString().substring(5, 7) == "12")
      retorno = "Dez"

    // if (data == 'Farmacêutico') {
    //   if (_datahora.toString().substring(5, 7) == "01")
    //     retorno = "Jan/Fev"
    //   if (_datahora.toString().substring(5, 7) == "02")
    //     retorno = "Jan/Fev"
    //   if (_datahora.toString().substring(5, 7) == "03")
    //     retorno = "Março"
    //   if (_datahora.toString().substring(5, 7) == "04")
    //     retorno = "Abril"
    //   if (_datahora.toString().substring(5, 7) == "05")
    //     retorno = "Maio"
    //   if (_datahora.toString().substring(5, 7) == "06")
    //     retorno = "Junho"
    //   if (_datahora.toString().substring(5, 7) == "07")
    //     retorno = "Julho"
    //   if (_datahora.toString().substring(5, 7) == "08")
    //     retorno = "Agosto"
    //   if (_datahora.toString().substring(5, 7) == "09")
    //     retorno = "Setembro"
    //   if (_datahora.toString().substring(5, 7) == "10")
    //     retorno = "Outubro"
    //   if (_datahora.toString().substring(5, 7) == "11")
    //     retorno = "Nov/Dez"
    //   if (_datahora.toString().substring(5, 7) == "12")
    //     retorno = "Nov/Dez"
    // }
    // else {
    //   if (_datahora.toString().substring(5, 7) == "01")
    //     retorno = "Jan/Fev"
    //   if (_datahora.toString().substring(5, 7) == "02")
    //     retorno = "Jan/Fev"
    //   if (_datahora.toString().substring(5, 7) == "03")
    //     retorno = "Mar/Abr"
    //   if (_datahora.toString().substring(5, 7) == "04")
    //     retorno = "Mar/Abr"
    //   if (_datahora.toString().substring(5, 7) == "05")
    //     retorno = "Mai/Jun"
    //   if (_datahora.toString().substring(5, 7) == "06")
    //     retorno = "Mai/Jun"
    //   if (_datahora.toString().substring(5, 7) == "07")
    //     retorno = "Jul/Ago"
    //   if (_datahora.toString().substring(5, 7) == "08")
    //     retorno = "Jul/Ago"
    //   if (_datahora.toString().substring(5, 7) == "09")
    //     retorno = "Set/Out"
    //   if (_datahora.toString().substring(5, 7) == "10")
    //     retorno = "Set/Out"
    //   if (_datahora.toString().substring(5, 7) == "11")
    //     retorno = "Nov/Dez"
    //   if (_datahora.toString().substring(5, 7) == "12")
    //     retorno = "Nov/Dez"
    // };

    return retorno;
  }
}
