import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Spinner } from './components/spinner/spinner.component';
import { OrderByPipe } from './pipes/orderby.pipe';
import { CtrlMessages } from './components/ctrl-messages.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        Spinner,
        CtrlMessages,
        OrderByPipe
    ],
    providers: [
        OrderByPipe
    ],
    exports:[
        Spinner,
        CtrlMessages,
        OrderByPipe
    ],
    entryComponents: [
    ]
})
export class SharedModule { }
