export class TbInstituicao {
    id: number;
    codigo: string;
    idInstituicao: number;
    nomeFantasia: string;
    razaoSocial: string;
    cnpj: string;
    endereco: string;
    telefone: string;
    idResponsavelLegal: number;
    idResponsavelTecnico: number;
    idResponsavelGarantiaQualidade: number;
    dataCadastro: Date;
    ativo: boolean;
    excluido: boolean;

    constructor(init?: Partial<TbInstituicao>) {
        if (init != null) Object.assign(this, init);
        else {
            this.id = undefined;
            this.codigo = undefined;
            this.idInstituicao = undefined;
            this.nomeFantasia = undefined;
            this.razaoSocial = undefined;
            this.cnpj = undefined;
            this.endereco = undefined;
            this.telefone = undefined;
            this.idResponsavelLegal = undefined;
            this.idResponsavelTecnico = undefined;
            this.idResponsavelGarantiaQualidade = undefined;
            this.dataCadastro = undefined;            
            this.ativo = undefined;
            this.dataCadastro = undefined;
            this.excluido = undefined;
        }
    }
}