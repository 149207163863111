<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <!-- Default box -->
                <!-- TO DO List -->
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">
                            <i class="ion ion-clipboard mr-1"></i>
                            Sobre
                        </h3>
                    </div>
                    <form>
                        <div class="card-body row">
                            <div class="col-5 text-center d-flex align-items-center justify-content-center">
                                <div class="">
                                    <h2>Habilita Farma</h2>
                                    <p class="lead mb-5">São José dos Campos - SP<br>
                                        <i class="fas fa-phone"></i> +55 12 99716-6336
                                    </p>
                                </div>
                            </div>
                            <div class="col-7">
                                <div class="form-group">
                                    <label for="inputName">Nome</label>
                                    <input type="text" id="inputName" class="form-control" />
                                </div>
                                <div class="form-group">
                                    <label for="inputEmail">E-Mail</label>
                                    <input type="email" id="inputEmail" class="form-control" />
                                </div>
                                <div class="form-group">
                                    <label for="inputSubject">Assunto</label>
                                    <input type="text" id="inputSubject" class="form-control" />
                                </div>
                                <div class="form-group">
                                    <label for="inputMessage">Mensagem</label>
                                    <textarea id="inputMessage" class="form-control" rows="4"></textarea>
                                </div>
                                <div class="form-group">
                                    <input type="submit" class="btn btn-primary" value="Enviar Mensagem">
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>