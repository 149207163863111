export class TbContratadoSetor{
    id: number;
    idContratado: number;
    Idinstituicao: number;
    idFuncaoCargo: number;
    idSetor: number;
    vinculado: boolean;
    dataIntegracao: Date;
    dataFinal: Date;
    excluido: boolean;

    constructor(init? : Partial<TbContratadoSetor>) {
        if(init != null) Object.assign(this, init);
        else{
            this.id = undefined;
            this.idContratado = undefined;
            this.Idinstituicao = undefined;
            this.idFuncaoCargo = undefined;
            this.idSetor = undefined;
            this.vinculado = undefined;
            this.dataIntegracao = undefined;
            this.dataFinal = undefined;
            this.excluido = undefined;
        }
    }
}
