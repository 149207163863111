import { Component, Inject, OnInit, ViewChild, forwardRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AppComponent } from 'src/app/app.component';
import { TbContratado } from 'src/app/entities/tbContratado';
import { TbInstituicao } from 'src/app/entities/tbInstituicao';
import { TbSetor } from 'src/app/entities/tbSetor';
import { TbTema } from 'src/app/entities/tbTema';
import { TbTopico } from 'src/app/entities/tbTopico';
import { vwAcompanhamentoContinuado } from 'src/app/entities/vwAcompanhamentoContinuado';
import { vwAcompanhamentoInicial } from 'src/app/entities/vwAcompanhamentoInicial';
import { vwDetalheAcompanhamentoContinuado } from 'src/app/entities/vwDetalheAcompanhamentoContinuado';
import { vwDetalheAcompanhamentoInicial } from 'src/app/entities/vwDetalheAcompanhamentoInicial';
import { InstituicaoWebapiService } from 'src/app/shared/services/webapi/instituicao-webapi.service';
import { VwAcompanhamentoContinuadoWebapiService } from 'src/app/shared/services/webapi/vw-acompanhamento-continuado-webapi.service';
import { VwAcompanhamentoInicialWebapiService } from 'src/app/shared/services/webapi/vw-acompanhamento-inicial-webapi.service';
import { VwDetalheAcompanhamentoContinuadoWebapiService } from 'src/app/shared/services/webapi/vw-detalhe-acompanhamento-continuado-webapi.service';
import { VwDetalheAcompanhamentoInicialWebapiService } from 'src/app/shared/services/webapi/vw-detalhe-acompanhamento-inicial-webapi.service';

@Component({
  selector: 'app-acompanhamento',
  templateUrl: './acompanhamento.component.html',
  styleUrls: ['./acompanhamento.component.css']
})
export class AcompanhamentoComponent implements OnInit {

  @ViewChild('ngForm') frm: NgForm;
  public get frmCtrls(): any { return this.frm ? this.frm.controls : {}; };

  public tbSetores: TbSetor[] = new Array<TbSetor>();
  public tbSetor: TbSetor = new TbSetor();
  public tbTemas: TbTema[] = new Array<TbTema>();
  public tbTema: TbTema = new TbTema();
  public tbTopicos: TbTopico[] = new Array<TbTopico>();
  public tbTopico: TbTopico = new TbTopico();
  public vwAcompanhamentoInicial: vwAcompanhamentoInicial[] = new Array<vwAcompanhamentoInicial>();
  public vwAcompanhamentoContinuado: vwAcompanhamentoContinuado[] = new Array<vwAcompanhamentoContinuado>();
  public vwDetalheAcompanhamentoInicial: vwDetalheAcompanhamentoInicial[] = new Array<vwDetalheAcompanhamentoInicial>();
  public vwDetalheAcompanhamentoContinuado: vwDetalheAcompanhamentoContinuado[] = new Array<vwDetalheAcompanhamentoContinuado>();
  
  TopicoTexto: number = 0;
  desabilitarConcluido: boolean = true;
  desabilitarBotaoRegistro: boolean = true;
  public idInstituicao;
  codeInstituicao = '';
  textoFiltro = '';
  HFtkn = localStorage.getItem("HFtkn");

  constructor(@Inject(forwardRef(() => AppComponent))
  public parent: AppComponent,
    public toast: ToastrService,
    private instituicaoWebApiService: InstituicaoWebapiService,
    private vwAcompanhamentoInicialWebapiService: VwAcompanhamentoInicialWebapiService,
    private vwAcompanhamentoContinuadoWebapiService: VwAcompanhamentoContinuadoWebapiService,
    private vwDetalheAcompanhamentoInicialWebapiService: VwDetalheAcompanhamentoInicialWebapiService,
    private vwDetalheAcompanhamentoContinuadoWebapiService: VwDetalheAcompanhamentoContinuadoWebapiService) {
    this.codeInstituicao = localStorage.getItem("chaveId");
    this.BuscarInstituicaoPorCode(this.codeInstituicao);
  }

  countCheckedTopicos(checked) {
    console.log(checked);
    if (checked.currentTarget.checked == true)
      this.TopicoTexto--;
    else
      this.TopicoTexto++;

    console.log(this.TopicoTexto);

    if (this.TopicoTexto == 0) {
      this.desabilitarConcluido = false;
    }
    else {
      this.desabilitarConcluido = true;
      this.desabilitarBotaoRegistro = true;
    }
  }

  habilitarRegistro() {
    this.desabilitarBotaoRegistro = false;
  }

  BuscarInstituicaoPorCode(code) {
    this.instituicaoWebApiService.pesquisarInstituicaoPorCode(this.codeInstituicao, this.HFtkn, (data: TbInstituicao) => {
      this.idInstituicao = data[0];
      this.listarAcompanhamentoInicial(this.idInstituicao);     
      this.listarAcompanhamentoContinuado(this.idInstituicao);
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }
  
  listarAcompanhamentoInicial(idInstituicao) {
    this.vwAcompanhamentoInicialWebapiService.selecionarPorIdInstituicao(this.idInstituicao, this.HFtkn, (data: vwAcompanhamentoInicial[]) => {
      this.vwAcompanhamentoInicial = data;
    },
      (error) => {
        if(error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }
  
  
  listarAcompanhamentoContinuado(idInstituicao) {
    this.vwAcompanhamentoContinuadoWebapiService.selecionarPorIdInstituicao(this.idInstituicao, this.HFtkn, (data: vwAcompanhamentoInicial[]) => {
      this.vwAcompanhamentoContinuado = data;
    },
      (error) => {
        if(error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }
  
  listarDetalheAcompanhamentoInicial(idContratado) {
    this.vwDetalheAcompanhamentoInicialWebapiService.TemasPorContratado(this.idInstituicao, idContratado, this.HFtkn, (data: vwDetalheAcompanhamentoInicial[]) => {
      this.vwDetalheAcompanhamentoInicial = data;
    },
      (error) => {
        if(error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }
  
  
  listarDetalheAcompanhamentoContinuado(idContratado) {
    this.vwDetalheAcompanhamentoContinuadoWebapiService.TemasPorContratado(this.idInstituicao, idContratado, this.HFtkn, (data: vwDetalheAcompanhamentoContinuado[]) => {
      this.vwDetalheAcompanhamentoContinuado = data;
    },
      (error) => {
        if(error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }


  ngOnInit(): void {   

  }

}
