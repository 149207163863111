export class VwContratadoFuncaoCargo{
    id: number;
    instituicaoId: number;
    idContratado: number;
    nomeContratado: string;
    idFuncaoCargo: number;
    nomeFuncaoCargo: string;

    constructor(init? : Partial<VwContratadoFuncaoCargo>) {
        if(init != null) Object.assign(this, init);
        else{
            this.id = undefined;
            this.instituicaoId = undefined; 
            this.idContratado = undefined;   
            this.nomeContratado = undefined;
            this.idFuncaoCargo = undefined;
            this.nomeFuncaoCargo = undefined;
        }
    }
}
