import { Injectable } from '@angular/core';
import { BaseWebApi } from './base-webapi.service';
import { Http } from '../http.service';
import { SessionService } from '../session.service';

@Injectable({
  providedIn: 'root'
})
export class VwGridAgendaWebapiService extends BaseWebApi {
  constructor(httpService: Http,
    sessionService: SessionService) {
    super(httpService, sessionService);
    this.prefixo = 'VwGridAgenda';
  }

  ListarAgenda(idInstituicao: number, HFtkn: string, success?, error?) {
    return this.httpClient.get(this.prefixo + '/porInstituicao/' + idInstituicao, HFtkn, success, error);
  }
}