<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <!-- Default box -->
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Setores</h3>
                    </div>
                    <form #f="ngForm">
                        <div class="row" style="padding: 10px; text-align: right;">
                            <div class="col-sm-8" style="text-align: left;">
                                <button type="button" (click)="Novo()" data-toggle="modal" data-target="#novo"
                                    class="btn btn-secondary btn-sm" style="width: 150px;">
                                    <span><i class="fas fa-save"></i> Novo</span>
                                </button>
                            </div>
                            <div class="col-sm-4">
                                <input class="form-control mr-sm-2" type="search"
                                    placeholder="Pesquisar por Nome." name="textoFiltro" id="textoFiltro"
                                    [(ngModel)]="textoFiltro">

                            </div>
                        </div>
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <!-- <th scope="col">Código</th> -->
                                    <th scope="col">Nome</th>
                                    <!-- <th scope="col">Grupo</th> -->
                                    <th scope="col" class="text-center">Ação</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <tr *ngFor="let item of tbSetores | filtrosGrid:textoFiltro">
                                    <!-- <td width="auto" [innerHtml]="item.id"></td> -->
                                    <td width="auto" [innerHtml]="item.nome"></td>
                                    <!-- <td width="auto" [innerHtml]="item.grupo"></td> -->
                                    <td class="text-center">
                                        <button type="button" class="btn btn-outline-default btn-sm"
                                            (click)="BuscarPorId(item)" data-toggle="modal" data-target="#novo">
                                            <i class="nav-icon fas fa-edit"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Confirma a exclusão do registro?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Confirma a exclusão do registro?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">
                    Cancelar</button>
                <button type="button" class="btn btn-warning btn-sm" (click)="ExcluirRegistro()"data-dismiss="modal">
                    Confirmar</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal novo setor-->
<div class="modal fade" id="novo" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"
    data-backdrop="static">

    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Novo Cadastro</h5>
            </div>
            <div class="modal-body">
                <div class="row gx-3 gy-2 align-items-center">

                    <div class="form-group col-md-6">
                        <label for="nome"><b>Nome</b></label>
                        <input type="text" class="form-control" id="tbSetor.nome" [(ngModel)]="tbSetor.nome"
                            name="tbSetor.nome" placeholder="Nome" [disabled]="!this.loading">
                    </div>
                    
                    <div class="form-group col-md-6">
                        <label for="inputSetor">Grupo</label>
                        <select id="tbSetor.idGrupo" [(ngModel)]="tbSetor.idGrupo"
                            [disabled]="!this.loading" name="tbSetor.idGrupo" class="form-control">
                            <option [value]="0" selected>Escolha...</option>
                            <option [value]="item.id" *ngFor="let item of tbGrupos">{{item.nome}}</option>
                        </select>
                    </div>
                    <!-- <div class="form-group col-md-12">
                        <label for="inputDescricao"><b>Descrição</b></label>
                        <input type="text" class="form-control" id="tbSetor.descricao" [(ngModel)]="tbSetor.descricao"
                            name="tbSetor.descricao" placeholder="Nome do Setor" [disabled]="!this.loading">
                    </div> -->
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary btn-sm" (click)="LimparCampo()" data-dismiss="modal">
                    Cancelar</button>
                <button type="button" class="btn btn-success btn-sm" (click)="Salvar()" data-dismiss="modal">
                    Confirmar</button>
                <div [hidden]="!habilitarExcluir">
                    <button type="button" data-toggle="modal" data-target="#exampleModal"  data-dismiss="modal"
                        class="btn btn-danger btn-sm">
                    Excluir</button>
                </div>
            </div>
        </div>
    </div>
</div>