export class TbContratado{
    id: number;
    idInstituicao: number;
    nome: string;
    telefone?: string;
    email: null | string;
    documento: null | string;
    dataNascimento: Date;
    escolaridade: string;
    idFuncaoCargo: string;
    avaliador: boolean;
    treinador: boolean;
    responsavel: boolean;
    usuario: boolean;
    aprovador: boolean;
    dataCadastro: Date;
    excluido: boolean;

    constructor(init? : Partial<TbContratado>) {
        if(init != null) Object.assign(this, init);
        else{
            this.id = undefined;
            this.idInstituicao = undefined;
            this.nome = undefined;
            this.telefone = undefined;
            this.email = undefined;
            this.documento = undefined;
            this.dataNascimento = undefined;
            this.escolaridade = undefined;
            this.idFuncaoCargo = undefined;
            this.avaliador = undefined;
            this.treinador = undefined;
            this.responsavel = undefined;
            this.dataCadastro = undefined;
            this.usuario = undefined;            
            this.excluido = undefined;
        }
    }
}
