import { Component, Inject, OnInit, ViewChild, ViewContainerRef, forwardRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppComponent } from 'src/app/app.component';
import { TbContratado } from 'src/app/entities/tbContratado';
import { TbContratadoSetor } from 'src/app/entities/tbContratadoSetor';
import { Utils } from 'src/app/shared/classes/utils';
import { ContratadoSetorWebapiService } from 'src/app/shared/services/webapi/contratado-setor-webapi.service';
import { ContratadoWebapiService } from 'src/app/shared/services/webapi/contratado-webapi.service';
import { EnviarEmailWebapiService } from 'src/app/shared/services/webapi/enviar-email-webapi.service';
import { InstituicaoWebapiService } from 'src/app/shared/services/webapi/instituicao-webapi.service';
import { SetorWebapiService } from 'src/app/shared/services/webapi/setor-webapi.service';
import { UsuarioWebApi } from 'src/app/shared/services/webapi/usuario-webapi.service';

@Component({
  selector: 'app-enviaremailagenda',
  templateUrl: './enviaremailagenda.component.html',
  styleUrls: ['./enviaremailagenda.component.css']
})
export class EnviaremailagendaComponent implements OnInit {

  @ViewChild('ngForm') frm: NgForm;
  public get frmCtrls(): any { return this.frm ? this.frm.controls : {}; };
  public HFtkn;
  codeInstituicao = '';

  constructor(@Inject(forwardRef(() => AppComponent))
  public parent: AppComponent,
    public toast: ToastrService,
    public vcr: ViewContainerRef,
    private usuarioWebApi: UsuarioWebApi,
    public enviaremailagenda: EnviarEmailWebapiService) {
    this.enviar();
    this.EnviarEmailCronJob();
  }

  enviar() {
    try {
      this.usuarioWebApi.gettkn("admin", "admin2207@", "2207-1", (data) => {
        if (data == null) {
          return;
        }
        this.HFtkn = data;
        localStorage.setItem("HFtkn", this.HFtkn.token);

        let HFtkn = localStorage.getItem("HFtkn");
        this.enviaremailagenda.enviaremailagenda(HFtkn,
          (data) => {

          },
          (error, status) => {
          });
      },
        (error) => {
          this.toast.error("Falha no Sistema. Entre em contato com o Suporte.");
        });

    } catch (error) {
    }

  }


  EnviarEmailCronJob() {
    try {
      this.usuarioWebApi.gettkn("admin", "admin2207@", "2207-1", (data) => {
        if (data == null) {
          return;
        }
        this.HFtkn = data;
        localStorage.setItem("HFtkn", this.HFtkn.token);

        let HFtkn = localStorage.getItem("HFtkn");
        this.enviaremailagenda.EnviarEmailCronJob(HFtkn,
          (data) => {

          },
          (error, status) => {
          });
      },
        (error) => {
          this.toast.error("Falha no Sistema. Entre em contato com o Suporte.");
        });

    } catch (error) {
    }

  }



  ngOnInit(): void {
  }

}
