import { Component, Inject, OnInit, ViewChild, ViewContainerRef, forwardRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppComponent } from 'src/app/app.component';
import { TbContratado } from 'src/app/entities/tbContratado';
import { TbContratadoSetor } from 'src/app/entities/tbContratadoSetor';
import { Utils } from 'src/app/shared/classes/utils';
import { ContratadoSetorWebapiService } from 'src/app/shared/services/webapi/contratado-setor-webapi.service';
import { ContratadoWebapiService } from 'src/app/shared/services/webapi/contratado-webapi.service';
import { InstituicaoWebapiService } from 'src/app/shared/services/webapi/instituicao-webapi.service';
import { SetorWebapiService } from 'src/app/shared/services/webapi/setor-webapi.service';

@Component({
  selector: 'app-contratado-setor',
  templateUrl: './contratado-setor.component.html',
  styleUrls: ['./contratado-setor.component.css']
})
export class ContratadoSetorComponent implements OnInit {

  @ViewChild('ngForm') frm: NgForm;
  public get frmCtrls(): any { return this.frm ? this.frm.controls : {}; };

  public tbContratados: TbContratado[] = new Array<TbContratado>();
  public tbContratado: TbContratado = new TbContratado();
  public tbContratadosSetores: TbContratadoSetor[] = new Array<TbContratadoSetor>();
  public tbContratadoSetor: TbContratadoSetor = new TbContratadoSetor();
  public loading: boolean = false;

  codeInstituicao = '';

  constructor(@Inject(forwardRef(() => AppComponent))
  public parent: AppComponent,
    public toast: ToastrService,
    public vcr: ViewContainerRef,
    private contratadoWebApi: ContratadoWebapiService,
    private contratadoSetorWebApi: ContratadoSetorWebapiService,
    private setorWebapiService: SetorWebapiService,
    private instituicaoWebApiService: InstituicaoWebapiService,
    private utils: Utils,
    private router: Router) 
  {
    this.codeInstituicao = localStorage.getItem("chaveId");
    // this.BuscarInstituicaoPorCode(this.codeInstituicao);
  }
  
  // BuscarInstituicaoPorCode(code) {
  //   this.instituicaoWebApiService.pesquisarInstituicaoPorCode(this.codeInstituicao, (data: TbInstituicao) => {
  //     this.tbContratado.idInstituicao = data[0];
  //     // this.listarContratadoSetor()
  //   },
  //     (error) => {
  //       this.toast.error(error);
  //     });
  // }
  
  // listarContratadoSetor() {
  //   this.vwcontratadoSetorWebapiService.ListarContratadoSetor(this.tbContratado.idInstituicao, (data: VwContratadoSetor[]) => {
  //     this.vwContratadosSetor = data;
  //   },
  //     (error) => {
  //       this.toast.error(error);
  //     });
  // }


  ngOnInit(): void {
  }

}
