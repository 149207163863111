import { Component, forwardRef, Inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AppComponent } from 'src/app/app.component';
import { Utils } from 'src/app/shared/classes/utils';
import { TbContratado } from 'src/app/entities/tbContratado';
import { VwContratadoSetor } from 'src/app/entities/vwContratadoSetor';
import { ContratadoWebapiService } from 'src/app/shared/services/webapi/contratado-webapi.service';
import { VwcontratadoSetorWebapiService } from 'src/app/shared/services/webapi/vwcontratado-setor-webapi.service';
import { SetorWebapiService } from 'src/app/shared/services/webapi/setor-webapi.service';
import { TbSetor } from 'src/app/entities/tbSetor';
import { ContratadoSetorWebapiService } from 'src/app/shared/services/webapi/contratado-setor-webapi.service';
import { TbContratadoSetor } from 'src/app/entities/tbContratadoSetor';
import { VwSetorPorContratado } from 'src/app/entities/vwSetorPorContratado';
import { InstituicaoWebapiService } from 'src/app/shared/services/webapi/instituicao-webapi.service';
import { TbInstituicao } from 'src/app/entities/tbInstituicao';
import { FuncaoCargoWebapiService } from 'src/app/shared/services/webapi/funcao-cargo-webapi.service';
import { TbFuncaoCargo } from 'src/app/entities/tbFuncaoCargo';
import { tbAcompanhamento } from 'src/app/entities/tbAcompanhamento';
import { AcompanhamentoInicialWebapiService } from 'src/app/shared/services/webapi/acompanhamento-inicial-webapi.service';
import { TemaWebapiService } from 'src/app/shared/services/webapi/tema-webapi.service';
import { TbTema } from 'src/app/entities/tbTema';
import { UsuarioWebApi } from 'src/app/shared/services/webapi/usuario-webapi.service';
import { TbUsuario } from 'src/app/entities/tbUsuario';
import { AgendaWebapiService } from 'src/app/shared/services/webapi/agenda-webapi.service';
import { TbAgenda } from 'src/app/entities/tbAgenda';
import { tbAcompanhamentoContinuado } from 'src/app/entities/tbAcompanhamentoContinuado';
import { AcompanhamentoContinuadoWebapiService } from 'src/app/shared/services/webapi/acompanhamento-continuado-webapi.service';

@Component({
  selector: 'app-contratado',
  templateUrl: './contratado.component.html',
  styleUrls: ['./contratado.component.css']
})
export class ContratadoComponent implements OnInit {

  @ViewChild('ngForm') frm: NgForm;
  public get frmCtrls(): any { return this.frm ? this.frm.controls : {}; };

  public tbContratados: TbContratado[] = new Array<TbContratado>();
  public tbContratado: TbContratado = new TbContratado();
  public vwContratadosSetor: VwContratadoSetor[] = new Array<VwContratadoSetor>();
  public vwSetorPorContratado: VwSetorPorContratado[] = new Array<VwSetorPorContratado>();
  public tbContratadosSetores: TbContratadoSetor[] = new Array<TbContratadoSetor>();
  public tbContratadoSetor: TbContratadoSetor = new TbContratadoSetor();
  public tbSetores: TbSetor[] = new Array<TbSetor>();
  public tbFuncoesCargos: TbFuncaoCargo[] = new Array<TbFuncaoCargo>();
  public tbSetor: TbSetor = new TbSetor();
  public tbAcompanhamentoInicial: tbAcompanhamento = new tbAcompanhamento();
  public tbAcompanhamentoContinuado: tbAcompanhamentoContinuado = new tbAcompanhamentoContinuado();
  public tbtema: TbTema[] = new Array<TbTema>();
  public tbUsuario: TbUsuario = new TbUsuario();
  public tbAgenda: TbAgenda[] = new Array<TbAgenda>();

  public loading: boolean = false;
  public idInstituicao;
  public camposLiberados: boolean = false;
  public habilitarExcluir: boolean = false;

  textoFiltro = '';
  datanasc = '';
  codeInstituicao = '';
  editar = false;
  HFtkn = localStorage.getItem("HFtkn");


  constructor(@Inject(forwardRef(() => AppComponent))
  public parent: AppComponent,
    public toast: ToastrService,
    public vcr: ViewContainerRef,
    private contratadoWebApi: ContratadoWebapiService,
    private UsuarioWebapiService: UsuarioWebApi,
    private contratadoSetorWebApi: ContratadoSetorWebapiService,
    private vwcontratadoSetorWebapiService: VwcontratadoSetorWebapiService,
    private setorWebapiService: SetorWebapiService,
    private funcaoCargoWebapiService: FuncaoCargoWebapiService,
    private instituicaoWebApiService: InstituicaoWebapiService,
    private acompanhamentoInicialService: AcompanhamentoInicialWebapiService,
    private acompanhamentoContinuadoService: AcompanhamentoContinuadoWebapiService,
    private temaService: TemaWebapiService,
    private agendaService: AgendaWebapiService,
    private utils: Utils,
    private router: Router) {
    this.codeInstituicao = localStorage.getItem("chaveId");
    this.BuscarInstituicaoPorCode(this.codeInstituicao);
  }

  BuscarInstituicaoPorCode(code) {
    this.instituicaoWebApiService.pesquisarInstituicaoPorCode(this.codeInstituicao, this.HFtkn, (data: TbInstituicao) => {
      this.idInstituicao = data[0];
      this.listarContratadoSetor();
      this.listarSetores();
      this.listarFuncaoCargo();
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  LimparCampo() {
    this.tbContratado.nome = undefined;
    this.tbContratado.usuario = false;
    this.tbContratado.email = undefined;
    this.tbContratado.telefone = undefined;
    this.tbContratado.documento = undefined;
    this.tbContratado.escolaridade = undefined;
    this.tbContratado.idFuncaoCargo = undefined;
    this.tbContratado.dataNascimento = undefined;
    this.tbContratado.excluido = undefined;
    this.datanasc = undefined;
    this.tbContratado.treinador = undefined;
    this.tbContratado.avaliador = undefined;
    this.tbContratadoSetor.idSetor = 0;
    this.tbContratado.id = undefined;

    this.tbContratadoSetor.id = undefined;
    this.tbContratadoSetor.Idinstituicao = undefined;
    this.tbContratadoSetor.dataFinal = undefined;
    this.tbContratadoSetor.dataIntegracao = undefined;
    this.tbContratadoSetor.excluido = undefined;
    this.tbContratadoSetor.idContratado = undefined;
    this.tbContratadoSetor.idFuncaoCargo = undefined;
    this.tbContratadoSetor.idSetor = undefined;
    this.tbContratadoSetor.vinculado = undefined;

    this.tbUsuario.ativo = undefined;
    this.tbUsuario.dataCadastro = undefined;
    this.tbUsuario.excluido = undefined;
    this.tbUsuario.id = undefined;
    this.tbUsuario.idContratado = undefined;
    this.tbUsuario.idInstituicao = undefined;
    this.tbUsuario.senha = undefined;
    this.tbUsuario.usuario = undefined;

    this.habilitarExcluir = false;
    this.editar = false;
    this.loading = false;
  }

  BuscarPorId(item) {
    this.contratadoWebApi.selecionarPorId(item.id, this.HFtkn, (data: TbContratado) => {
      this.tbContratado = data;

      this.contratadoSetorWebApi.SetorPorIdContratado(this.tbContratado.id, this.HFtkn, (dataSetor: TbContratadoSetor) => {
        this.tbContratadoSetor = dataSetor[0];
        this.habilitarExcluir = true;
      },
        (error) => {
          this.toast.error(error);
        });

      this.loading = true;
      this.editar = true;
      this.datanasc = data.dataNascimento.toString().substring(0, 4) + '-' + data.dataNascimento.toString().substring(5, 7) + '-' + data.dataNascimento.toString().substring(8, 10);

      window.scroll(0, 0);
    },
      (error) => {
        this.toast.error(error);
      });
  }

  listarContratadoSetor() {
    this.vwcontratadoSetorWebapiService.ListarContratadoSetor(this.idInstituicao, this.HFtkn, (data: VwContratadoSetor[]) => {
      this.vwContratadosSetor = data;
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  listarSetores() {
    this.setorWebapiService.selecionarPorIdInstituicao(this.idInstituicao, this.HFtkn, (dataSetor: TbSetor[]) => {
      this.tbSetores = dataSetor;
      this.tbContratadoSetor.idSetor = 0;
    },
      (error) => {
        this.toast.error(error);
      });
  }

  // salvarAcompanhamentoInicial(idSetor) {
  //   this.temaService.TemaPorSetor(this.idInstituicao, idSetor, this.HFtkn, (dataTema: TbTema[]) => {
  //     this.tbtema = dataTema;

  //     this.tbtema.forEach(element => {
  //       if (element.tipo == 1) {
  //         this.tbAcompanhamentoInicial.idTema = element.id;
  //         this.acompanhamentoInicialService.incluir(this.tbAcompanhamentoInicial, this.HFtkn, (retorno) => {
  //         },
  //           (error) => {
  //             this.toast.error(error);
  //           });
  //       }
  //     });
  //   },
  //     (error) => {
  //       this.toast.error(error);
  //     });
  // }

  // salvarAcompanhamentoContinuado(idSetor) {

  //   this.setorWebapiService.selecionarPorId(idSetor, this.HFtkn, (data: TbSetor) => {
  //     this.tbSetor = data;
  //     this.temaService.TemaPorGrupo(this.idInstituicao, this.tbSetor.idGrupo, this.HFtkn, (dataTema: TbTema[]) => {
  //       this.tbtema = dataTema;

  //       this.tbtema.forEach(element => {
  //         if (element.tipo == 2) {
  //           this.tbAcompanhamentoContinuado.idTema = element.id;
  //           this.acompanhamentoInicialService.incluir(this.tbAcompanhamentoInicial, this.HFtkn, (retorno) => {
  //           },
  //             (error) => {
  //               this.toast.error(error);
  //             });
  //         }
  //       });
  //     },
  //       (error) => {
  //         this.toast.error(error);
  //       });

  //   },
  //     (error) => {
  //       this.toast.error(error);
  //     });
  // }


  listarFuncaoCargo() {
    this.funcaoCargoWebapiService.selecionarPorIdInstituicao(this.idInstituicao, this.HFtkn, (dataFuncaoCargo: TbFuncaoCargo[]) => {
      this.tbFuncoesCargos = dataFuncaoCargo;
      this.tbContratadoSetor.idFuncaoCargo = 0;
    },
      (error) => {
        this.toast.error(error);
      });
  }

  Salvar() {
    //verifica se esta adicionando ou editando
    if (this.tbContratado.id == undefined) {
      this.editar = false;

      if (!this.ValidarCampos())
        return;

      this.contratadoWebApi.incluir(this.tbContratado, this.HFtkn, (retorno) => {

        // salvar na tabela de acompanhamento inicial
        // this.tbAcompanhamentoInicial.excluido = false;
        // this.tbAcompanhamentoInicial.idContratado = retorno;
        // this.tbAcompanhamentoInicial.idInstituicao = this.idInstituicao;
        // this.tbAcompanhamentoInicial.concluido = false;
        // this.tbAcompanhamentoInicial.tipo = 1;
        // this.salvarAcompanhamentoInicial(this.tbContratadoSetor.idSetor); //aqui é listado todos os temas por setor para adicionar

        // this.tbAcompanhamentoInicial.tipo = 2;

        // this.salvarAcompanhamentoContinuado(this.tbContratadoSetor.idSetor); //aqui é listado todos os temas por setor para adicionar

        // salva na tabela de relacionamento
        this.SalvarContratadoSetor(retorno);

        //salvar como usuario do sistema caso marque a opção na tela
        if (this.tbContratado.usuario)
          this.SalvarUsuario(retorno);

        this.LimparCampo();
        this.listarContratadoSetor();
        this.loading = false;
      },
        (error) => {
          this.loading = false;
          this.toast.error(error);
        });
    }
    else {
      this.editar = true;

      if (!this.ValidarCampos()) {
        return;
      }

      this.contratadoWebApi.alterar(this.tbContratado, this.HFtkn, (retorno) => {
        this.SalvarContratadoSetor(this.tbContratado.id);

        //salvar como usuario do sistema caso marque a opção na tela
        if (this.tbContratado.usuario)
          this.SalvarUsuario(this.tbContratado.id);
        else
          this.ExcluirUsuario();

        this.LimparCampo();
        this.listarContratadoSetor();
        this.loading = false;
      },
        (error) => {
          this.loading = false;
          this.toast.error(error);
        });
    }
  }

  SalvarUsuario(retorno) {
    this.tbUsuario.ativo = true;
    this.tbUsuario.dataCadastro = new Date(Date.now());
    this.tbUsuario.excluido = false;
    this.tbUsuario.idContratado = retorno;
    this.tbUsuario.idInstituicao = this.idInstituicao;
    this.tbUsuario.senha = '123456';
    this.tbUsuario.usuario = this.tbContratado.email;

    this.UsuarioWebapiService.pesquisarUsuarioPorIdContratado(this.tbContratado.id, this.idInstituicao, this.HFtkn, (data: TbUsuario) => {
      this.tbUsuario = data[0];
      if (this.tbUsuario == undefined) {
        this.UsuarioWebapiService.incluir(this.tbUsuario, this.HFtkn, (retorno) => {
        },
          (error) => {
            this.loading = false;
            this.toast.error(error);
          });
      }
    },
      (error) => {
        this.toast.error(error);
      });    
  }

  ExcluirUsuario() {
    this.UsuarioWebapiService.pesquisarUsuarioPorIdContratado(this.tbContratado.id, this.idInstituicao, this.HFtkn, (data: TbUsuario) => {
      this.tbUsuario = data[0];
      if (this.tbUsuario != undefined) {
        this.UsuarioWebapiService.excluir(this.tbUsuario.id, this.HFtkn, (retorno) => {
        },
          (error) => {
            this.loading = false;
            this.toast.error(error);
          });
      }
    },
      (error) => {
        this.toast.error(error);
      });



  }

  SalvarContratadoSetor(idcontratado) {
    let dataAtual = new Date();
    this.tbContratadoSetor.Idinstituicao = this.idInstituicao;
    this.tbContratadoSetor.idContratado = idcontratado;
    this.tbContratadoSetor.dataIntegracao = dataAtual;
    this.tbContratadoSetor.dataFinal = dataAtual;
    this.tbContratadoSetor.excluido = false;
    this.tbContratadoSetor.idSetor = this.tbContratadoSetor.idSetor;
    this.tbContratadoSetor.vinculado = true;

    if (this.editar == false) {
      this.contratadoSetorWebApi.incluir(this.tbContratadoSetor, this.HFtkn, (retorno) => {
        this.LimparCampo();
        this.loading = false;
        this.habilitarExcluir = false;
        this.toast.success("Registro salvo com sucesso!")
        this.listarContratadoSetor();
      },
        (error) => {
          this.toast.error(error);
        });
    }
    else {
      this.contratadoSetorWebApi.alterar(this.tbContratadoSetor, this.HFtkn, (retorno) => {
        this.LimparCampo();
        this.loading = false;
        this.habilitarExcluir = false;
        this.toast.success("Registro salvo com sucesso!")
        this.listarContratadoSetor();
      },
        (error) => {
          this.toast.error(error);
        });
    }
  }

  ExcluirRegistro() {
    this.tbContratado.excluido = true;
    this.contratadoWebApi.alterar(this.tbContratado, this.HFtkn, (retorno) => {
      this.ExcluirUsuario();
      this.LimparCampo();
      this.listarContratadoSetor();
      this.loading = false;
      this.toast.success("Registro excluído com sucesso!")
    },
      (error) => {
        this.loading = false;
        this.toast.error(error);
      });
  }

  ValidarCampos() {

    // var emailRegex = /^[a-z0-9.]+@[a-z0-9]+\.[a-z]+\.([a-z]+)?$/i;

    if (this.tbContratado.usuario && (this.tbContratado.email == undefined || this.tbContratado.email == "")) {
      this.toast.error("Não é possível cadastrar esse colaborador como usuário sem informar o campo de e-mail.");
      return false;
    }

    if (this.editar) {
      //Verificar se existem treinamentos agendados para esse colaborador. Se houver não deixa colocar "Não" nas opções abaixo
      let alterarTreinador;
      alterarTreinador = this.tbContratado.treinador;

      let alterarAvaliador;
      alterarAvaliador = this.tbContratado.avaliador;

      if (alterarTreinador == 'false' || alterarAvaliador == 'false') {
        this.agendaService.SelecionarAgendaPorTreinadorAvaliador(this.idInstituicao, this.tbContratado.id, this.HFtkn, (dataAgenda: TbAgenda[]) => {
          if (dataAgenda.length > 0) {
            this.toast.error('Esse colaborador está vinculado a um ou mais treinamentos agendados. Portanto para alterar ele como não treinador ou não avaliador primeiro precisa retirá-lo dessas agendas!', 'Atenção', { disableTimeOut: true });
            return;
          }
        });
      }
    }

    let dataatual = new Date();
    this.tbContratado.dataCadastro = dataatual;
    this.tbContratado.idInstituicao = this.idInstituicao;

    if (this.tbContratado.nome == undefined ||
      this.tbContratado.nome == '' ||
      this.tbContratado.idFuncaoCargo == (undefined || "0") ||
      this.tbContratado.treinador == (undefined) ||
      this.tbContratado.avaliador == undefined ||
      this.tbContratadoSetor.idSetor == (undefined || 0)) {
      this.toast.error("Preencha todos os campos.");
      return false;
    }
    else {
      return true;
    }
  }

  Novo() {
    this.loading = true;
  }

  ngOnInit(): void {
  }
}
