import { Component, OnInit, Injectable, ComponentFactoryResolver, ComponentRef, ViewContainerRef } from '@angular/core';

@Injectable()
export class Confirm {
    private vcr: ViewContainerRef;

    private confirmComponent: ConfirmComponent;
    private componentRef: ComponentRef<ConfirmComponent>;

    constructor(private componentFactoryResolver: ComponentFactoryResolver) {

    }

    public setRootVcr(vcr: ViewContainerRef){
        this.vcr = vcr;
    }

    private createComponent(){
        let componentFactory = this.componentFactoryResolver.resolveComponentFactory(ConfirmComponent);
        this.componentRef = this.vcr.createComponent(componentFactory);
        this.confirmComponent = <ConfirmComponent>this.componentRef.instance;
        this.confirmComponent.componentRef = this.componentRef;
    }

    public show(obj: any, okCallback, cancelCallback = null){
        this.createComponent();

        if(typeof obj == 'string')
            this.confirmComponent.objeto = {
                msg: obj
            };
        else
            this.confirmComponent.objeto = obj;

        this.confirmComponent.okCallback = okCallback;
        this.confirmComponent.cancelCallback = cancelCallback;
    }
}

@Component({
    template: `
        
    `,
    styleUrls: ['./confirm.service.scss']
})
export class ConfirmComponent implements OnInit {
    public componentRef: ComponentRef<ConfirmComponent>;

    public objeto: any;
    public okCallback: (() => any);
    public cancelCallback: (() => any);

    public titulo: String;
    public msgConfirmacao: String;
    public okBtnLabel: String;
    public cancelarBtnLabel: String;

    public modalShow: boolean = false;

    constructor(){ }

    ngOnInit(){
        this.titulo = this.objeto.title != undefined ? this.objeto.title : 'Confirmação';
        this.msgConfirmacao = this.objeto.msg != undefined ? this.objeto.msg : 'Tem certeza que deseja prosseguir?';
        this.okBtnLabel = this.objeto.okBtn != undefined ? this.objeto.okBtn : 'Sim';
        this.cancelarBtnLabel = this.objeto.cancelBtn != undefined ? this.objeto.cancelBtn : 'Não';
        this.modalShow = true;
    }

    okBtn(){
        if(this.okCallback) this.okCallback();
        this.modalShow = false;

        setTimeout(() => this.componentRef.destroy(), 200);
    }

    cancelarBtn(){
        if(this.cancelCallback) this.cancelCallback();
        this.modalShow = false;

        setTimeout(() => this.componentRef.destroy(), 200);
    }
};
