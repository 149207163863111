import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { PrincipalComponent } from './principal/principal.component';
import { PrincipalModule } from './principal/principal.module';
import { UsuarioWebApi } from './shared/services/webapi/usuario-webapi.service';

import { SessionService } from './shared/services/session.service';
import { AuthGuard } from './shared/guards/auth.guard';
import { HttpClientModule } from '@angular/common/http';
import { Http } from './shared/services/http.service';
import { FormsModule }   from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { Confirm } from './shared/services/confirm/confirm.service';
import { Utils } from './shared/classes/utils';
import { Global } from './core/global';
import { Ng2SearchPipeModule } from 'ng2-search-filter'
import { NgWizardModule, NgWizardConfig, THEME } from 'ng-wizard';

const ngWizardConfig: NgWizardConfig = {
  theme: THEME.default
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PrincipalComponent
  ],
  imports: [
    PrincipalModule,
    HttpClientModule,
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    SharedModule,
    AppRoutingModule,
    Ng2SearchPipeModule,
    NgWizardModule.forRoot(ngWizardConfig)
  ],
  providers: [   
    SessionService,
    AuthGuard,
    Window,
    Http,
    Confirm,
    Utils,
    Global
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
