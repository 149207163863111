<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <!-- Default box -->
                <!-- TO DO List -->
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">
                            <i class="ion ion-clipboard mr-1"></i>
                            Contato
                        </h3>
                    </div>
                    <form>
                        <div class="card-body row">
                            <div class="col-5 text-center d-flex align-items-center justify-content-center">
                                <div class="">
                                    <h2>Habilita Farma</h2>
                                    <p class="lead mb-5">São José dos Campos - SP<br>                                        
                                    </p>
                                </div>
                            </div>
                            <div class="col-7">
                                <div class="form-group">
                                    <label for="inputName">Nome</label>
                                    <input [(ngModel)]="nome" type="text" id="nome" name="nome" class="form-control" />
                                </div>
                                <div class="form-group">
                                    <label for="inputEmail">E-Mail</label>
                                    <input [(ngModel)]="email" type="email" id="email" name="email" class="form-control" />
                                </div>
                                <div class="form-group">
                                    <label for="inputSubject">Assunto</label>
                                    <input [(ngModel)]="assunto" type="text" id="assunto" name="assunto" class="form-control" />
                                </div>
                                <div class="form-group">
                                    <label for="inputMessage">Mensagem</label>
                                    <textarea [(ngModel)]="mensagem" id="mensagem" name="mensagem" class="form-control" rows="4"></textarea>
                                </div>
                                <div class="form-group">
                                    <a [href]="'mailto:contato@habilitafarma.com.br'">Enviar E-mail</a>
                                    <!-- <a [href]="mailto:'contato@habilitafarma.com.br'?subject='assunto'&cc=jedsonresende@outlook.com.com.br&cc=carolina@habilitafarma.com.br&body='mensagem'">Enviar email</a> -->
                                    <!-- <input type="submit" class="btn btn-primary" value="Enviar Mensagem"> -->
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>