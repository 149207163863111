<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">
                            Temas
                        </h3>
                    </div>
                    <form>
                        <div class="card-body">
                            <div class="form-group col-md-12">
                                <label for="inputTema">Setores</label>
                                <select (change)="listarTemasPorSetor(tbSetor)" id="tbSetor.id" [(ngModel)]="tbSetor.id"
                                    name="tbSetor.id" class="form-control">
                                    <!-- <option [value]="0" selected>Escolha...</option> -->
                                    <option [value]="item.id" *ngFor="let item of tbSetores">{{item.nome}}</option>

                                </select>
                            </div>
                            <div class="row">
                                <div class="card col-md-6 card-header">
                                    <div class="card-header">
                                        <h5 class="card-title">
                                            Temas Iniciais
                                            <!-- <div class="row">
                                                <div class="col-10">
                                                    Temas Iniciais
                                                </div>
                                                <div class="col-1">
                                                    <i data-toggle="modal" data-target="#novo" class="fas fa-plus text-blue"></i>
                                                </div>
                                            </div> -->
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <ol>
                                            <li *ngFor="let tema of tbTemasIniciais">
                                                <div class="row">
                                                    <!-- <i class="ion ion-clipboard mr-1">{{tema.nome}}</i> -->

                                                    <div class="col-10">
                                                        <i class="ion ion-clipboard mr-1">{{tema.nome}}</i>
                                                    </div>
                                                    <div class="col-1">
                                                        <i data-toggle="modal" (click)="listarTopicosPorTema(tema.id, tema.nome)"
                                                            data-target="#novo" style="cursor: pointer; color: gray;"
                                                            class="nav-icon fas fa-eye"></i>
                                                    </div>
                                                </div>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                                <div class="card col-md-6 card-header">
                                    <div class="card-header">
                                        <h5 class="card-title">
                                            Temas Continuados
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <!-- <div class="bs-example">
                                            <ol>
                                            <div class="panel-group" [attr.id]="'accordion_' + i"
                                                *ngFor="let tema of tbTemasContinuados; let i = index;">
                                                <li>
                                                <div class="panel panel-default row">
                                                    <div class="col-10">
                                                        <div class="panel-heading">
                                                            <a data-toggle="collapse" [attr.data-parent]="'#accordion_' + i"
                                                                [attr.href]="'#collapseOne_' + i">{{tema.nome}}</a>
                                                        </div>
                                                    </div>
                                                    <div class="col-1">
                                                        <i style="cursor: pointer; color: gray;"
                                                            class="nav-icon fas fa-eye" data-toggle="collapse" [attr.data-parent]="'#accordion_' + i"
                                                            [attr.href]="'#collapseOne_' + i"></i>
                                                    </div>
                                                    <div [attr.id]="'collapseOne_' + i"
                                                        class="panel-collapse collapse in">
                                                        <div class="panel-body">
                                                            {{tema.nome}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            </div>
                                        </ol>
                                        </div> -->
                                        <ol>
                                            <li *ngFor="let tema of tbTemasContinuados">
                                                <div class="row">
                                                    <div class="col-10">
                                                        <i class="ion ion-clipboard mr-1">{{tema.nome}}</i>
                                                    </div>
                                                    <div class="col-1">
                                                        <i data-toggle="modal" (click)="listarTopicosPorTema(tema.id, tema.nome)"
                                                        data-target="#novo"style="cursor: pointer; color: gray;"
                                                            class="nav-icon fas fa-eye"></i>
                                                    </div>
                                                </div>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>


<div class="modal fade" id="novo" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"
    data-backdrop="static">

    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Lista de Tópicos</h5>
            </div>
            <div class="modal-body">
                <div class="row gx-3 gy-2 align-items-center">
                    <div class="form-group col-md-12">
                        <div class="card-body p-0">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>{{tema}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let topico of tbTopicos">
                                        <td>{{topico.descricao}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">
                    Cancelar</button>
            </div>
        </div>
    </div>
</div>