<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <!-- Default box -->
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Treinamento Inicial</h3>
                    </div>
                    <form #f="ngForm">
                        <div class="row" style="padding: 10px; text-align: right;">
                            <div class="col-sm-8" style="text-align: left;">
                                <button type="button" (click)="Novo()" data-toggle="modal" data-target="#novo"
                                    class="btn btn-secondary btn-sm">
                                    <span><i class="fas fa-save"></i> Novo</span>
                                </button>
                            </div>
                            <div class="col-sm-4">
                                <input class="form-control mr-sm-2" type="search" placeholder="Pesquisar..."
                                    name="textoFiltro" id="textoFiltro" [(ngModel)]="textoFiltro">

                            </div>
                        </div>
                        <p></p>
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">Contratado</th>
                                    <th scope="col">Setor</th>
                                    <th scope="col">Tema</th>
                                    <th scope="col">Data Planejada</th>
                                    <th scope="col">Data Realizada</th>
                                    <th scope="col">Data Limite</th>
                                    <th scope="col">Ação</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <tr *ngFor="let item of tbAgendas | filtrosGrid:textoFiltro">
                                    <td width="auto" [innerHtml]="item.nomeContratado"></td>
                                    <td width="auto" [innerHtml]="item.nomeSetor"></td>
                                    <td width="auto" [innerHtml]="item.nomeTema"></td>
                                    <td width="100px" [innerHtml]="item.dataPlanejada | separardatahora: 1"></td>
                                    <td width="100px" [innerHtml]="item.dataRealizada | separardatahora: 1"></td>
                                    <td width="100px" [innerHtml]="item.dataLimite | separardatahora: 1"></td>
                                    <td><button type="button" class="btn btn-outline-default btn-sm"
                                        (click)="BuscarPorId(item)" data-toggle="modal" data-target="#novo">
                                        <i class="nav-icon fas fa-edit"></i></button></td>
                                </tr>
                            </tbody>
                        </table>
                    </form>

                </div>
            </div>
        </div>
    </div>
</section>


<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Confirma a exclusão do registro?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Confirma a exclusão do registro?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-outline-warning" (click)="ExcluirRegistro()"
                    data-dismiss="modal">Confirmar</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal novo registro-->
<div class="modal fade" id="novo" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"
    data-backdrop="static">    
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Novo Cadastro</h5>
            </div>
            <div class="modal-body">
                <div class="row gx-3 gy-2 align-items-center">                    
                    <div class="form-group col-md-12">
                        <label for="inputEmail4">Colaborador</label>
                        <select id="tbAgenda.idContratado" [(ngModel)]="tbAgenda.idContratado"
                            name="tbAgenda.idContratado" [disabled]="!this.loading || tbAgenda.tipo == 2"
                            class="form-control" (change)="SelecionarSetorPorContratado(tbAgenda.idContratado)">
                            <option selected [value]="0">Escolha...</option>
                            <option [value]="item.id" *ngFor="let item of tbContratados">{{item.nome}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="nome">Setor</label>
                        <select id="tbAgenda.idSetor" [(ngModel)]="tbAgenda.idSetor" name="tbAgenda.idSetor"
                            [disabled]="true" class="form-control" (change)="listarTemas(tbAgenda.idSetor)">
                            <option selected [value]="0">Escolha...</option>
                            <option [value]="item.id" *ngFor="let item of tbSetores">{{item.nome}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="inputTelefone">Tema</label>
                        <select id="tbAgenda.idTema" [(ngModel)]="tbAgenda.idTema" [disabled]="!this.loading"
                            (change)="mostrarTopicos()" name="tbAgenda.idTema" class="form-control">
                            <option [value]="item.id" *ngFor="let item of tbTemas">{{item.nome}}</option>
                            <option selected [value]="0">Escolha...</option>
                        </select>
                    </div>
                    <div class="form-group col-md-12">
                        <div>
                            <div>
                                <h5></h5>
                                <ul>
                                    <li *ngFor="let topico of tbTopicos">{{topico.descricao}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="inputTreinador">Treinador</label>
                        <select id="tbAgenda.idTreinador" [(ngModel)]="tbAgenda.idTreinador" [disabled]="!this.loading"
                            name="tbAgenda.idTreinador" class="form-control">
                            <option [value]="0" selected>Escolha...</option>
                            <option [value]="item.id" *ngFor="let item of tbContratadosTreinadores">{{item.nome}}
                            </option>
                        </select>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="inputAvaliador">Avaliador</label>
                        <select id="tbAgenda.idAvaliador" [(ngModel)]="tbAgenda.idAvaliador" [disabled]="!this.loading"
                            name="tbAgenda.idAvaliador" class="form-control">
                            <option [value]="0" selected>Escolha...</option>
                            <option [value]="item.id" *ngFor="let item of tbContratadosAvaliadores">{{item.nome}}
                            </option>
                        </select>
                    </div>
                    <div class="form-group col-md-4">
                        <label for="inputDataNascimmento">Data Planejada</label>
                        <input type="datetime-local" class="form-control" name="tbAgenda.dataPlanejada"
                            [(ngModel)]="tbAgenda.dataPlanejada" id="tbAgenda.dataPlanejada" [disabled]="!this.loading">
                    </div>
                    <div class="form-group col-md-4">
                        <label for="inputState">Data Realizada</label>
                        <input type="datetime-local" class="form-control" name="tbAgenda.dataRealizada"
                            [(ngModel)]="tbAgenda.dataRealizada" id="tbAgenda.dataRealizada" [disabled]="true">
                    </div>
                    <div class="form-group col-md-4">
                        <label for="inputState">Data Limite</label>
                        <input type="datetime-local" class="form-control" name="tbAgenda.dataLimite"
                            [(ngModel)]="tbAgenda.dataLimite" id="tbAgenda.dataLimite" [disabled]="!this.loading">
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary btn-sm" (click)="LimparCampo()" data-dismiss="modal">
                    Cancelar</button>
                <button type="button" class="btn btn-success btn-sm" (click)="Salvar()" data-dismiss="modal">
                    Confirmar</button>
                <div [hidden]="!habilitarExcluir">
                    <button type="button" data-toggle="modal" data-target="#exampleModal" data-dismiss="modal"
                        class="btn btn-danger btn-sm">Excluir</button>
                </div>
            </div>
        </div>
    </div>
</div>