import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrincipalComponent } from './principal.component';
import { UsuarioComponent } from './usuario/usuario.component';
import { AuthGuard } from '../shared/guards/auth.guard';
import { ContratadoComponent } from './contratado/contratado.component';
import { HomeComponent } from './home/home.component';
import { SetorComponent } from './setor/setor.component';
import { ContratadoSetorComponent } from './contratado-setor/contratado-setor.component';
import { AgendamentoComponent } from './agendamento/agendamento.component';
import { AcompanhamentoComponent } from './acompanhamento/acompanhamento.component';
import { AvaliacaoComponent } from './avaliacao/avaliacao.component';
import { RegistroComponent } from './registro/registro.component';
import { AgendamentoContinuadoComponent } from './agendamento-continuado/agendamento-continuado.component';
import { ContatoComponent } from './contato/contato.component';
import { SobreComponent } from './sobre/sobre.component';
import { ManualComponent } from './manual/manual.component';
import { AplicarComponent } from './aplicar/aplicar.component';
import { FuncaoCargoComponent } from './funcao-cargo/funcao-cargo.component';
import { TemaComponent } from './tema/tema.component';
import { FarmaciaComponent } from './admin/farmacia/farmacia.component';
import { InstituicaoComponent } from './instituicao/instituicao.component';
import { EnviaremailagendaComponent } from './enviaremailagenda/enviaremailagenda.component';
import { EnviaremailagendamensalComponent } from './enviaremailagendamensal/enviaremailagendamensal.component';
import { TestecronjoghfComponent } from './testecronjoghf/testecronjoghf.component';


const routes: Routes = [
  {
    path: "principal", component: PrincipalComponent, children: [
      { path: "home", component: HomeComponent},
      { path: "admin/usuario", component: UsuarioComponent },
      { path: "contratado", component: ContratadoComponent },
      { path: "funcaoCargo", component: FuncaoCargoComponent, canActivate: [AuthGuard] },
      { path: "setor", component: SetorComponent, canActivate: [AuthGuard] },
      { path: "tema", component: TemaComponent },
      { path: "contratadoSetor", component: ContratadoSetorComponent, canActivate: [AuthGuard] },
      { path: "agendamento", component: AgendamentoComponent},
      { path: "agendamentoContinuado", component: AgendamentoContinuadoComponent},
      { path: "acompanhamento", component: AcompanhamentoComponent},
      { path: "aplicar", component: AplicarComponent},
      { path: "avaliacao", component: AvaliacaoComponent, canActivate: [AuthGuard] },
      { path: "registro", component: RegistroComponent },
      { path: "contato", component: ContatoComponent },
      { path: "sobre", component: SobreComponent },
      { path: "manual", component: ManualComponent },
      { path: "admin/farmacia", component: FarmaciaComponent},
      { path: "enviaremailagenda", component: EnviaremailagendaComponent},
      { path: "testecronjobhf", component: TestecronjoghfComponent},
      { path: "enviaremailagendamensal", component: EnviaremailagendamensalComponent},
      { path: "admin/instituicao", component: InstituicaoComponent}
    ]
  },//Another pages
  { path: '**', redirectTo: '/login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PrincipalRoutingModule { }
