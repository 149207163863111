<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <!-- Default box -->
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Treinamento Continuado</h3>
                    </div>
                    <form #f="ngForm">
                        <div class="row" style="padding: 10px; text-align: right;">
                            <!-- <div class="col-sm-2" style="text-align: left;">
                                <button type="button" (click)="Novo()" data-toggle="modal" data-target="#novo"
                                    class="btn btn-secondary btn-sm">
                                    <span><i class="fas fa-save"></i> Novo</span>
                                </button>
                            </div> -->
                            <div class="col-sm-6 row">
                                <div class="col-sm-6" style="padding-top: 5px;"><b> Ano da agenda: </b></div>
                                <div class="col-sm-6" style="padding-bottom: 10px;">
                                    <select id="ano" [(ngModel)]="ano" (change)="listarAgenda()" name="ano"
                                        class="form-control">
                                        <option [value]="2020">2020</option>
                                        <option [value]="2021">2021</option>
                                        <option [value]="2022">2022</option>
                                        <option [value]="2023">2023</option>
                                        <option [value]="2024">2024</option>
                                        <option [value]="2025">2025</option>
                                        <option [value]="2026">2026</option>
                                        <option [value]="2027">2027</option>
                                        <option [value]="2028">2028</option>
                                        <option [value]="2029">2029</option>
                                        <option [value]="2030">2030</option>
                                        <option [value]="2031">2031</option>
                                        <option [value]="2032">2032</option>
                                        <option [value]="2033">2033</option>
                                        <option [value]="2034">2034</option>
                                        <option [value]="2035">2035</option>
                                        <option [value]="2036">2036</option>
                                        <option [value]="2037">2037</option>
                                        <option [value]="2038">2038</option>
                                        <option [value]="2039">2039</option>
                                        <option [value]="2040">2040</option>
                                        <option [value]="2041">2041</option>
                                        <option [value]="2042">2042</option>
                                        <option [value]="2043">2043</option>
                                        <option [value]="2044">2044</option>
                                        <option [value]="2045">2045</option>
                                        <option [value]="2046">2046</option>
                                        <option [value]="2047">2047</option>
                                        <option [value]="2048">2048</option>
                                        <option [value]="2049">2049</option>
                                        <option [value]="2050">2050</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-4" style="text-align: right;">
                                <input class="form-control mr-sm-2" type="search" placeholder="Pesquisar..."
                                    name="textoFiltro" id="textoFiltro" [(ngModel)]="textoFiltro">
                            </div>
                        </div>
                        <p></p>
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col" [hidden]="true">id</th>
                                    <th scope="col">Mês</th>
                                    <th scope="col">Grupo</th>
                                    <th scope="col">Tema</th>
                                    <th scope="col">Data Planejada</th>
                                    <th scope="col">Data Realizada</th>
                                    <th scope="col">Data Limite</th>
                                    <th scope="col">Editar</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <tr *ngFor="let item of tbAgendas | filtrosGrid:textoFiltro">
                                    <td width="auto" [innerHtml]="item.id" [hidden]="true"></td>
                                    <td width="auto"
                                        [innerHtml]="item.dataPlanejada | convertDataPraMes: item.nomeGrupo"></td>
                                    <td width="auto" [innerHtml]="item.nomeGrupo"></td>
                                    <td width="auto" [innerHtml]="item.nomeTema"></td>
                                    <td width="100px" [innerHtml]="item.dataPlanejada | separardatahora: 1"></td>
                                    <td width="100px" [innerHtml]="item.dataRealizada | separardatahora: 1"></td>
                                    <td width="100px" [innerHtml]="item.dataLimite | separardatahora: 1"></td>
                                    <td><button type="button" class="btn btn-sm" data-toggle="modal" data-target="#novo"
                                            (click)="BuscarPorId(item)"><i class="nav-icon fas fa-edit"></i>
                                        </button></td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Confirma a exclusão do registro?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Confirma a exclusão do registro?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-outline-warning" (click)="ExcluirRegistro()"
                    data-dismiss="modal">Confirmar</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal novo registro-->
<div class="modal fade" id="novo" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"
    data-backdrop="static">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Novo Cadastro</h5>
            </div>
            <div class="modal-body">
                <div class="row gx-3 gy-2 align-items-center">
                    <div class="form-group col-md-6">
                        <label for="nome">Grupo</label>
                        <select id="tbAgenda.idGrupo" [(ngModel)]="tbAgenda.idGrupo" name="tbAgenda.idGrupo"
                            [disabled]="!this.loading || tbAgenda.preDefinido == true" class="form-control"
                            (change)="listarTemas(tbAgenda.idGrupo)">
                            <option selected [value]="0">Escolha...</option>
                            <option [value]="item.id" *ngFor="let item of tbGrupos">{{item.nome}}
                            </option>
                        </select>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="inputEmail4">Tema</label>
                        <select id="tbAgenda.idTema" [(ngModel)]="tbAgenda.idTema"
                            [disabled]="!this.loading || tbAgenda.preDefinido == true" (change)="mostrarTopicos()"
                            name="tbAgenda.idTema" class="form-control">
                            <option selected [value]="0">Escolha...</option>
                            <option [value]="item.id" *ngFor="let item of tbTemas">{{item.nome}}
                            </option>
                        </select>
                    </div>
                    <div class="form-group col-md-12">
                        <div>
                            <div>
                                <h5></h5>
                                <ul>
                                    <li *ngFor="let topico of tbTopicos">{{topico.descricao}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-md-4">
                        <label for="inputDataNascimmento">Data Planejada</label>
                        <input type="datetime-local" class="form-control" name="tbAgenda.dataPlanejada"
                            [(ngModel)]="tbAgenda.dataPlanejada" id="tbAgenda.dataPlanejada" [disabled]="!this.loading">
                    </div>
                    <div class="form-group col-md-4">
                        <label for="inputState">Data Realizada</label>
                        <input type="datetime-local" class="form-control" name="tbAgenda.dataRealizada"
                            [(ngModel)]="tbAgenda.dataRealizada" id="tbAgenda.dataRealizada" [disabled]="true">
                    </div>
                    <div class="form-group col-md-4">
                        <label for="inputState">Data Limite</label>
                        <input type="datetime-local" class="form-control" name="tbAgenda.dataLimite"
                            [(ngModel)]="tbAgenda.dataLimite" id="tbAgenda.dataLimite" [disabled]="!this.loading">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="inputTreinador">Treinador</label>
                        <select id="tbAgenda.idTreinador" [(ngModel)]="tbAgenda.idTreinador" [disabled]="!this.loading"
                            name="tbAgenda.idTreinador" class="form-control">
                            <option [value]="0" selected>Escolha...</option>
                            <option [value]="item.id" *ngFor="let item of tbContratadosTreinadores">
                                {{item.nome}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="inputAvaliador">Avaliador</label>
                        <select id="tbAgenda.idAvaliador" [(ngModel)]="tbAgenda.idAvaliador" [disabled]="!this.loading"
                            name="tbAgenda.idAvaliador" class="form-control">
                            <option [value]="0" selected>Escolha...</option>
                            <option [value]="item.id" *ngFor="let item of tbContratadosTreinadores">
                                {{item.nome}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary btn-sm" (click)="LimparCampo()" data-dismiss="modal">
                    Cancelar</button>
                <button type="button" class="btn btn-success btn-sm" (click)="Salvar()" data-dismiss="modal">
                    Confirmar</button>                
            </div>
        </div>
    </div>
</div>