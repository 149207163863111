import { Injectable } from '@angular/core';
import { SessionService } from "../session.service";
import { Http } from '../http.service';
import { BaseWebApi } from "./base-webapi.service";

@Injectable({
  providedIn: 'root'
})
export class AgendaWebapiService extends BaseWebApi {
  constructor(httpService: Http,
    sessionService: SessionService) {
      super(httpService, sessionService);
      this.prefixo = 'agenda';
    }    

    SelecionarAgendaPorContratadoTema(idInstituicao: number, idContratado: number, idTema: number, HFtkn: string, success?, error?) {
      return this.httpClient.get(this.prefixo + '/SelecionarAgendaPorContratadoTema?idInstituicao=' + idInstituicao + '&idContratado=' + idContratado + "&idTema=" + idTema, HFtkn, success, error);
    }
    SelecionarAgendaPorTreinadorAvaliador(idInstituicao: number, idContratado: number, HFtkn: string, success?, error?) {
      return this.httpClient.get(this.prefixo + '/SelecionarAgendaPorTreinadorAvaliador?idInstituicao=' + idInstituicao + '&idContratado=' + idContratado, HFtkn, success, error);
    }
    
  }
  