export class tbAcompanhamento{
    id: number;
    idInstituicao: number;
    idContratado: number;
    idTema: number;
    idSetor: number;
    tipo: number;
    concluido: boolean;
    excluido: boolean;

    constructor(init? : Partial<tbAcompanhamento>) {
        if(init != null) Object.assign(this, init);
        else{
            this.id = undefined;
            this.idInstituicao = undefined;
            this.idContratado = undefined;
            this.idTema = undefined;
            this.idSetor = undefined;
            this.tipo = undefined;
            this.concluido = undefined;
            this.excluido = undefined;
        }
    }
}