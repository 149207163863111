<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">
                            Aplicar Treinamento
                        </h3>
                    </div>
                    <form>
                        <div class="card-body">
                            <div class="form-group col-md-12">
                                <label for="inputTema">Temas</label>
                                <select (change)="ListarTopicosPorTema()" id="tbTema.id" [(ngModel)]="tbTema.id"
                                    name="tbTema.id" class="form-control" disabled>
                                    <option [value]="0" selected>Escolha...</option>
                                    <option [value]="item.id" *ngFor="let item of tbTemas">{{item.nome}}</option>
                                </select>
                            </div>
                                <div class="card card-info">
                                    <div class="card-header">
                                        <h3 class="card-title">Lista de Presença</h3>
                                    </div>
                                    <div class="card-body">
                                        <ul class="todo-list" data-widget="todo-list"
                                            *ngFor="let presentes of listaPresenca">
                                            <label class="switch">
                                                <input type="checkbox" id="presentes.nomeContratado" value=""
                                                (change)="presencaChecked($event, presentes.nomeContratado)">
                                                <span class="slider round"></span>
                                            </label>&nbsp;
                                            <label for="todoCheck1"></label>
                                            <span class="text">{{presentes.nomeContratado}}</span>
                                        </ul>
                                    </div>
                                </div>
                                <div class="card card-info">
                                    <div class="card-header">
                                        <h3 class="card-title">Tópicos</h3>
                                    </div>
                                    <div class="card-body">
                                        <ul class="todo-list" data-widget="todo-list" *ngFor="let topico of tbTopicos">
                                            <label class="switch">
                                                <input type="checkbox" id="topico.id" value=""
                                                    (change)="countCheckedTopicos($event)">
                                                <span class="slider round"></span>
                                            </label>&nbsp;
                                            <label for="todoCheck1"></label>
                                            <span class="text">{{topico.descricao}}</span>
                                        </ul>
                                    </div>
                                </div>

                            <!-- </div> -->

                            <!-- <div class="form-group row col-md-12">
                                <div class="card col-md-7">
                                    <div class="card-header">
                                        <h5 class="card-title">
                                            <i class="ion ion-clipboard mr-1"></i>
                                            Tópicos
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <ul class="todo-list" data-widget="todo-list" *ngFor="let topico of tbTopicos">
                                            <label class="switch">
                                                <input type="checkbox" id="topico.id" value=""
                                                    (change)="countCheckedTopicos($event)">
                                                <span class="slider round"></span>
                                            </label>&nbsp;
                                            <label for="todoCheck1"></label>
                                            <span class="text">{{topico.descricao}}</span>
                                        </ul>
                                    </div>
                                </div>

                                <div class="card col-md-5">
                                    <div class="card-header">
                                        <h5 class="card-title">
                                            <i class="ion ion-clipboard mr-1"></i>
                                            Lista de Presença
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <ul class="todo-list" data-widget="todo-list"
                                            *ngFor="let presentes of listaPresenca">
                                            <label class="switch">
                                                <input type="checkbox" id="presentes.nomeContratado" value="">
                                                <span class="slider round"></span>
                                            </label>&nbsp;
                                            <label for="todoCheck1"></label>
                                            <span class="text">{{presentes.nomeContratado}}</span>
                                        </ul>
                                    </div>
                                </div>
                            </div> -->
                            <div class="row">
                                <div class="col-12">
                                    <button type="button" class="btn btn-primary" [disabled]="desabilitarConcluido" [hidden]="ocultarConcluido"
                                        (click)="habilitarRegistro()" style="margin-right: 5px; width: 200px;">
                                        Concluído
                                    </button>
                                    <button type="button" class="btn btn-success" routerLink="/principal/registro"
                                        [disabled]="desabilitarConcluido || desabilitarBotaoRegistro"
                                        style="width: 200px;">
                                        Emitir Registro
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>