import { Injectable } from '@angular/core';
import { SessionService } from "../session.service";
import { Http } from '../http.service';
import { BaseWebApi } from "./base-webapi.service";

@Injectable({
  providedIn: 'root'
})
export class SetorWebapiService extends BaseWebApi {
  constructor(httpService: Http,
    sessionService: SessionService) {
      super(httpService, sessionService);
      this.prefixo = 'setor';
    }
  
    ListarSetorPorContratado(idInstituicao: number, idContratado: number, auth_token: string, success?, error?) {
      return this.httpClient.get(this.prefixo + '/SetorPorContratado?idInstituicao=' + idInstituicao + '&idContratado=' + idContratado, auth_token, success, error);
    }
  }
  