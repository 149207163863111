export enum MetodoAvaliacao {
    'Aplicação de teste escrito' = 1,
    'Acompanhamento de rotina' = 2,
    'Discussão sobre o tema' = 3
}

export enum TipoTreinamento{
    Inicial = 1,
    Continuado = 2
}
