import { Injectable } from "@angular/core";
import { SessionService } from "../session.service";
import { Http } from '../http.service';
import { BaseWebApi } from "./base-webapi.service";

@Injectable()
export class UsuarioWebApi extends BaseWebApi {
    constructor(httpService: Http,
        sessionService: SessionService) {
        super(httpService, sessionService);
        this.prefixo = 'usuario';
    }

    autenticar(usuario: String, senha: String, codeInstituicao: string, auth_token: string, success, error) {
        this.httpClient.autenticar('usuario/ValidarUsuario/?', auth_token, usuario, senha, codeInstituicao, (data) => {
            this.sessionService.setUsuario({ usuario: data });
            success(data);
        }, error);
    }

    pesquisarPorUsuario(usuario: String, idInstituicao: number, auth_token: string, success, error) {
        return this.httpClient.get(this.prefixo + '/PesquisarPorUsuario?usuario=' + usuario + '&idInstituicao=' + idInstituicao, auth_token, success, error);
    }

    pesquisarUsuarioPorIdContratado(idContratado: number, idInstituicao: number, auth_token: string, success, error) {
        return this.httpClient.get(this.prefixo + '/pesquisarUsuarioPorIdContratado?idContratado=' + idContratado + '&idInstituicao=' + idInstituicao, auth_token, success, error);
    }

    gettkn(usuario: String, senha: String, codeInstituicao: string, success, error) {
        this.httpClient.gettkn('api/v1/auth/auth?', usuario, senha, codeInstituicao, (data) => {
            this.sessionService.setUsuario({ usuario: data });
            success(data);
        }, error);
    }

    incluirUsuario(objeto, success?, error?) {
        return this.httpClient.post('usuario/IncluirUsuario/', objeto, success, error);
    }

    alterarUsuario(objeto, success?, error?) {
        return this.httpClient.put('usuario/AlterarUsuario/', objeto, success, error);
    };
}