import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'escolaridadePipe'
})
export class EscolaridadePipePipe implements PipeTransform {

  transform(escolaridade: number): string
    {
        let retorno = "";

            if(escolaridade == 1){
              retorno = "Fundamental 1";
            }
            else if(escolaridade == 2){
              retorno = "Fundamental 2";
            }
            else if (escolaridade == 3) {
              retorno = "Ensino Médio"              
            }
            else if (escolaridade == 4) {
              retorno = "Ensino Técnico"              
            }
            else if (escolaridade == 5) {
              retorno = "Ensino Superior"              
            }
            else{
                retorno = "Indefinido"
            };
            
        
        return retorno;
    }
}
