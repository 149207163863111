import { Component, Inject, OnInit, forwardRef } from '@angular/core';
import { ContratadoWebapiService } from 'src/app/shared/services/webapi/contratado-webapi.service';
import { PrincipalComponent } from '../principal.component';
import { InstituicaoWebapiService } from 'src/app/shared/services/webapi/instituicao-webapi.service';
import { ToastrService } from 'ngx-toastr';
import { TbContratado } from 'src/app/entities/tbContratado';
import { TbInstituicao } from 'src/app/entities/tbInstituicao';
import { TemaWebapiService } from 'src/app/shared/services/webapi/tema-webapi.service';
import { TbTema } from 'src/app/entities/tbTema';
import { MetodoAvaliacao, TipoTreinamento } from 'src/app/entities/enum';
import { VwContratadoFuncaoCargo } from 'src/app/entities/vwContratadoFuncaoCargo';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.css']
})
export class RegistroComponent implements OnInit {
  public tbContratado: TbContratado = new TbContratado();
  public listaLinhasBrancas:VwContratadoFuncaoCargo = new VwContratadoFuncaoCargo();
  public tbInstituicao: TbInstituicao = new TbInstituicao();
  public listaContratados: TbContratado[] = new Array<TbContratado>();
  public vwContratadoFuncaoCargo: VwContratadoFuncaoCargo[] = new Array<VwContratadoFuncaoCargo>();
  public vwContratadoResponsavelFuncaoCargo: VwContratadoFuncaoCargo = new VwContratadoFuncaoCargo();
  public tbTema: TbTema = new TbTema();
  public metodoAvaliacao;
  public tipoTreinamento;
  public idInstituicao;
  public DadosRegistro;
  public responsavel;
  dataDoRegistro: Date;

  constructor(@Inject(forwardRef(() => PrincipalComponent))
  public toast: ToastrService,
    private contratadoWebApiservice: ContratadoWebapiService,
    private temaWebapiService: TemaWebapiService,
    private instituicaoWebApiService: InstituicaoWebapiService
  ) {
    this.codeInstituicao = localStorage.getItem("chaveId");

    this.BuscarInstituicaoPorCode();
  }

  codeInstituicao = '';
  pathLogo = '';
  HFtkn = localStorage.getItem("HFtkn");

  BuscarInstituicaoPorCode() {
    this.instituicaoWebApiService.pesquisarInstituicaoPorCode(this.codeInstituicao, this.HFtkn, (data: TbInstituicao) => {
      this.idInstituicao = data[0];
      this.pathLogo = '../../assets/farmacias/' + this.codeInstituicao + '/logo.jpg'
      this.buscarResponsavel();
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }
  BuscarInstituicaoDataCadastro() {
    this.instituicaoWebApiService.selecionarPorId(this.idInstituicao, this.HFtkn, (data: TbInstituicao) => {
      this.tbInstituicao = data;
      this.dataDoRegistro = new Date(this.tbInstituicao.dataCadastro);
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  imprimir() {
    window.print();
  }

  buscarResponsavel() {
    this.contratadoWebApiservice.selecionarResponsavel(this.idInstituicao, this.HFtkn, (data: TbContratado) => {
      this.tbContratado = data[0];      
      this.DadosRegistro = JSON.parse(localStorage.getItem("agenda"));
      this.responsavel = this.tbContratado.nome;
      this.BuscarTema(this.DadosRegistro.idTema);
      this.BuscarTreinadorResponsavel(this.DadosRegistro.nomeTreinador);
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  BuscarTema(id) {
    this.temaWebapiService.selecionarPorId(id, this.HFtkn, (dataTema: TbTema) => {
      this.tbTema = dataTema;
      this.metodoAvaliacao = MetodoAvaliacao[this.tbTema.idMetodoAvaliacao];
      this.tipoTreinamento = TipoTreinamento[this.tbTema.tipo];      
      if (this.tbTema.tipo == 1) {
        this.BuscarContratado(this.DadosRegistro.nomeContratado);
      }
      else {
        this.BuscarContratadosPorNomeSetor(this.DadosRegistro.nomeSetor);
      }
      
      this.BuscarInstituicaoDataCadastro();
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  BuscarContratado(nomeContratado) {
    this.contratadoWebApiservice.SelecionarContratadoPorNome(this.idInstituicao, nomeContratado, this.HFtkn, (data: VwContratadoFuncaoCargo[]) => {
      this.vwContratadoFuncaoCargo = data;

      for (let index = 1; index < 12; index++) {
        this.listaLinhasBrancas.nomeContratado = "";
        this.vwContratadoFuncaoCargo.push(this.listaLinhasBrancas);
      }
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  BuscarTreinadorResponsavel(nomeContratadoResponsavel) {
    this.contratadoWebApiservice.SelecionarContratadoPorNome(this.idInstituicao, nomeContratadoResponsavel, this.HFtkn, (data: VwContratadoFuncaoCargo) => {
      this.vwContratadoResponsavelFuncaoCargo = data[0];
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  // preenche a lista de presenca do continuado
  BuscarContratadosPorNomeSetor(nomeGrupo) {
    this.contratadoWebApiservice.ListarContratadosPresentesRegistroPorIdAgenda(this.idInstituicao, this.DadosRegistro.id, this.HFtkn, (data: VwContratadoFuncaoCargo[]) => {
      this.vwContratadoFuncaoCargo = data;

      if (this.vwContratadoFuncaoCargo.length < 12) {
        for (let index = this.vwContratadoFuncaoCargo.length; index < 12; index++) {
          this.listaLinhasBrancas.nomeContratado = "";
          this.vwContratadoFuncaoCargo.push(this.listaLinhasBrancas);
        }
      }
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  ngOnInit(): void {

  }

}
