import { Component, Inject, OnInit, ViewChild, forwardRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppComponent } from 'src/app/app.component';
import { tbAcompanhamento } from 'src/app/entities/tbAcompanhamento';
import { TbAgenda } from 'src/app/entities/tbAgenda';
import { TbInstituicao } from 'src/app/entities/tbInstituicao';
import { TbSetor } from 'src/app/entities/tbSetor';
import { TbTema } from 'src/app/entities/tbTema';
import { TbTopico } from 'src/app/entities/tbTopico';
import { AcompanhamentoInicialWebapiService } from 'src/app/shared/services/webapi/acompanhamento-inicial-webapi.service';
import { AgendaWebapiService } from 'src/app/shared/services/webapi/agenda-webapi.service';
import { InstituicaoWebapiService } from 'src/app/shared/services/webapi/instituicao-webapi.service';
import { SetorWebapiService } from 'src/app/shared/services/webapi/setor-webapi.service';
import { TemaWebapiService } from 'src/app/shared/services/webapi/tema-webapi.service';
import { TopicoWebapiService } from 'src/app/shared/services/webapi/topico-webapi.service';

@Component({
  selector: 'app-tema',
  templateUrl: './tema.component.html',
  styleUrls: ['./tema.component.css']
})
export class TemaComponent implements OnInit {
  @ViewChild('ngForm') frm: NgForm;
  public get frmCtrls(): any { return this.frm ? this.frm.controls : {}; };

  public tbSetores: TbSetor[] = new Array<TbSetor>();
  public tbSetor: TbSetor = new TbSetor();
  public tbTemas: TbTema[] = new Array<TbTema>();
  public tbTemasIniciais: TbTema[] = new Array<TbTema>();
  public tbTemasContinuados: TbTema[] = new Array<TbTema>();
  
  public tbTemasIniciaisTodos: TbTema[] = new Array<TbTema>();
  public tbTemasContinuadosTodos: TbTema[] = new Array<TbTema>();

  public tbTema: TbTema = new TbTema();
  public tbTopicos: TbTopico[] = new Array<TbTopico>();
  public tbTopico: TbTopico = new TbTopico();

  TopicoTexto: number = 0;
  tema = "";
  desabilitarConcluido: boolean = true;
  desabilitarBotaoRegistro: boolean = true;
  public idInstituicao;
  public DadosRegistro;
  codeInstituicao = '';
  HFtkn = localStorage.getItem("HFtkn");

  constructor(@Inject(forwardRef(() => AppComponent))
  public parent: AppComponent,
    public toast: ToastrService,
    private activatedRoute: ActivatedRoute,
    private instituicaoWebApiService: InstituicaoWebapiService,
    private temaWebapiService: TemaWebapiService,
    private setorWebapiService: SetorWebapiService,
    private topicoWebapiService: TopicoWebapiService) {
    this.codeInstituicao = localStorage.getItem("chaveId");
    this.BuscarInstituicaoPorCode();
  }

  
  BuscarInstituicaoPorCode() {
    this.instituicaoWebApiService.pesquisarInstituicaoPorCode(this.codeInstituicao, this.HFtkn, (data: TbInstituicao) => {
      this.idInstituicao = data[0];
      this.listarSetores();
      this.activatedRoute.queryParams.subscribe(
        (queryParams: any) => {
          const idTema = queryParams['idTema'];
          if (idTema != undefined) {
            this.tbTema.id = Number(idTema);
          }
        }
      );
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  listarSetores() {
    this.setorWebapiService.selecionarPorIdInstituicao(this.idInstituicao, this.HFtkn, (data: TbSetor[]) => {
      this.tbSetores = data;
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  listarTemasPorSetor(setor) {
    this.temaWebapiService.TemaPorSetor(this.idInstituicao, setor.id, this.HFtkn, (dataTemasIniciais: TbTema[]) => {
      this.tbTemasIniciais = dataTemasIniciais;
      this.setorWebapiService.selecionarPorId(setor.id, this.HFtkn, (dataSetor: TbSetor) => {
        this.tbSetor = dataSetor;
        this.listarTemasPorGrupo(this.tbSetor.idGrupo);
        // this.listarTemasIniciaisPorInstituicao();
        // this.listarTemasContinuadosPorInstituicao();
      },
        (error) => {
          if (error.status == 401)
            this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
          else
            this.toast.error(error);
        });
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  listarTemasIniciaisPorInstituicao() {
    this.temaWebapiService.selecionarPorIdInstituicao(1, this.HFtkn, (dataTemasIniciais: TbTema[]) => {
      this.tbTemasIniciaisTodos = dataTemasIniciais;      
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  listarTemasPorGrupo(idGrupo) {
    this.temaWebapiService.TemaPorGrupo(this.idInstituicao, idGrupo, this.HFtkn, (dataTemasContinuados: TbTema[]) => {
      this.tbTemasContinuados = dataTemasContinuados; 
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }  

  listarTemasContinuadosPorInstituicao() {
    this.temaWebapiService.selecionarPorIdInstituicao(1, this.HFtkn, (dataTemasContinuados: TbTema[]) => {
      this.tbTemasContinuadosTodos = dataTemasContinuados; 
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }  
   

  listarTopicosPorTema(IdTema, tema) {
    this.topicoWebapiService.TopicosPorTema(this.idInstituicao, IdTema, this.HFtkn, (dataTopicos: TbTopico[]) => {
      this.tbTopicos = dataTopicos; 
      this.tema = tema;
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }  


  ngOnInit(): void {
  }

}