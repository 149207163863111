import { Injectable } from '@angular/core';
import { SessionService } from "../session.service";
import { Http } from '../http.service';
import { BaseWebApi } from "./base-webapi.service";

@Injectable({
  providedIn: 'root'
})
export class ContratadoSetorWebapiService extends BaseWebApi {
  constructor(httpService: Http,
    sessionService: SessionService) {
    super(httpService, sessionService);
    this.prefixo = 'contratadoSetor';
  }

  ListarContratadoPorSetor(idInstituicao: number, auth_token: string, success?, error?) {
    return this.httpClient.get(this.prefixo + '/ContratadoSetor?idInstituicao=' + idInstituicao, auth_token, success, error);
  }

  SetorPorIdContratado(idContratado: number, auth_token: string, success?, error?) {
    return this.httpClient.get(this.prefixo + '/SetorPorIdContratado?idContratado=' + idContratado, auth_token, success, error);
  }
}
