import { Component, Inject, OnInit, ViewChild, ViewContainerRef, forwardRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppComponent } from 'src/app/app.component';
import { TbGrupo } from 'src/app/entities/tbGrupo';
import { TbInstituicao } from 'src/app/entities/tbInstituicao';
import { TbSetor } from 'src/app/entities/tbSetor';
import { TbUsuario } from 'src/app/entities/tbUsuario';
import { Utils } from 'src/app/shared/classes/utils';
import { GrupoWebapiService } from 'src/app/shared/services/webapi/grupo-webapi.service';
import { InstituicaoWebapiService } from 'src/app/shared/services/webapi/instituicao-webapi.service';
import { SetorWebapiService } from 'src/app/shared/services/webapi/setor-webapi.service';
import { UsuarioWebApi } from 'src/app/shared/services/webapi/usuario-webapi.service';

@Component({
  selector: 'app-instituicao',
  templateUrl: './instituicao.component.html',
  styleUrls: ['./instituicao.component.css']
})
export class InstituicaoComponent implements OnInit {

  @ViewChild('ngForm') frm: NgForm;
  public get frmCtrls(): any { return this.frm ? this.frm.controls : {}; };
  public tbInstituicoes: TbInstituicao[] = new Array<TbInstituicao>();
  public tbInstituicao: TbInstituicao = new TbInstituicao();
  public tbUsuario: TbUsuario = new TbUsuario();
  public loading: boolean = false;
  public habilitarExcluir: boolean = false;

  textoFiltro = '';
  codeInstituicao = '';
  HFtkn = localStorage.getItem("HFtkn");

  constructor(@Inject(forwardRef(() => AppComponent))
  public parent: AppComponent,
    public toast: ToastrService,
    public vcr: ViewContainerRef,
    private instituicaoWebApiService: InstituicaoWebapiService,    
    private UsuarioWebapiService: UsuarioWebApi,
    private utils: Utils,
    private router: Router) {
    let HFtkn = localStorage.getItem("HFtkn");
    this.codeInstituicao = localStorage.getItem("chaveId");
    this.BuscarInstituicaoPorCode(this.codeInstituicao);
  }

  BuscarInstituicaoPorCode(code) {
    this.instituicaoWebApiService.pesquisarInstituicaoPorCode(this.codeInstituicao, this.HFtkn, (data: TbInstituicao) => {
      this.tbInstituicao.id = data[0];
      this.listarInstituicoes();
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  listarInstituicoes() {
    this.instituicaoWebApiService.listar(this.HFtkn, (data: TbInstituicao[]) => {
      this.tbInstituicoes = data;
    },
      (error) => {
        if (error.status == 401)
          this.toast.error("Seu Login expirou. Saia do Sistema e acesse novamente com suas credenciais.").toastRef.manualClose;
        else
          this.toast.error(error);
      });
  }

  BuscarPorId(item) {
    this.instituicaoWebApiService.selecionarPorId(item.id, this.HFtkn, (data: TbInstituicao) => {
      this.tbInstituicao = data;
      this.loading = true;
      this.habilitarExcluir = true;
      window.scroll(0, 0);
    },
      (error) => {
        this.toast.error(error);
      });
  }


  LimparCampo() {
    this.tbInstituicao.id = undefined;
    this.tbInstituicao.codigo = undefined;
    this.tbInstituicao.idInstituicao = undefined;
    this.tbInstituicao.nomeFantasia = undefined;
    this.tbInstituicao.razaoSocial = undefined;
    this.tbInstituicao.cnpj = undefined;
    this.tbInstituicao.endereco = undefined;
    this.tbInstituicao.telefone = undefined;
    this.tbInstituicao.idResponsavelLegal = undefined;
    this.tbInstituicao.idResponsavelTecnico = undefined;
    this.tbInstituicao.idResponsavelGarantiaQualidade = undefined;
    this.tbInstituicao.ativo = undefined;
    this.tbInstituicao.dataCadastro = undefined;
    this.tbInstituicao.excluido = undefined;
    this.loading = false;
    this.habilitarExcluir = false;
  }

  Novo() {
    this.loading = true;
  }

  Salvar() {
    this.tbInstituicao.idInstituicao = 0;
    this.tbInstituicao.ativo = true;
    this.tbInstituicao.dataCadastro = new Date(Date.now());
    this.tbInstituicao.excluido = false;

    if (this.ValidarCampos()) {
      this.loading = true;
      this.habilitarExcluir = false;
      this.tbInstituicao.excluido = false;

      if (this.tbInstituicao.id == undefined) {
        this.instituicaoWebApiService.incluir(this.tbInstituicao, this.HFtkn, (retorno) => {
          
          this.tbUsuario.ativo = true;
          this.tbUsuario.dataCadastro = new Date(Date.now());
          this.tbUsuario.excluido = false;
          this.tbUsuario.idContratado = 1;
          this.tbUsuario.idInstituicao = retorno;
          this.tbUsuario.senha = 'admin2024';
          this.tbUsuario.usuario = 'admin'
          this.UsuarioWebapiService.incluir(this.tbUsuario, this.HFtkn, (retorno) => {                      
          },
            (error) => {
              this.loading = false;
              this.toast.error(error);
            });
          
          this.LimparCampo();
          this.listarInstituicoes();
          this.loading = false;
          this.toast.success("Registro salvo com sucesso!");
        },
          (error) => {
            this.loading = false;
            this.toast.error(error);
          });
      }
      else {
        this.instituicaoWebApiService.alterar(this.tbInstituicao, this.HFtkn, (retorno) => {
          this.LimparCampo();
          this.listarInstituicoes();
          this.loading = false;
          this.toast.success("Registro salvo com sucesso!")
        },
          (error) => {
            this.loading = false;
            this.toast.error(error);
          });
      }
    }
  }

  ExcluirRegistro() {
    this.tbInstituicao.excluido = true;
    this.instituicaoWebApiService.alterar(this.tbInstituicao, this.HFtkn, (retorno) => {
      this.LimparCampo();
      this.listarInstituicoes();
      this.loading = false;
      this.habilitarExcluir = false;
      this.toast.success("Registro excluído com sucesso!")
    },
      (error) => {
        this.loading = false;
        this.toast.error(error);
      });
  }

  ValidarCampos() {
    if (this.tbInstituicao.nomeFantasia == undefined || "") {
      this.toast.error("Campo Nome é Obrigatório.");
      return false;
    }
    else {
      return true;
    }
  }

  ngOnInit(): void {
  }
}
